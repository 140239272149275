import React, { useState, useEffect } from 'react';
import { executeLaravelFrontAPI } from '../../Admin/Utils'; // Import the executeLaravelFrontAPI function
import DeveloperBox from './DeveloperBox'; // Import the DeveloperBox component

const DeveloperListing = () => {
    const [developers, setDevelopers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchDevelopers = async () => {
            setLoading(true);
            try {
                const result = await executeLaravelFrontAPI('developers', {
                    page: 1,
                    per_page: 15,
                }, 'GET');
                setDevelopers(result.data.data);
            } catch (error) {
                console.error('Error fetching developers:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDevelopers();
    }, []);

    return (
        <section className='devListing'>
            {/* <h2 class="sec-title fw-bold text-center">Developers</h2> */}
            {loading ? <p>Loading...</p> : <DeveloperBox developers={developers} />}
        </section>
    );
};

export default DeveloperListing;
