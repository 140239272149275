import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { executeLaravelAPI, userToken, convertDateTOddmmyyyy, parsedUserData, showToastMessage } from '../../../Admin/Utils';
// import { Button } from 'react-bootstrap';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridReact } from 'ag-grid-react';




const CouponList = () => {
    const [rowData, setRowData] = useState([]);
    const [totalCoupons, setTotalCoupons] = useState(0);
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const [pageSize, setPageSize] = useState(10); // Set default page size
    const gridRef = useRef();

    const userType = parsedUserData.userrole;
    const userId = parsedUserData.id;
    // useMemo(() => {
    //     setPageSize(totalCoupons);
    // }, [totalCoupons]);
    const disableCoupon = async (id) => {
        // Show confirmation alert
        const confirmDelete = window.confirm("Are you sure you want to delete this coupon?");

        if (confirmDelete) {
            // If confirmed, proceed with API call to delete
            let endpoint = `couponcode/disable/${id}`;
            const response = await executeLaravelAPI(endpoint, '', 'GET', userToken);
            if (response.status === 200) {
                showToastMessage('Coupon deleted successfully', 'top-right', 'success');
            }

            // Reload data after deletion
            callCouponAPI(currentPage, pageSize);
        } else {
            // If not confirmed, log or do nothing
            console.log("Deletion canceled");
        }
    }
    const CreateButtonComponent = (props) => {
        return (
            <button className='btn btn-danger' variant="primary" onClick={() => disableCoupon(props.data.actions.id)}>
                <span>Delete</span>
            </button>
        );
    }

    useEffect(() => {
        callCouponAPI(currentPage, pageSize); // Call API on load
    }, [currentPage, pageSize]); // Call API on page or page size change

    const defaultColDef = {
        flex: 1,
    };

    const callCouponAPI = async (page = 1, per_page = 10) => {
        let endpoint = `couponcodes?page=${page}&per_page=${per_page}`;
        const response = await executeLaravelAPI(endpoint, '', 'GET', userToken);

        if (response.status === 200) {
            let newArray = response?.data?.data?.map((item, index) => ({
                'Sr No': (page - 1) * per_page + index + 1, // Adjust Sr No for pagination
                'Name': item.user.name,
                'Phone': item.user.phone,
                'Coupon': item.coupon_code,
                'Project': item.project.name,
                'Created At': convertDateTOddmmyyyy(item.created_at),
                'actions': item
            }));
            setRowData(newArray);
            setTotalCoupons(response?.data?.total); // Update total count
        }
    }

    const onGridReady = useCallback((params) => { gridRef.current = params.api; }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        const gridApi = gridRef.current;
        if (gridApi && gridApi.setGridOption) {
            gridApi.setGridOption(
                "quickFilterText",
                document.getElementById("filter-text-box").value,
            );
        } else {
            console.error("Grid API is not initialized or setGridOption is not available.");
        }
    }, []);

    const onGridSizeChanged = useCallback((params) => {
        if (params && params.columnApi) {
            const gridWidth = document.querySelector(".ag-body-viewport").clientWidth;
            const columnsToShow = [];
            const columnsToHide = [];
            let totalColsWidth = 0;
            const allColumns = params.columnApi.getAllColumns();

            if (allColumns && allColumns.length > 0) {
                allColumns.forEach(column => {
                    totalColsWidth += column.getMinWidth();
                    if (totalColsWidth > gridWidth) {
                        columnsToHide.push(column.getColId());
                    } else {
                        columnsToShow.push(column.getColId());
                    }
                });

                params.columnApi.setColumnsVisible(columnsToShow, true);
                params.columnApi.setColumnsVisible(columnsToHide, false);

                window.setTimeout(() => {
                    params.api.sizeColumnsToFit();
                }, 10);
            }
        }
    }, []);

    // Dynamically generate the column definitions based on userType
    const columnDefs = useMemo(() => {
        let baseColumns = [
            { field: 'Sr No', maxWidth: 100 },
            { field: 'Name', cellClass: 'name-column' },
            { field: 'Phone' },
            { field: 'Coupon' },
            { field: 'Project' },
            { field: 'Created At' },
            {
                field: 'actions',
                headerName: 'Actions',
                cellRenderer: CreateButtonComponent,
                cellRendererParams: (params) => (
                    <CreateButtonComponent value="Action" data={params.data.actions} />
                ),
                flex: 1,
            },
        ];

        return baseColumns;
    }, [userType]);

    const onFirstDataRendered = useCallback((params) => {
        params.api.sizeColumnsToFit();
    }, []);

    // Handle pagination changes
    const onPaginationChanged = (params) => {
        if (params.api) {
            const newPage = params.api.paginationGetCurrentPage() + 1; // Pages are 0-based
            if (newPage !== currentPage) {
                setCurrentPage(newPage); // Update the current page
                callCouponAPI(newPage, pageSize); // Fetch data for new page
            }
        }
    };

    const onPageSizeChanged = (event) => {
        setPageSize(Number(event.target.value)); // Update page size and re-fetch data
        setCurrentPage(1); // Reset to page 1 when page size changes
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage); // Update current page and re-fetch data
    };

    const totalPages = Math.ceil(totalCoupons / pageSize);

    return (
        <>
            <style>
                {
                    `.content-wrapper {
                    padding: 0px !important;
                    }
                    .custom-button {
                    background-color: red;
                    color: white;
                    border: none;
                    padding: 10px 20px;
                    cursor: pointer;
                    }

                    .dev-header-search-wrap {
                    float: none !important;
                    }

                    .pagination-controls {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 40px;
                    padding: 10px;
                    border-radius: 5px;
                    }

                    .pagination-controls div {
                    display: flex;
                    align-items: center;
                    }

                    .pagination-controls label {
                    font-size: 16px;
                    margin-right: 10px;
                    }

                    .pagination-controls select {
                    padding: 5px 10px;
                    font-size: 16px;
                    border-radius: 4px;
                    border: 1px solid #ccc;
                    background-color: #fff;
                    cursor: pointer;
                    }

                    .pagination-controls button {
                    padding: 8px 16px;
                    font-size: 16px;
                    background-color: #007bff;
                    color: #fff;
                    border: none;
                    border-radius: 4px;
                    cursor: pointer;
                    margin: 0 10px;
                    transition: background-color 0.3s ease;
                    }

                    .pagination-controls button:disabled {
                    background-color: #ccc;
                    cursor: not-allowed;
                    }

                    .pagination-controls button:hover:not(:disabled) {
                    background-color: #0056b3;
                    }

                    .pagination-controls span {
                    font-size: 16px;
                    }

                   
                    `

                }
            </style>

            <main className="project-detail-main pt-5">
                <div className='container'>
                    <div className='col-12 text-right mb-3'><strong>Total Coupons:</strong> {totalCoupons}</div>

                    <div className={'col-md-12'}>
                        <div className="dev-header-search-wrap">
                            <input
                                type="text"
                                id="filter-text-box"
                                placeholder="Search..."
                                onInput={onFilterTextBoxChanged}
                                className="w-100"
                            />
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "600px" }}>
                        <div id="grid-wrapper" style={{ width: "100%", height: "100%", minHeight: "600px" }}>
                            <div
                                style={{ width: "100%", height: "600px" }}
                                className="ag-theme-quartz"
                            >
                                <AgGridReact
                                    style={{ minHeight: "600px" }}
                                    ref={gridRef}
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    onGridReady={onGridReady}
                                    onGridSizeChanged={onGridSizeChanged}
                                    onFirstDataRendered={onFirstDataRendered}
                                    rowHeight={80}
                                    suppressPaginationPanel={true}
                                // pagination={true} // Enable pagination
                                // paginationPageSize={pageSize} // Default page size
                                // onPaginationChanged={onPaginationChanged} // Handle page changes
                                // paginationPageSizeSelector={[10, 25, 50]}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Pagination Controls */}
                    <div className="pagination-controls">
                        <div>
                            <label>Page Size: </label>
                            <select value={pageSize} onChange={onPageSizeChanged}>
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={-1}>-1</option>
                            </select>
                        </div>
                        <div>
                            <button
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(currentPage - 1)}
                            >
                                Previous
                            </button>
                            <span> Page {currentPage} of {totalPages} </span>
                            <button
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>

            </main>
        </>
    );
};

export default CouponList;
