import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { executeLaravelFrontAPI, capitalize, appUrl } from '../../../Admin/Utils';
import BannerSlider from './BannerSlider';
import { Helmet } from 'react-helmet-async';

const PricingSection = React.lazy(() => import('./PricingSection'));
const MapSection = React.lazy(() => import('./MapSection'));
const DreamHomeSection = React.lazy(() => import('./DreamHomeSection'));
const LocalityNear = React.lazy(() => import('./LocalityNear'));
const HotProjects = React.lazy(() => import('./HotProjects'));
const LocationSiteMap = React.lazy(() => import('./LocationSiteMap'))

const ProsCons = React.lazy(() => import('./ProsCons'));
const LandmarkSlider = React.lazy(() => import('./LandmarkSlider'));

const Loclitydetails = ({ }) => {
    const { slug } = useParams();
    const pageDetails = { 'pagetitle': 'Location', 'pageslug': 'location' };
    const [location, setLocationData] = useState({});
    const [locality, setLocality] = useState('');
    const [city, setCity]=useState('');
    useEffect(() => {
        document.title = pageDetails.pagetitle;
        const getLocation = async () => {
            try {
                // ?cacheBust=${new Date().getTime()}
                const result = await executeLaravelFrontAPI(`location/${slug}`, '', 'GET');
                const dataLocationDetails = result.data;
                setLocationData(dataLocationDetails);
                setLocality(dataLocationDetails.name);
                setCity(dataLocationDetails.city)               
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (slug) {
            getLocation();
        }
    }, [slug]);

    return (<>
        <Helmet>
            <title>{locality}: Real Estate Projects, Map, Photos, Reviews, Info 
            </title>
            <meta name="description" content={`Get to know all about ${locality}, ${capitalize(city)}: Check the ${locality} map, photos, reviews, residential projects, schools, hospitals, property rates, top builders, and more!`} />
            <link rel="canonical" href={`${appUrl}/location/${slug}`} />
        </Helmet>
        <main className='plp-page pb-5 mt-5 bg-white'>
            <BannerSlider locationData={location} />

            <Suspense fallback={<div>Loading...</div>}>
                <PricingSection locationData={location} />
            </Suspense>

            <Suspense fallback={<div>Loading...</div>}>
                <DreamHomeSection locationData={location} />
            </Suspense>

            <Suspense fallback={<div>Loading...</div>}>
                <MapSection locality_name={locality.toLowerCase()}/>
            </Suspense>
            {locality?.landmarks?.length > 0 ? (
            <Suspense fallback={<div>Loading...</div>}>
                <LandmarkSlider locationData={location}/>
            </Suspense>
            ) : null}

            <Suspense fallback={<div>Loading...</div>}>
                <LocalityNear locationData={location} />
            </Suspense>

            <Suspense fallback={<div>Loading...</div>}>
                <ProsCons locationData={location}/>
            </Suspense>

            <Suspense fallback={<div>Loading...</div>}>
                <HotProjects locationData={location} />
            </Suspense>
            <Suspense fallback={<div>Loading...</div>}>
                <LocationSiteMap locationData={location} />
            </Suspense>
        </main >
    </>);
};

export default Loclitydetails;