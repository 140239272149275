import React, { useState, useEffect } from 'react';
import './Services.scss';

const Services = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const winWD = window.innerWidth;

  useEffect(() => {
    if (winWD < 992) {
      const slides = document.querySelectorAll('.flex-slide');
      slides.forEach((slide, index) => {
        slide.addEventListener('click', () => setActiveIndex(index));
      });
    }
  }, [winWD]);

  const servicesData = [
    {
      number: '01.',
      title: 'Market Research',
      description: 'We conduct primary & secondary detailed market research of existing projects and new launches for a specific micro-market and primary & secondary research of projects in neighboring micro markets. It also includes a thorough comparison of reports stating suggestions for the given project.',
    },
    {
      number: '02.',
      title: 'Marketing Strategy',
      description: 'We devise Digital Marketing and Internet Marketing strategy for Branding and campaigns including Offline marketing strategies (suggestions only). Based on the planning and digital performance, we leverage technology for tracking ROI on offline spends. We also optimize campaigns based on data and formulate Pre-launch/launch/post-launch strategies to further streamline communication, timeline, cost, and run sustainability campaigns.',
    },
    {
      number: '03.',
      title: 'Project Launch & Execution Planning',
      description: 'We provide suggestions in project layouts, floor plan, agencies for consultation, research with comparator of the specifications being used in the project, amenities list and define project positioning with theme. We also engage brand ambassadors if required. We choose, plan and execute along agencies for photo & video shoots and post-production while creating content and script for shoots and other platforms.',
    },
    {
      number: '04.',
      title: 'Collaterals & Communication',
      description: 'We create marketing collaterals for digital as well as offline in which offline collaterals include creative for sales presentation, coffee table book, leaflets, hoardings, jingles for radio among others. Digital collaterals include project websites, videos, content to advertisement. We also create channel partner collaterals. We come up with creative concepts and their extensions to communicate the core theme of the project to audiences across channels.',
    },
    {
      number: '05.',
      title: 'Social Media, Email Marketing, PR & Events',
      description: 'Our expertise lies in ideation and execution of social media campaigns for all phases of the projects including brand aspect across all digital channels. We deploy mailers to acquire leads through powerful software like Mail chimp, constant contact etc. (Email Server Cost will be charged as actual – if required). We also plan PR activities before and during the project which includes preparing scripts, content and testimonials for PR activities. We engage relevant agencies for Print, Radio, TV & digital PR events, where we plan blogger meets and events as well.',
    },
    {
      number: '06.',
      title: 'Paid Media Marketing',
      description: (
        <>
          From conceptualization of marketing campaigns to media planning, we handle every aspect:
          <ul className="flex-pt-list">
            <li>- Ad creative – various platforms</li>
            <li>- Lead tracking and creating management reports</li>
            <li>- Optimizing campaigns based on data</li>
            <li>- Creating project wise micro sites, propagation of videos, ads etc.</li>
            <li>- Local marketing with different local marketing strategies</li>
            <li>- Marketing channels</li>
            <li>- SEM advertising</li>
            <li>- Display Advertising – via Google</li>
            <li>- Targeting BFSI, News network, real estate portals, luxury and lifestyle magazines</li>
            <li>- Targeted campaigns for NRI</li>
            <li>- Mobile and Handheld Advertising</li>
          </ul>
        </>
      ),
    },
    {
      number: '07.',
      title: 'SEO & Website Management',
      description: 'We perform best SEO practices for your website to rank higher and build your brand presence online, which includes marketing tactics that stand out from the rest of your competitors. Plus, we help you in designing and deploying your website with the right content to achieve the best digital proficiency for you online.',
    },
    {
      number: '08.',
      title: 'Reporting & BI (Business Intelligence) with Systems & Technology',
      description: 'We do campaign level reporting covering lead source and quality reports. We integrate and use custom-built (sell.do & iris) technology for seamless execution and experience for marketing, sales and registration processes including in-built telephony, email solutions for upgrading customer experience. Our constant technology updates derive lower cost of marketing.',
    },
  ];

  return (
    <section className="padding pos-rel py-5" id="services">
      <div className="container">
        <div className="row">
          <div className="services-wrapper">
            <h2 className="sec-heading2 fw-bold text-white">OFFERINGS/SERVICES</h2>
            <p className="sec-desc text-regular text-white">
              We have a result-oriented approach that reflects through our services. Let us know
              <br className="hidden-ipad" /> about your requirements and discover how BeyondWalls can fulfil them.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="flex-container">
            {servicesData.map((service, index) => (
              <div
                key={index}
                className={`flex-slide ${index === activeIndex ? 'active' : ''}`}
                onMouseEnter={() => winWD > 992 && setActiveIndex(index)}
              >
                <div className="flex-slide-no hidden-ipad">{service.number}</div>
                <div className={`flex-title ${winWD < 992 ? 'visible-ipad' : ''}`}>
                  {winWD < 992 ? (
                    <span className="flex-mob-no">{service.number}</span>
                  ) : (
                    // {
                        `/ `
                    // }
                  )}
                  {service.title}
                </div>
                <div className="flex-about">
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
