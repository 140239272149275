import React, { useEffect } from 'react';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import searchDataJson from '../../../../Assets/search-json/pune_master_search.json';

const Popup = ({ closePopup, setProjectNameForBox, setProjectSlugForBox }) => {
  useEffect(() => {
    const defaultProjects = searchDataJson.projects.map((item) => ({
      id: item.id,
      text: item.name,
      slug: item.slug
    }));

    // Initialize Select2 with default options
    $('#project-search').select2({
      placeholder: 'Search for Project',
      minimumInputLength: 0,
      data: defaultProjects,
      // allowClear: true,
      ajax: {
        delay: 250,
        processResults: (data) => {
          if (!data || !Array.isArray(data)) {
            return { results: [] };
          }
          return {
            results: data.map(project => ({
              id: project.id,
              text: project.name,
              slug: project.slug
            }))
          };
        },
        transport: (params, success, failure) => {
          try {
            // Ensure params.data.term is defined
            const searchTerm = params.data.term || '';
            const filteredProjects = searchDataJson.projects.filter(project =>
              project.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            success(filteredProjects);
          } catch (error) {
            failure(error);
          }
        }
      }
    });

    // Handle select event
    $('#project-search').on('select2:select', (e) => {
      const selectedData = e.params.data;
      setProjectNameForBox(selectedData.text);
      setProjectSlugForBox(selectedData.slug);
      closePopup();
    });

    // Clean up Select2 on component unmount
    return () => {
      $('#project-search').select2('destroy');
    };
  }, [closePopup, setProjectNameForBox, setProjectSlugForBox]);

  return (
    <div className="popup-container">
      <div className="popup-body">
        <div className="position-relative flex-grow-1 zindex-10 text-start search-box">
          <select id="project-search" className="form-control col-xs-12 search-box shadow-sm" style={{ width: '100%' }}>
          <option disabled selected>Search by Projects</option>
            {searchDataJson.projects.map((item, index) => (
              <option key={index} defaultValue={item.id}>{item.name}</option>
            ))}
          </select>
        </div>
        <button className="close-btn" onClick={closePopup}>X</button>
      </div>
    </div>
  );
};

export default Popup;
