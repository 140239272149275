import React from 'react';
import { Accordion, Card, Container, Row, Col } from 'react-bootstrap';
import './RevenueSection.scss';

const RevenueSection = () => {
  const accordionItems1 = [
    {
      title: 'Project Planning',
      content: `We help you progress from concept to reality using professional planning methods and valid technological support to further enhance your business processes resulting in optimized outputs.`
    },
    {
      title: 'Pricing Strategy',
      content: `We conduct in-depth market survey and research into existing and newly launched projects in a specific micro-market and allied micro-market. Also, we will derive a pricing strategy for the project for you, which includes preparing a cost sheet of the project (Phase-wise) along with defining discount structure and policy. Providing financial input on project level is one of our major expertise that includes researching, calculating and providing apt finance schemes for home buyers.`
    },
    {
      title: 'Creative Conceptualization',
      content: `We come up with creative ideas in terms of communication and collaterals for you to have an edge over your competitors and sell your project better.`
    },
    {
      title: 'Sales Strategy',
      content: `We help you devise sales strategy to achieve sales velocity with developing channel partner strategy & activation for project life cycle. Also, we execute the planned strategy to achieve target sales all the while training and deploying required resources.`
    },
    {
      title: 'Market Demand Analysis',
      content: `We offer you a complete comparative market analysis about your competitors and the product with data prevalent to your product positioning, future insights and other related information to market your product efficiently.`
    }
  ];

  const accordionItems2 = [
    {
      title: 'Go-To Market Strategy',
      content: `We help you identify the right audience, find your niche, form meaningful partnerships and collaborations to launch your project effectively with thorough planning and execution.`
    },
    {
      title: 'Marketing Campaign',
      content: `We help you promote your unique value proposition in the market with distinctive ideas and actionable plans to achieve higher consumer attention, establish brand connection, increase and achieve sales targets and setup seamless digital campaigns to generate valid leads.`
    },
    {
      title: 'Sales Experience',
      content: `We provide suggestions on layout for proposed sales office & sample flat while defining a customer flow for the customers on-site. We also give suggestions for enhancing the overall experience of the customer and provide suggestions for on-site branding. Designing site branding collaterals for the entire project is part of our expertise. Plus, we offer suggestions on F&B for customers on-site as well.`
    },
    {
      title: 'Channel Partner Activation',
      content: `We specialize in on-ground channel partner activation with presentation and registration of channel partners and IPC’s. We also conduct channel partner events and meets for you. On top of that managing channel partners on site along with managing the booking process is a few of our specialties where we also define brokerage slabs.`
    },
    {
      title: 'Real Estate Mandate Service',
      content: `We take the responsibility of selling your property off your shoulders with our robust mandate services so that you focus entirely on project management, construction, and growing your business.`
    }
  ];

  const renderAccordionItems = (items) =>
    items.map((item, index) => (
      <Accordion.Item eventKey={index.toString()} key={index}>
        <Accordion.Header>{item.title}</Accordion.Header>
        <Accordion.Body>{item.content}</Accordion.Body>
      </Accordion.Item>
    ));

  return (
    <section id="revenue" className="py-5">
      <Container>
        <Row>
          <Col>
            <div className="revenue-wrapper text-center">
              <h2 className="fw-bolder">
                HOW DO WE SIMPLIFY <br /> PROPERTY SELLING FOR YOU?
              </h2>
              <p className="fw-normal">
                We draw on years of experience in real estate sales and marketing to meet your commercial goals by
                supporting your journey from project planning to execution & analysis.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <Accordion>{renderAccordionItems(accordionItems1)}</Accordion>
          </Col>
          <Col md={6}>
            <Accordion>{renderAccordionItems(accordionItems2)}</Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RevenueSection;
