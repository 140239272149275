import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Form, Card, FloatingLabel } from 'react-bootstrap';
import { s3url,showToastMessage, executeLaravelAPI,userToken,uploadAssets,validateName,appAbsPath,validatePhone,formatDateForDatepicker } from '../../Utils';
// import SubmitButton from '../../shared/SubmitButton';


const DeveloperEdit = ({ onCreateFormSubmit }) => {
    const buttonClass=useRef('theme-btn btn btn-success');
    const pageDetails={'pagetitle':'Edit Developer','pageslug':'edit-developer'};
    // const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const [editDeveloper, setEditedItem] = useState({
        name: '',
        nickname: '',
        description: '',
        is_active: false,
        is_primary: false,
        rating: 0,
        total_completed_projects: 0,
        total_ongoing_projects: 0,
        logo: '',
        established_time_stamp: '',
        website: '',
        years_of_experience: 0,
        sq_ft_delivered: 0,
        no_of_customers_served: 0,
        happy_customers: 0
    });
    const { id } = useParams();
    const [developerLogo, setSelectedFile] = useState(null); 
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        document.title = pageDetails.pagetitle;
        const getDeveloper = async () => {
            try {
                const result = await executeLaravelAPI(`developer/edit/${id}`, '', 'GET', userToken);
                const dataDeveloperDetails = result.data;
                //console.log(dataDeveloperDetails);
                setEditedItem({
                    name: dataDeveloperDetails.name,
                    nickname: dataDeveloperDetails.nickname,
                    description: dataDeveloperDetails.description,
                    is_active: dataDeveloperDetails.is_active,
                    is_primary: dataDeveloperDetails.is_primary,
                    rating: dataDeveloperDetails.rating,
                    total_completed_projects: dataDeveloperDetails.total_completed_projects,
                    total_ongoing_projects: dataDeveloperDetails.total_ongoing_projects,
                    logo: dataDeveloperDetails.logo,
                    established_time_stamp: formatDateForDatepicker(dataDeveloperDetails.established_time_stamp),
                    website: dataDeveloperDetails.website,
                    years_of_experience: dataDeveloperDetails.years_of_experience,
                    sq_ft_delivered: dataDeveloperDetails.sq_ft_delivered,
                    no_of_customers_served: dataDeveloperDetails.no_of_customers_served,
                    happy_customers: dataDeveloperDetails.happy_customers
                });
                
                // const getassetdata={
                //     'id':dataDeveloperDetails.id,
                //     'asset_type':'developer'
                // }
                // const assetsresult = await executeLaravelFrontAPI(`getallassetsbytype`, getassetdata, 'GET');
                // const dataAssetDetails = assetsresult.data;

                // const developerLogo = dataAssetDetails.filter(image => image.image_sub_type === 'location_image');
                // setSelectedFile(developerLogo.map(item => item.url));

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (id) {
            getDeveloper();
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let slugValue;
        const updatedState = { [name]: value };
        if (name === 'name') {
            // slugValue = generateSlug(value);
            // updatedState.slug = slugValue;
            // updatedState.canonical_url = "/" + slugValue;
        }
        setEditedItem((prevState) => ({
            ...prevState,
            ...updatedState,
        }));
        
        // Validate the input
        let error = validateInput(name, value);
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const validateInput = (name, value) => {
        // Your validation logic here
        let error = '';
        if (['name'].includes(name)) {
            error = validateName(value);
        }
        // Add other validations as needed...
        return error;
    };
   
    const updateDeveloper = async (e) => {
        e.preventDefault();
        try {
           
            // Check for errors
            let hasErrors = false;
            const errors = {};
            errors.name = validateName(editDeveloper.name);
            errors.nickname = validateName(editDeveloper.nickname);
            errors.established_time_stamp = validateName(editDeveloper.established_time_stamp);
            for (const key in errors) {
                if (errors[key]) {
                    hasErrors = true;
                    break;
                }
            }
            setValidationErrors(errors);
            if (hasErrors) return;


            const developerFormData = new FormData();
            if (developerLogo) {
                developerFormData.append(`logo`, developerLogo);
            }
           // console.log(editDeveloper);return false;
            const response = await executeLaravelAPI(`developer/update/${id}`, editDeveloper,'PUT',userToken);
           // console.log('Uploading assets...',response.data.logo);
            if (developerLogo) {
                await uploadAssets(`developerassets/${response.data.id}`, developerFormData,userToken);
                // const getassetdata={
                //     'id':response.data.logo,
                //     'asset_type':'developer'
                // }
                // const assetsresult = await executeLaravelFrontAPI(`getallassetsbytype`, getassetdata, 'GET');
                // const dataAssetDetails = assetsresult.data;
                // console.log(dataAssetDetails);
            }
            if (response.status === 200) {
                showToastMessage('Developer edited successfully', 'top-right', 'success');
            } else {
                showToastMessage('Developer edition failed', 'top-right', 'error');
            }
        } catch (error) {
            showToastMessage('Error updating developer', 'top-right', 'error');
        }
        if (onCreateFormSubmit) {
            onCreateFormSubmit(editDeveloper);
        }
    };

    return (
        <form onSubmit={updateDeveloper} className='developerForm' encType="multipart/form-data">
            <div className="row">
                <div className="col-md-12 mb-2">
                    <Card border="" style={{ width: '100%' }}>
                        <Card.Header>Edit "{editDeveloper.name}" Developer</Card.Header>
                        <Card.Body>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Brand Name">
                                                    <Form.Control placeholder="Brand Name" type="text" name='name' value={editDeveloper.name || ''} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.name}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.name}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <hr/>
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Developer Name">
                                                    <Form.Control placeholder="Developer Name" type="text" name='nickname' value={editDeveloper.nickname || ''} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.nickname}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.nickname}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    {/* <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Phone">
                                                    <Form.Control placeholder="Phone" type="text" name='phone' value={editDeveloper.phone || ''} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.phone}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.phone}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div> */}
                                    <hr/>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Rating">
                                                    <Form.Control placeholder="Rating" type="number" name='rating' value={editDeveloper.rating || ''} onChange={handleInputChange} disabled={false} readOnly={false}/>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Total Completed Projects">
                                                    <Form.Control placeholder="Total Completed Projects" type="number" name='total_completed_projects' value={editDeveloper.total_completed_projects || ''} onChange={handleInputChange} disabled={false} readOnly={false}/>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Total Ongoing Projects">
                                                    <Form.Control placeholder="Total Ongoing Projects" type="text" name='total_ongoing_projects' value={editDeveloper.total_ongoing_projects || ''} onChange={handleInputChange} disabled={false} readOnly={false}/>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Years Of Experience">
                                                    <Form.Control placeholder="Years Of Experience" type="number" name='years_of_experience' value={editDeveloper.years_of_experience || ''} onChange={handleInputChange} disabled={false} readOnly={false}/>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="No of customers served">
                                                    <Form.Control placeholder="No of customers served" type="number" name='no_of_customers_served' value={editDeveloper.no_of_customers_served || ''} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Happy customers">
                                                    <Form.Control placeholder="Happy customers" type="number" name='happy_customers' value={editDeveloper.happy_customers || ''} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Sq.Ft. delivered">
                                                    <Form.Control placeholder="Sq.Ft. delivered" type="number" name='sq_ft_delivered' value={editDeveloper.sq_ft_delivered || ''} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <hr/>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Established Timestamp">
                                                    <Form.Control placeholder="Established Timestamp" type="date" name='established_time_stamp' value={editDeveloper.established_time_stamp || ''} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.established_time_stamp}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.established_time_stamp}
                                                    </Form.Control.Feedback>    
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Logo">
                                                    <Form.Control type="file" className="form-control" accept="image/*" onChange={handleFileChange} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Website">
                                                    <Form.Control placeholder="Website" type="text" name='website' value={editDeveloper.website || ''} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                { editDeveloper.logo?.url && editDeveloper.logo?.url !== '' ? (
                                                    <img src={`${s3url}${editDeveloper.logo?.url}`} width={100} alt={`${editDeveloper.name} Logo`} />
                                                    ) : 'No Logo'
                                                }
                                            </div>
                                        </Form.Group>
                                    </div>

                                    
                                    <hr/>
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Description">
                                                    <Form.Control placeholder="Description" type="text" name='description' value={editDeveloper.description || ''} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>

                        </Card.Body>
                        <Card.Footer className="text-end text-muted">
                            <Link to={`${appAbsPath}/portaladmin/developers`} variant="secondary" className="theme-btn secondary me-2">Cancel</Link>
                            <Button variant="success" type="submit" className="theme-btn">Save</Button>
                        </Card.Footer>
                    </Card>
                </div>
            </div>
        </form>
    );
};

export default DeveloperEdit;
