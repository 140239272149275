import Propimg from '../../../../Assets/img/propimg.png';
import Vs from '../../../../Assets/img/vs.png';

const HotProperties = ({}) => {
    return(<>
        {/*<section className="py-5 pt-0">
            <div className="container">
                <div className="row hotprop-row">
                    <div className='bnrsubhead'>View hot comparisions</div>
                    <div className="col-xl-6 col-12">
                        <div className="prop-boxdiv">
                            <div className='prop-box'>
                                <div>
                                    <img src={Propimg} className="img-fluid"/>
                                </div>
                                <div className='prop-cont'>
                                    <p className='builder'>Siddhashila Groups</p>
                                    <div className='project-name'>Treasure Troves</div>
                                    <p className='project-desc'>2 & 3BHK Residences in Wakad, Pune
                                    RERA Certificate No.P51800026069</p>
                                </div>
                            </div>
                            <div className='vsimg'>
                                <img src={Vs} className="img-fluid"/>
                            </div>
                            <div className='prop-box'>
                                <div>
                                    <img src={Propimg} className="img-fluid"/>
                                </div>
                                <div className='prop-cont'>
                                    <p className='builder'>Siddhashila Groups</p>
                                    <div className='project-name'>Treasure Troves</div>
                                    <p className='project-desc'>2 & 3BHK Residences in Wakad, Pune
                                    RERA Certificate No.P51800026069</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-12">
                        <div className="prop-boxdiv">
                            <div className='prop-box'>
                                <div>
                                    <img src={Propimg} className="img-fluid"/>
                                </div>
                                <div className='prop-cont'>
                                    <p className='builder'>Siddhashila Groups</p>
                                    <div className='project-name'>Treasure Troves</div>
                                    <p className='project-desc'>2 & 3BHK Residences in Wakad, Pune
                                    RERA Certificate No.P51800026069</p>
                                </div>
                            </div>
                            <div className='vsimg'>
                                <img src={Vs} className="img-fluid"/>
                            </div>
                            <div className='prop-box'>
                                <div>
                                    <img src={Propimg} className="img-fluid"/>
                                </div>
                                <div className='prop-cont'>
                                    <p className='builder'>Siddhashila Groups</p>
                                    <div className='project-name'>Treasure Troves</div>
                                    <p className='project-desc'>2 & 3BHK Residences in Wakad, Pune
                                    RERA Certificate No.P51800026069</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>*/}
    </>)
};

export default HotProperties;