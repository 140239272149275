import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Card, FloatingLabel } from 'react-bootstrap';
import { showToastMessage, buttonLoadingClass, executeLaravelAPI, userToken, uploadAssets, validateName } from '../../Utils';
import SubmitButton from '../../shared/SubmitButton';
import { Icon } from '@iconify/react';

const ShortUpdateAddNew = () => {

    const buttonClass = useRef('theme-btn btn btn-success');
    const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const [loading, setLoading] = useState(false);
    // const [error, setError] = useState('');
    const [locationFile, setLocationFile] = useState(null);
    const [newShortUpdate, setShortUpdate] = useState({
        title: '',
        image: '',
        content: '',
        status: 1,
    });
    const handleLocationFileChange = (event) => {
        // Convert FileList to an array and update state
        const filesArray = Array.from(event.target.files);
        const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp', 'image/gif'];
        
        const invalidFiles = filesArray.filter(file => !validTypes.includes(file.type));
        
        if (invalidFiles.length > 0) {
            showToastMessage("Invalid file type selected. Please select a valid image file.", 'top-right', 'error');
            return;
        }
        setLocationFile(filesArray);
    };

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;


        if (type === 'file') {
            const file = e.target.files[0];
            setShortUpdate({ ...newShortUpdate, [name]: file });
            console.log("file ", newShortUpdate.image);
        } else {
            setShortUpdate({ ...newShortUpdate, [name]: value });
        }
    };

    // const handleImageChange = async (e) => {
    //     const image = e.target.files[0];
    //     setShortUpdate({ ...newShortUpdate, image: image });
    // }

    const handleSave = async (e) => {
        e.preventDefault();
        // alert(newShortUpdate.title)
        let error = [];

        error.title = validateName(newShortUpdate.title);
        // alert(error.title)
        // error.image = newShortUpdate.image
        error.content = validateName(newShortUpdate.content);

       
        if (error.title != '') {
            showToastMessage("Title required", 'top-right', 'error');
            return;
        }else if ((newShortUpdate.title).length > 255) {
            showToastMessage("Title cannot exceed 255 characters", 'top-right', 'error');
            return;
        }


        // if (error.image == false) {
        //     showToastMessage("Image required", 'top-right', 'error');
        //     return;
        // }

        if (error.content != '') {
            showToastMessage("content required", 'top-right', 'error');
            return;
        }




        setLoading(true);
        setButtonClass(buttonLoadingClass);

        const formData = new FormData();
        // Append text fields


        // Append file if it exists
        if (locationFile && locationFile.length) {
            locationFile.forEach(file => {
                formData.append('images[]', file);
            });
        }

        // console.log("newShortUpdate : ", formData.get('images'));
        try {

            // created_by

            const response = await executeLaravelAPI('add-shortupdate', newShortUpdate, 'POST', userToken);


            const imageupdate = await uploadAssets(`shortupdateassets/${response.data.id}`, formData, userToken);



            if (response.status === 200) {
                showToastMessage("Data added", 'top-right', 'success');
                setShortUpdate({
                    title: '',
                    image: '',
                    content: '',
                    status: 1,
                });
                setButtonClass(buttonClass.current);
                setLoading(false);
            } else {
                showToastMessage("Something went wrong", 'top-right', 'error');
                setButtonClass(buttonClass.current);
                setLoading(false);
            }
        } catch (error) {
            showToastMessage("Something went wrong", 'top-right', 'error');
            setButtonClass(buttonClass.current);
            setLoading(false);
        }
    }

    return (
        <div className="short-update-add-new">
          <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                <h2>Add New Short Update</h2>
                <NavLink to="/portaladmin/shortupdates" className="back-btn btn btn-danger rounded-pill ">
                    <Icon icon="akar-icons:arrow-left" className='me-2'/>
                    Back
                </NavLink>
            </div>
            <Card>
                <Card.Body>
                    <form onSubmit={handleSave} className='locationForm' encType="multipart/form-data">
                        <Form.Group className="mb-3">
                            <FloatingLabel controlId="title" label="Title*">
                                <Form.Control type="text" name="title" value={newShortUpdate.title} onChange={handleInputChange} placeholder="Title*" />
                            </FloatingLabel>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <Form.Control type="file" name="images" onChange={handleLocationFileChange} multiple accept=".jpg,.jpeg,.png"/>
                        </Form.Group>
                        <FloatingLabel controlId="content" label="Content*">
                            <Form.Control as="textarea" name="content" value={newShortUpdate.content} onChange={handleInputChange} placeholder="Content*" /> 
                        </FloatingLabel>
                        <Form.Group className="mt-3 float-end">
                            <SubmitButton type="submit" className={buttonClassLoader} label="Save" />
                        </Form.Group>
                    </form>
                </Card.Body>
            </Card>
        </div>
    )


}

export default ShortUpdateAddNew;