import React, { useState, useEffect, useContext } from 'react';
import { SearchVisibilityContext } from './SearchVisibilityContext';

const Gamification = ({ projectDetails }) => {
  const { showGamification } = useContext(SearchVisibilityContext);
  const [randomField, setRandomField] = useState(null);

  useEffect(() => {
    if (projectDetails && projectDetails.is_irfs) {  // Check if is_irfs is true
      // Define the fields with their labels, but exclude 'download_couponcode_count' if 'offers' is not available
      const fields = [
        { name: 'interested_project_count', label: 'interested in this project' },
        { name: 'wishlist_project_count', label: 'added this project to their wishlist' }
      ];

      // Add 'download_couponcode_count' only if 'offers' exists
      if (projectDetails.offers) {
        fields.push({ name: 'download_couponcode_count', label: 'downloaded the coupon' });
      }

      // Randomly select one of the fields
      const randomIndex = Math.floor(Math.random() * fields.length);
      const selectedField = fields[randomIndex];

      // Check if the selected field's count is greater than 0
      if (projectDetails[selectedField.name] > 0) {
        setRandomField({
          count: projectDetails[selectedField.name],
          message: selectedField.label
        });
      }
    }
  }, [projectDetails]);

  // Return nothing if randomField is null, count is less than 1, is_irfs is false, or showGamification is false
  if (!randomField || randomField.count < 1 || !projectDetails.is_irfs || !showGamification) return null;

  return (
    <div className="gamification-card">
      <p>{randomField.count} people {randomField.message}</p>
    </div>
  );
};

export default Gamification;
