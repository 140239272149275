import React from 'react';
import Slider from 'react-slick';
import './SalesChooseSection.scss';
import { Icon } from '@iconify/react';
import bgVideo from '../../../../Assets/img/seopage/expertise-desktop-video.mp4';
import expImg10 from '../../../../Assets/img/seopage/exp-10.svg';
import expImg1 from '../../../../Assets/img/seopage/exp-1.svg';
import expImg6 from '../../../../Assets/img/seopage/exp-6.svg';
import expImg2 from '../../../../Assets/img/seopage/exp-2.svg';
import expImg3 from '../../../../Assets/img/seopage/exp-3.svg';
import expImg9 from '../../../../Assets/img/seopage/exp-9.svg';

const SalesChooseSection = () => {
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 2,
    responsive: [
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 2,
          rows: 1,
          dots: false,
          arrows: true,
          prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className="prev-arrow" />,
          nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className="next-arrow" />,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          rows: 1,
          dots: false,
          arrows: true,
          prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className="prev-arrow" />,
          nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className="next-arrow" />,
        }
      }
    ]
  };

  const sliderData = [
    {
      imageSrc: expImg10,
      mainText: "15+ YEARS",
      subText: "Of experience in the Real-Estate industry"
    },
    {
      imageSrc: expImg1,
      mainText: "1000+ CLIENTS",
      subText: "Across the RE industry in India"
    },
    {
      imageSrc: expImg6,
      mainText: "INR 40,000 CR.",
      subText: "Worth inventory sold"
    },
    {
      imageSrc: expImg2,
      mainText: "50 MN+ LEADS",
      subText: "Generated across channels"
    },
    {
      imageSrc: expImg3,
      mainText: "INR 103+CR.",
      subText: "Marketing budget managed"
    },
    {
      imageSrc: expImg9,
      mainText: "15+ AWARDS",
      subText: "For Real-Estate projects and marketing campaigns"
    }
  ];

  return (
    <section id="sales-choose" className="position-relative">
      <video
        autoPlay
        loop
        muted
        playsInline
        disablePictureInPicture
        controlsList="nodownload"
        className="expertise-video w-100"
      >
        <source type="video/mp4" src={bgVideo} />
      </video>

      <div className="video-content-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center text-white">
              <h2 className="sec-heading2 text-extraBold">
                WHY CHOOSE US?
                <br className="d-none d-md-block" /> WE ARE COMMITTED TO YOUR SUCCESS
              </h2>
              <p className="text-white text-semiBold">
                Our proven strategies in real estate sales & marketing generate leads, drive sales, and stimulate business growth.
              </p>

              <div className="exp-slider-wrapper position-relative">
                <Slider {...sliderSettings} className="exp-slider">
                  {sliderData.map((item, index) => (
                    <div className="exp-box text-center" key={index}>
                      <div>
                        <img src={item.imageSrc} alt="exp image" className="img-responsive lazy" />
                      </div>
                      <div className="exp-Num text-extraBold">{item.mainText}</div>
                      <p className="text-semiBold subheading">{item.subText}</p>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SalesChooseSection;
