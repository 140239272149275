import React, { useEffect, useState, useRef } from 'react';
import { showToastMessage, userToken, executeLaravelAPI, buttonLoadingClass } from '../../Utils';
import { Form } from 'react-bootstrap';
import { SelectToSearch } from '../SelectToSearch';
import SubmitButton from '../../shared/SubmitButton';

const ProjectEditAmenities = ({selectedProjectId }) => {
    const buttonClass=useRef('theme-btn btn btn-success');
    const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const [projectAmenities, setEditedItem] = useState({});

    useEffect(() => {    
        const getProjects = async () => {
            try {
                const result = await executeLaravelAPI(`project/view/${selectedProjectId}`, '', 'GET', userToken);
                const dataProjectDetails = result.data;
                setEditedItem(dataProjectDetails); // Set the editedItem state
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (selectedProjectId) {
            getProjects();
        }
    }, [selectedProjectId]);

    const handleSelectChange = (selectedOptions) => {
        setEditedItem(prevState => ({
            ...prevState,
            amenities: selectedOptions.length > 0 ? selectedOptions.map(option => ({ id: option.value, name: option.label })) : []
        }));        
    };

    const updateProjectAmenities = async (e) => {
        e.preventDefault();
        // Get all buttons with the 'theme-btn' class
        const buttons = document.getElementsByClassName('theme-btn');
        // Disable all these buttons
        for (let button of buttons) {
            button.disabled = true;
        }
        setButtonClass(`${buttonClass.current} ${buttonLoadingClass}`);
        
        try {
            if (projectAmenities.amenities && Array.isArray(projectAmenities.amenities)) {
                const amenitiesData = { "amenities": projectAmenities.amenities.map(amenity => amenity.id) };
    
                if (amenitiesData.amenities.length > 0) {
                    const updatedProjectAmenitiesData = await executeLaravelAPI(`projectamenities/edit/${selectedProjectId}`, amenitiesData, 'PUT', userToken);
    
                    if (updatedProjectAmenitiesData.status === 200) {
                        const response = await executeLaravelAPI(`amenitiesbyids`, amenitiesData, 'POST', userToken);
                        projectAmenities.amenities = response.data.map(amenity => ({ id: amenity.id, name: amenity.name }));
                        showToastMessage('Amenities are updated successfully', 'top-right', 'success');
                    } else {
                        showToastMessage('Amenities are not updated successfully', 'top-right', 'error');
                    }
                }
            }
        } catch (error) {
            console.error('Error updating amenities:', error);
            showToastMessage('Error updating amenities', 'top-right', 'error');
        } finally {
            // Reset the button class and enable all the buttons
            setButtonClass(buttonClass.current);
            for (let button of buttons) {
                button.disabled = false;
            }
        }
    }
    
    //console.log("Amenities",projectAmenities.amenities);
    return (
        <form onSubmit={updateProjectAmenities} className='projectForm'>
            <div className="row">
                <div className="col-md-12 mb-2">
                    <Form.Group className="row">
                        <label className="col-sm-12 col-form-label">Amenities</label>
                        <div className="col-sm-12">
                            <SelectToSearch 
                                type={"amenities"} 
                                defaultValues={projectAmenities?.amenities} 
                                name="amenities" 
                                onChange={(selectedOptions) => handleSelectChange(selectedOptions)} 
                            />
                        </div>
                    </Form.Group>
                </div>
            </div>
            <div className='col-12'>
                <hr></hr>
            </div>
            <div className='text-end mt-3'>
            {/* <Button variant="secondary" className="theme-btn secondary me-2">Cancel</Button> */}
            <SubmitButton variant="success" type="submit" className={buttonClassLoader} label="Update Amenities" />
            </div>
        </form>
    )
}
export default ProjectEditAmenities;
