import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import axios from 'axios';
// import { lazyLoadImages, appAbsPath } from '../../../Admin/Utils';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';

import BlogListCard from './BlogListCard';


const BlogCatogeryListing = ({ }) => {
    const [blogs, setBlogs] = useState([]);
    let { catogery } = useParams();
    if (catogery === 'articles') {
        catogery = 'blog'
      }
      
    useEffect(() => {
        axios.get(`https://corporate.beyondwalls.com/?portalblogbycategory=1&portalblogcategoryname=blog${catogery ? ',' + catogery : ''}`)
            .then((response) => {
                setBlogs(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <main className='plp-page pb-5 mt-5 '>
            <section className="mt-5 pt-5">
                <div className='bloglisting-card'>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="text-center mt-3 mb-5">Articles</h2>
                            </div>
                        </div>
                        <div className='blogs-box'>
                            {blogs.map((blog, index) => (
                                // <div className="col-md-3">
                                //     <div key={index} className="blog-card">
                                //         <div className="blog-card-image">
                                //             <img src={appAbsPath + blog.image} alt={blog.title} />
                                //         </div>
                                //         <div className="blog-card-content">
                                //             <h3>{blog.title}</h3>
                                //             <p>{blog.description}</p>
                                //             <a href={appAbsPath + blog.link} className="btn btn-primary">Read More</a>
                                //         </div>
                                //     </div>
                                // </div>
                                <BlogListCard blog={blog} />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default BlogCatogeryListing;