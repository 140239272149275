import { Accordion } from "react-bootstrap";

const MyActivity = () => {
  return (
    <>
      <section className="profile-section">
        <div className="profile-navigation">
        
        </div>
      </section>
      <section className="pt-5 activitysection">
        <div className="container">
          <div className="activity-card">
            <div className="title-card">
              <h2 className="activity-head">My Activity</h2>
            </div>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0"><div className="titleicon"><div className="circle"></div></div>
                <Accordion.Header className="custom-accordion-header">Friday (04 Nov 2022)</Accordion.Header>
                <Accordion.Body>
                  <ul className="activity-detailsul">
                    <li className="activity-details">
                      <a>
                        <div className="listicon"></div>
                        <div className="activity-detail">Buy in Baner, Pune, Below Rs. 90 L, 2 BHK, Residential Apartment</div>
                        <div className="activity-time">10:00 AM</div>
                      </a>
                    </li>
                    <li className="activity-details">
                      <a>
                        <div className="listicon"></div>
                        <div className="activity-detail">Buy in Baner, Pune, Below Rs. 90 L, 2 BHK, Residential Apartment</div>
                        <div className="activity-time">10:00 AM</div>
                      </a>
                    </li>
                    <li className="activity-details">
                      <a>
                        <div className="listicon"></div>
                        <div className="activity-detail">Buy in Baner, Pune, Below Rs. 90 L, 2 BHK, Residential Apartment</div>
                        <div className="activity-time">10:00 AM</div>
                      </a>
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1"><div className="titleicon"><div className="circle"></div></div>
                <Accordion.Header className="custom-accordion-header">Tuesday (06 Sept 2022)</Accordion.Header>
                <Accordion.Body>
                    <ul className="activity-detailsul">
                        <li className="activity-details">
                            <a>
                                <div className="listicon"></div>
                                <div className="activity-detail">Buy in Baner, Pune, Below Rs. 90 L, 2 BHK, Residential Apartment</div>
                                <div className="activity-time">10:00 AM</div>
                            </a>
                        </li>
                        <li className="activity-details">
                            <a>
                                <div className="listicon"></div>
                                <div className="activity-detail">Buy in Baner, Pune, Below Rs. 90 L, 2 BHK, Residential Apartment</div>
                                <div className="activity-time">10:00 AM</div>
                            </a>
                        </li>
                        <li className="activity-details">
                            <a>
                                <div className="listicon"></div>
                                <div className="activity-detail">Buy in Baner, Pune, Below Rs. 90 L, 2 BHK, Residential Apartment</div>
                                <div className="activity-time">10:00 AM</div>
                            </a>
                        </li>
                    </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2"><div className="titleicon"><div className="circle"></div></div>
                <Accordion.Header className="custom-accordion-header">Friday (02 Sept 2022)</Accordion.Header>
                <Accordion.Body>
                    <ul className="activity-detailsul">
                        <li className="activity-details">
                        <a>
                            <div className="listicon"></div>
                            <div className="activity-detail">Buy in Baner, Pune, Below Rs. 90 L, 2 BHK, Residential Apartment</div>
                            <div className="activity-time">10:00 AM</div>
                        </a>
                        </li>
                        <li className="activity-details">
                        <a>
                            <div className="listicon"></div>
                            <div className="activity-detail">Buy in Baner, Pune, Below Rs. 90 L, 2 BHK, Residential Apartment</div>
                            <div className="activity-time">10:00 AM</div>
                        </a>
                        </li>
                        <li className="activity-details">
                        <a>
                            <div className="listicon"></div>
                            <div className="activity-detail">Buy in Baner, Pune, Below Rs. 90 L, 2 BHK, Residential Apartment</div>
                            <div className="activity-time">10:00 AM</div>
                        </a>
                        </li>
                    </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyActivity;
