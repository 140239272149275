import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { executeLaravelAPI,userToken, userData, parsedUserData, appAbsPath, showToastMessage } from './../../Admin/Utils';
import { Form} from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import WishListDetails from './WishListDetails';
// import UserCouponListing from './Profile/UserCouponListing';
import Breadcrumbs from './Profile/Breadcrumbs';

export const getCookie = (name) => {
    return Cookies.get(name);
};

const UserActivity = ({loggeduserdetails}) => {
    const pageDetails={'pagetitle':'User Activity','pageslug':'user-activity'};
    const [userActivityData, setUserActivityData] = useState([]);
    // const [userdetails, setUserResponseData] = useState(null);
    useEffect(() => {
        document.title = pageDetails.pagetitle;
        const fetchUserActivity = async () => {
            try {
                const userActivity=await executeLaravelAPI('useractivity', '', 'GET', userToken);
                if(userActivity.status===200) {
                    //console.log(userActivity.data);
                    setUserActivityData(userActivity.data);
                }
            } catch (error) {
                console.error('Error fetching user activity:', error);
            }
        };
        fetchUserActivity();
    }, []);
    // console.log('userActivityData:', Array(userdetails.name));

    const handleLogout = async () => {
        //alert('logout');
        try {
            localStorage.removeItem('bwToken'); localStorage.clear();
            Cookies.remove('userData');
            //deleteCookie('bwToken')
            const userdata = await executeLaravelAPI('logout', '', 'POST', userToken);
            if (userdata.status === 200) {
                // console.log('adarsh--',userdata)
                const VisitedIrfs = getCookie('VisitedIrfs');
                if (VisitedIrfs === 'true') {
                    Cookies.remove('VisitedIrfs');
                    window.location.href = `${appAbsPath}/irfs`;
                }
                else{
                    Cookies.remove('VisitedIrfs');
                    window.location.href = `${appAbsPath}/`;
                }
            } else {
                showToastMessage('Something Went Wrong!', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };
    
    return (
        <>
        {/* <main className="project-detail-main pb-5  pb-5 pt-5 mt-5">
            <section className="project-main pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="text-center">User Activity</h1>
                            <div className="col-6">
                                <h3 className="text-center">Recent Search</h3>
                                {userActivityData.map((activity, index) => (
                                    <div key={index}>
                                    <ul>
                                        {JSON.parse(activity.user_activity).map((item, subIndex) => (
                                            <li key={subIndex}>
                                                <a href={item} className='text-black text-decoration-none' style={{right:'15px'}}>
                                                    {item}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main> */}
        <main className="project-detail-main pb-5  pb-5 pt-5 mt-5">
            <Breadcrumbs/>
           
           
            <section class="my-profile-sec">
                <div class="container">
                    <div className='profile-wrapper-box'>
                        <div class="title d-flex justify-content-between align-items-center">
                            <h3 class="m-0">My profile</h3>
                            <div>
                                {/* <i class="fa-solid fa-pen"></i> <a id="edit-profile" class="profile-btn">Edit profile</a> */}
                                {/* <a id="save-profile" class="profile-btn" style={"display: none"}>Save profile</a> */}
                            </div>
                        </div>

                        <div class="profile-sec">
                            <div class="profile-tab-content">
                                <div id="user-form">
                                <form id="update-profile" novalidate>
                                    <div class="row form-mob-gap">
                                        <div class="col-12 col-md-6 d-flex flex-column user-form-gap">
                                            {/* {userdetails} */}
                                            <Form.Group className="row">
                                                <div className="col-sm-12">
                                                    <FloatingLabel controlId="floatingInput" label="Name">
                                                        <Form.Control placeholder="Name" type="text" name='name' value={Array(parsedUserData?.name)} />
                                                    </FloatingLabel>
                                                </div>
                                            </Form.Group>
                                                <Form.Group className="row">
                                                    <div className="col-sm-12">
                                                        <FloatingLabel controlId="floatingInput" label="Email">
                                                            <Form.Control placeholder="Email" type="text" name='email_input' value={Array(parsedUserData?.email)} />
                                                        </FloatingLabel>
                                                    </div>
                                                </Form.Group>
                                        </div>
                                        <div class="col-12 col-md-6 d-flex flex-column user-form-gap">
                                            <div class="d-flex justify-content-between align-items-end">
                                                <div class="col-12">
                                                <Form.Group className="row">
                                                    <div className="col-sm-12">
                                                        <FloatingLabel controlId="floatingInput" label="Phone Number">
                                                            <Form.Control placeholder="Phone Number" type="text" name='phone_input' value={Array(parsedUserData?.phone)} pattern="[0-9]{10}"  maxlength="10" minlength="10" />
                                                        </FloatingLabel>
                                                    </div>
                                                </Form.Group>
                                                </div>
                                            </div>
                                    
                                            <div class="">
                                                <Form.Group className="row">
                                                    <div className="col-sm-12">
                                                        <FloatingLabel controlId="floatingInput" label="City">
                                                            <Form.Control placeholder="City" type="text" name='city_input' value="Pune" />
                                                        </FloatingLabel>
                                                    </div>
                                                </Form.Group>
                                            </div>
                                    
                                            {/* {{-- <div class="">
                                                <label for="pincode-input" class="form-label"><strong>Pincode</strong></label>
                                                <input type="text" id="pincode-input" name="pincode-input" value="411 057" class="form-control" readonly required>
                                            </div> --}} */}
                                        </div>
                                        
                                    </div>
                            
                            
                                    {/* {{-- <div class="d-grid gap-2">
                                    <button id="edit-profile" type="button" class="btn btn-primary">Edit</button>
                                    </div> --}} */}
                                </form>
                                <div className='mt-3 text-danger' onClick={handleLogout}>
                                    Logout
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {parsedUserData.userrole !== 'developer' &&(
                    <WishListDetails />
                )}
                </div>
            </section>
        </main>
        </>
    );
};

export default UserActivity;
