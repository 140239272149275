import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { executeLaravelFrontAPI, s3url, checkFileExtension } from '../../../Admin/Utils';
import Popup from './Popup';
import ComparisonProjectDetails from '../ComparisonProjectDetails';
import { useLocation } from 'react-router-dom';
import defaultGallery from '../../../../images/default-corporate-image.jpg';

const AddCompare = () => {
  const [openPopupIndex, setOpenPopupIndex] = useState(null);
  const [projectNames, setProjectNames] = useState([null, null, null]);
  const [projectSlug, setProjectSlugs] = useState([]);

  const [projects, setProject] = useState([]);
  
  useEffect(() => {
    getProjects(projectSlug);
  }, [projectSlug]);

  const handleOpenPopup = (index) => {
    setOpenPopupIndex(index);
  };

  const handleClosePopup = () => {
    setOpenPopupIndex(null);
  };

  const setProjectNameForBox = (index, name) => {
    const updatedProjectNames = [...projectNames];
    updatedProjectNames[index] = name;
    setProjectNames(updatedProjectNames);
  };

  const setProjectSlugForBox = (index, slug) => {
    const updatedProjectSlug = [...projectSlug];
    updatedProjectSlug[index] = slug;
    setProjectSlugs(updatedProjectSlug);
  };

  const removeProjectFromBox = (index) => {
    const updatedProjectNames = [...projectNames];
    const updatedProjectSlug = [...projectSlug];
    updatedProjectNames[index] = null;
    updatedProjectSlug[index] = null;
    setProjectNames(updatedProjectNames);
    setProjectSlugs(updatedProjectSlug);
  };

  const encodedProjectSlugs = projectSlug.filter(slug => slug).join(',');

  const getProjects = async (slugs) => {
    try {
      const result = await executeLaravelFrontAPI('compareprojects', { projects: slugs }, 'GET');
      setProject(result.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const location = useLocation();

  const getAllParams = (location) => {
    const searchParams = new URLSearchParams(location.search);
    const comparisionData = searchParams.get('projects');
    return comparisionData;
  };

  let projectslug = getAllParams(location);

  useEffect(() => {
    const getProjects = async () => {
      try {
        const result = await executeLaravelFrontAPI('compareprojects', { projects: projectslug }, 'GET');
        const sortedProjects = result.data.slice().sort((a, b) => parseFloat(b.total_score) - parseFloat(a.total_score));
        setProject(sortedProjects.slice(0, 3));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (projectslug) {
      getProjects();
    }
  }, [projectslug]);

  return (
    <section className="compare py-5 bg-white">
      <div className="container">
        <div className="row add-row">
          <div className='bnrsubhead'>Add Properties to compare </div>
          {console.log("projectNames: ",projectNames)}
          {projectNames.map((projectName, index) => (
            <div className="col-12 col-md-4 mgtpbt" key={index}>
              <div className="add-box position-relative">
                <div>
                  {projectName ? (
                    <Icon 
                      icon="mdi:cross-circle" 
                      className='position-absolute' 
                      width="2.2em" 
                      height="2.2em" 
                      style={{ color: " #ffffff", backgroundColor: "#42B57E", borderRadius: "100%", cursor: "pointer" }} 
                      onClick={() => removeProjectFromBox(index)} 
                    />
                  ) : (
                    <Icon 
                      icon="octicon:feed-plus-16" 
                      width="3.2em" 
                      height="3.2em" 
                      style={{ color: " #42B57E", cursor: "pointer" }} 
                      onClick={() => handleOpenPopup(index)} 
                    />
                  )}
                  {openPopupIndex === index && (
                    <Popup
                      closePopup={handleClosePopup}
                      setProjectNameForBox={(name) => setProjectNameForBox(index, name)}
                      setProjectSlugForBox={(slug) => setProjectSlugForBox(index, slug)}
                    />
                  )}
                </div>
                <div>
                  <div className='projectShowInfo p-2'>
                    {projects.map((project, projIndex) => (
                      project.name === projectName &&
                      <div className='projectName' key={projIndex}>
                        {project.gallery && project.gallery[0]?.url !== undefined && checkFileExtension(project.gallery[0].url) ? (
                          <div>
                            <img 
                            src={ `${s3url}/project-assets${project.gallery[0].url}`} 
                            className="w-100 comparison-card-img"  
                            alt={project.name} 
                          />
                          </div>
                        ) : (
                          <div>
                          <img 
                          src={ defaultGallery} 
                          className="w-100 comparison-card-img"  
                          alt={project.name} 
                        />                          
                          </div>

                        )}
                        <div>
                        <span className='mt-2 d-inline'>
                        <small>
                          {project.developer?.name || 'NA'}, <b>{project.address.location}</b>
                        </small>
                      </span>
                      <h4 className='projectNameText'>{project.name}</h4>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {encodedProjectSlugs && (
          <div className="mt-5">
            <ComparisonProjectDetails projectslug={encodedProjectSlugs} />
          </div>
        )}
      </div>
    </section>
  );
};

export default AddCompare;
