import React, { useEffect, useState, lazy, Suspense, useMemo, useContext, useRef } from 'react';
import Loader from './../../../Components/Loader';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { appAbsPath, executeLaravelFrontAPI, parsedUserData, createGa4Event, formatNumber } from './../../Admin/Utils';
import axios from 'axios';
import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
// import ReactPaginate from 'react-paginate';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Slider from 'rc-slider';
import Spinner from 'react-bootstrap/Spinner';
import 'rc-slider/assets/index.css';
import { Icon } from '@iconify/react';
// import ProjectCard from './ProjectCard';
import Placeholder from 'react-bootstrap/Placeholder';
import InfiniteScroll from 'react-infinite-scroll-component';
// import { CompareProjectContext, CompareProjectProvider } from '../Layouts/CompareProjectContext';
import FilterSidebar from './FilterSidebar';
import MobileFilter from '../Layouts/MobileFilter';
import { Helmet } from 'react-helmet-async';
import { debounce } from 'lodash';
import RelatedProjects from './RecomendedProjects';
import SearchPopupIrfs from "../Layouts/SearchPopupIrfs";
import SearchInputIrfs from "../Layouts/SearchInputIrfs";
const ProjectCardListing = lazy(() => import('./ProjectCardListing'));


const itemsPerPageOptions = [5, 50, 100, 500, 1000];
let flagPage = false;
let newPage;
const ProjectList = ({ }) => {
    // let currentUrl = getCurrentUrl();
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isPdp, setPdp] = useState(false);
    const [noDataFound, setNoDataFound] = useState(false);
    const pageDetails = { 'pagetitle': 'Projects', 'pageslug': 'projects' };
    const isLandingPage = location.pathname === '/';
    const currentUrl = location.pathname;

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [projects, setResponseData] = useState([]);
    const [totalItems, setTotalItems] = useState(null);
    const [urlChanged, setUrlChanged] = useState(false);

    const [sortCriteria, setSortCriteria] = useState('relevance');

    const [priceRange, setPriceRange] = useState([500000, 50000000]);
    const [builtUpArea, setBuiltRange] = useState([100, 5000]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedPossessionStatus, setSelectedPossessionStatus] = useState([]);
    const [selectedBHK, setSelectedBHK] = useState([]);
    const [selectedConf, setSelectedConf] = useState([]);

    const [getProjectstatusData, getProjectStatus] = useState([]);
    const [getProjectBhkTypeData, getProjectBhkType] = useState([]);
    const [numericParts, getNumericParts] = useState([]);
    const [nonNumericParts, getNonNumericParts] = useState([]);
    const [showRecomendedProejctComponent, setRecomendedProejctComponent] = useState(false);

    const initialDisplayLimit = 11; // Change this number to show more/less items initially
    const [showAllBhk, setShowAllBhk] = useState(false);
    // const [showAllConf, setShowAllConf] = useState(false);
    const [userdetails, setUserResponseData] = useState(null);
    const [isWishList, isWishlistActive] = useState(false);
    const [getAllParams1, setAllParams1] = useState([]);

    const [seoTitle, setSeoTitle] = useState('');
    const [seoDescription, setSeoDiscription] = useState('');

    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const paramsloc = new URLSearchParams(window.location.search);
    const locality_name = paramsloc.get('locality_name') || null;
    const [hasParams, setHasParams] = useState(false);
    const [activeSort, setActiveSort] = useState('');
    const [showResult, showSearchResult] = useState(false);
    const [visible, setVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const { setIsVisible, setSearchOptions } = useContext(SearchVisibilityContext);
    useEffect(() => {
        const screenWidth = window.innerWidth;
        if (screenWidth > 992) {
            setIsVisible(true);
        }
        const onScroll = () => {
            const screenHeight = window.innerHeight;
            const scrollPosition = window.scrollY;

            if (screenWidth < 992) {
                // Check if the user has scrolled more than 20% of the viewport height
                if (scrollPosition > (screenHeight * 0.2)) {
                    setIsVisible(true);
                }
                else {
                    setIsVisible(false);
                }
            }
            else {
                setIsVisible(true);
            }
        };

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [setIsVisible]);

    // const location = useLocation();

    const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
            // Scrolling down
            setVisible(false);
        } else {
            // Scrolling up
            setVisible(true);
        }
        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        setHasParams(urlParams.toString().length > 0);
    }, [location.search]); // Effect will run whenever location.search changes

    useEffect(() => {
        // Scroll to the top smoothly when the pathname changes
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        // Update state when URL changes
        setUrlChanged(true);

        // Set a timeout to reset the state after 2 seconds
        const timer = setTimeout(() => {
            setUrlChanged(false);
        }, 2000);

        // Clear timeout if the component unmounts or the URL changes again before 2 seconds
        return () => clearTimeout(timer);
    }, [location.pathname, location.search]); // Run this effect every time the URL path changes

    const getAllParams = (location) => {
        const searchParams = new URLSearchParams(location.search);
        const is_new_in_town = searchParams.get('is_new_in_town');
        const is_hot_selling = searchParams.get('is_hot_selling');
        const is_affordable = searchParams.get('is_affordable');
        const is_ready_to_move = searchParams.get('is_ready_to_move');
        const is_great_view = searchParams.get('is_great_view');
        const is_mandate = searchParams.get('is_mandate');
        const is_live_in_sky = searchParams.get('is_live_in_sky');
        const allParams = {};

        for (const [key, value] of searchParams.entries()) {
            allParams[key] = value;
        }
        // allParams.city = city;
        if (is_new_in_town === "true") {
            allParams.is_new_in_town = true;
        }
        if (is_hot_selling === "true") {
            allParams.is_hot_selling = true;
        }
        if (is_affordable === "true") {
            allParams.is_affordable = true;
        }
        if (is_ready_to_move === "true") {
            allParams.is_ready_to_move = true;
        }
        if (is_great_view === "true") {
            allParams.is_great_view = true;
        }
        if (is_mandate === "true") {
            allParams.is_mandate = true;
        }
        if (is_live_in_sky === "true") {
            allParams.is_live_in_sky = true
        }
        allParams.page = currentPage + 1;
        allParams.per_page = 5;
        return allParams;
    }
    const [params, setParams] = useState(getAllParams(location));

    useEffect(() => {
        if (!hasMore) {
            setRecomendedProejctComponent(true);
        }
    }, [hasMore]);

    useEffect(() => {
        setParams(getAllParams(location));
        let params = new URLSearchParams(location.search);
        let configurationValue = params.get('configuration');
        let projectStatusValue = params.get('project_status');
        let possessionStatusValue = params.get('possession_months');
        let minpriceValue = params.get('minprice');
        let maxpriceValue = params.get('maxprice');
        let minBuiltValue = params.get('carpet_min');
        let maxBuiltValue = params.get('carpet_max');
        if (configurationValue) {
            let configurationArray = configurationValue.split(',');
            setSelectedBHK(configurationArray);
        }
        if (projectStatusValue) {
            let projectStatusArray = projectStatusValue.split(',');
            setSelectedStatus(projectStatusArray);
        }
        if (possessionStatusValue) {
            let possessionStatusArray = possessionStatusValue.split(',');
            // console.log("possessionStatusArray", possessionStatusArray);
            setSelectedPossessionStatus(possessionStatusArray);
        }
        if (minpriceValue || maxpriceValue) {
            setPriceRange([minpriceValue, maxpriceValue]);
        }
        if (minBuiltValue || maxBuiltValue) {
            setBuiltRange([minBuiltValue, maxBuiltValue]);
        }
    }, [location.pathname, location.search,]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (selectedStatus.length != 0) {
        params.project_status = selectedStatus;
    }
    if (selectedPossessionStatus.length != 0) {
        // console.log("selectedPossessionStatus111111", selectedPossessionStatus);
        params.possession_months = selectedPossessionStatus;
    }
    if (numericParts.length !== 0) {
        params.bhk = numericParts ? numericParts : 0;
        params.type = nonNumericParts;
    }
    if (selectedConf.length != 0) {
        params.configurations = selectedConf.toString().toLowerCase();
        // console.log("Configurations: ", selectedConf);
    }
    if (sortCriteria.length != 0) {
        params.orderby = sortCriteria;
    }
    if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
        let minPrice = Math.floor(priceRange[0]);
        let maxPrice = Math.ceil(priceRange[1]);
        params.minprice = minPrice;
        params.maxprice = maxPrice;
    }
    if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
        let minBuilt = Math.floor(builtUpArea[0]);
        let maxBuilt = Math.ceil(builtUpArea[1]);
        params.carpet_min = minBuilt;
        params.carpet_max = maxBuilt;
    }
    const handleSliderChange = (newRange) => {
        setPriceRange(newRange);
    };
    const handleSliderChangeArea = (newRangeArea) => {
        setBuiltRange(newRangeArea);
    };

    const debouncedHandleSliderChangeComplete = debounce((params) => {
        setAllParams1(params);
        getProjects(params, 0);
    }, 1000);
    const debouncedHandleSliderChangeCompleteArea = debounce((params) => {
        setAllParams1(params);
        getProjects(params, 0);
    }, 1000);
    if (currentUrl === '/') {
        currentUrl = '/projects';
    }
    const handleSliderChangeComplete = (params) => {
        if (locality_name?.length != 0 && locality_name !== undefined && locality_name !== '' && locality_name !== null) {
            params.locality_name = locality_name;
            params.searchtype = 'locality_name';
        }
        if (selectedStatus.length != 0) {
            params.project_status = selectedStatus;
        }
        if (selectedPossessionStatus.length != 0) {
            params.possession_months = selectedPossessionStatus;
        }
        if (numericParts.length !== 0) {
            params.bhk = numericParts ? numericParts : 0;
            params.type = nonNumericParts;
        }
        if (selectedConf.length != 0) {
            params.configurations = selectedConf.toString().toLowerCase();
        }
        if (sortCriteria.length != 0) {
            params.orderby = sortCriteria;
        }
        if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
            let minBuilt = Math.floor(builtUpArea[0]);
            let maxBuilt = Math.ceil(builtUpArea[1]);
            params.carpet_min = minBuilt;
            params.carpet_max = maxBuilt;
        }

        const minPrice = Math.floor(params[0]);
        const maxPrice = Math.ceil(params[1]);
        const priceparams = { ...params, minprice: minPrice, maxprice: maxPrice };
        delete priceparams[0];
        delete priceparams[1];
        let paramsURL = new URLSearchParams(location.search);
        paramsURL.set('minprice', minPrice);
        paramsURL.set('maxprice', maxPrice);
        location.search = paramsURL.toString();
        console.log('New Budget Price :- ', location.search);
        const newUrl = `${currentUrl}?${location.search}`;
        navigate(newUrl, { replace: true });
        debouncedHandleSliderChangeComplete(priceparams);
    };
    const handleSliderChangeCompleteArea = (params) => {
        if (locality_name?.length != 0 && locality_name !== undefined && locality_name !== '' && locality_name !== null) {
            params.locality_name = locality_name;
            params.searchtype = 'locality_name';
        }
        if (selectedStatus.length != 0) {
            params.project_status = selectedStatus;
        }
        if (selectedPossessionStatus.length != 0) {
            params.possession_months = selectedPossessionStatus;
        }
        if (numericParts.length !== 0) {
            params.bhk = numericParts ? numericParts : 0;
            params.type = nonNumericParts;
        }
        if (selectedConf.length != 0) {
            params.configurations = selectedConf.toString().toLowerCase();
        }
        if (sortCriteria.length != 0) {
            params.orderby = sortCriteria;
        }
        if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
            let minPrice = Math.floor(priceRange[0]);
            let maxPrice = Math.ceil(priceRange[1]);
            params.minprice = minPrice;
            params.maxprice = maxPrice;
        }
        const minBuilt = Math.floor(params[0]);
        const maxBuilt = Math.ceil(params[1]);
        const builtparams = { ...params, carpet_min: minBuilt, carpet_max: maxBuilt };
        delete builtparams[0];
        delete builtparams[1];
        let paramsURL = new URLSearchParams(location.search);
        paramsURL.set('carpet_min', minBuilt);
        paramsURL.set('carpet_max', maxBuilt);
        location.search = paramsURL.toString();
        // console.log('New Budget Price :- ', location.search);
        const newUrl = `${currentUrl}?${location.search}`;
        navigate(newUrl, { replace: true });
        debouncedHandleSliderChangeCompleteArea(builtparams);
    };

    // useEffect(() => {
    //     // This effect only clears the paginated data
    //     setPaginatedData([]);
    //     setPage(1);
    // }, [location.search, currentPage, itemsPerPage, selectedStatus, selectedBHK, sortCriteria, priceRange]); // Dependencies that trigger data reset

    useEffect(() => {
        // This effect only clears the paginated data
        setPaginatedData([]);
        setPage(1);
    }, [location.search, itemsPerPage, selectedStatus, selectedBHK, sortCriteria, priceRange, builtUpArea]); // Dependencies that trigger data reset

    useEffect(() => {
        setTimeout(async () => {
            if (paginatedData.length === 0) {
                // getProjectStatusFunc();
                getProjectBhkTypeFunc();
                setAllParams1(params);
                getProjects(params, 0);

            }
        }, 1000);
    }, [params, selectedStatus, selectedBHK, sortCriteria]);

    useEffect(() => {
        setNearByLocation([]);
        getNearByLocations(paginatedData, params);
    }, [location.search, paginatedData, params]);

    const getProjects = async (paramsData, scrollpage) => {
        setLoading(true);
        // setNoDataFound(false);
        if (paramsData === undefined || paramsData === "") {
            paramsData = getAllParams1;
        }
        let result = {};
        try {
            if (flagPage === false || scrollpage === 0) {
                scrollpage = 0;
                newPage = 1;
                setPage(newPage);
                paramsData.page = newPage;
            } else {
                scrollpage = 1;
                newPage = page + 1;
                setPage(newPage);
                paramsData.page = newPage;
            }
            const path = location.pathname.split("/");
            const seoparam = path[path.length - 1].split("-");
            const seobedroom = seoparam[0];
            const seocity = seoparam[seoparam.length - 1];
            // SEO URL like: /2-bhk-flats-for-sale-in-wakad-pune
            const seolocality = seoparam[6];
            if (seoparam.length === 8 && seoparam[1] === 'bhk') {
                paramsData.city = seocity;
                paramsData.locality_name = seolocality;
                paramsData.bhk = seobedroom;
                paramsData.type = 'bhk';
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`${seoparam[0]} ${seoparam[1]} Flats, Apartments for Sale in ${seolocality} ${seocity} - ${totalItems}+ Properties`)
                setSeoDiscription(`Find & Explore ${seoparam[0]} ${seoparam[1]}Flats for Sale in ${seolocality}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)

            }
            // SEO URL like: /2-bhk-flats-for-sale-in-pune
            if (seoparam.length === 7 && seoparam[1] === 'bhk') {
                paramsData.city = seocity;
                paramsData.bhk = seobedroom;
                paramsData.type = 'bhk';
            }
            // SEO URL like: /property-in-pune
            if (seoparam.length === 3 && seoparam[0] === 'property') {
                paramsData.city = seocity;
            }


            //SEO URL like: /ready-to-move-in-wakad-pune
            if (seoparam.length === 6 && seoparam[0] === 'ready' && seoparam[1] === 'to' && seoparam[2] === 'move') {
                paramsData.is_ready_to_move = true;
                paramsData.locality_name = seoparam[4];
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`Buy Ready to Move Flats / Apartments in  ${seoparam[4]} ${seocity} - ${totalItems}+ Flats`)
                setSeoDiscription(`Find & Explore Ready to Move Flats & Apartments for Sale in ${seoparam[4]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
            }

            //SEO URL like: /what-a-view-in-wakad-pune
            if (seoparam.length === 6 && seoparam[0] === 'what' && seoparam[1] === 'a' && seoparam[2] === 'view') {
                paramsData.is_great_view = true;
                paramsData.locality_name = seoparam[4];
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`Buy Flats / Apartments with a Great View in  ${seoparam[4]} ${seocity} - ${totalItems}+ Flats`)
                setSeoDiscription(`Find & Explore Flats & Apartments with a Great View for Sale in ${seoparam[4]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
            }

            //SEO URL like: /new-in-wakad-pune
            if (seoparam.length === 4 && seoparam[0] === 'new' && seoparam[1] === 'in') {
                paramsData.is_hot_selling = true;
                paramsData.locality_name = seoparam[2];
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`Explore New Launch Flats / Apartments in  ${seoparam[2]} ${seocity} - ${totalItems}+ Flats`)
                setSeoDiscription(`Find & Explore New Launch Flats & Apartments for Sale in ${seoparam[2]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
            }

            //SEO URL like: /hot-selling-in-wakad-pune
            if (seoparam.length === 5 && seoparam[0] === 'hot' && seoparam[1] === 'selling') {
                paramsData.is_new_in_town = true;
                paramsData.locality_name = seoparam[3];
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`Explore Hot Selling Flats / Apartments in  ${seoparam[3]} ${seocity} - ${totalItems}+ Flats`)
                setSeoDiscription(`Find & Explore Hot Selling Flats & Apartments for Sale in ${seoparam[3]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
            }

            //SEO URL like: affordable-homes-in-wakad-pune
            if (seoparam.length === 5 && seoparam[0] === 'affordable' && seoparam[1] === 'homes') {
                paramsData.is_affordable = true;
                paramsData.locality_name = seoparam[3];
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`Affordable Housing Projects | Affordable Flats in  ${seoparam[3]} ${seocity}`)
                setSeoDiscription(`Find low budget affordable flats or apartments in  ${seoparam[3]}, ${seocity} for sale. Get verified property details for all listed affordable housing projects at BeyondWalls.`)
            }

            //SEO URL like: live-in-sky-in-wakad-pune
            if (seoparam.length === 5 && seoparam[0] === 'live' && seoparam[2] === 'sky') {
                paramsData.is_live_in_sky = true;
                paramsData.locality_name = seoparam[4];
                paramsData.searchtype = 'locality_name';
                setSeoTitle(`Explore High-Rise Building Flats / Apartments in  ${seoparam[4]} ${seocity} - ${totalItems}+ Flats`)
                setSeoDiscription(`Find & Explore High-Rise Building Flats & Apartments for Sale in ${seoparam[4]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
            }

            if (window.location.pathname.includes('irfs')) {
                paramsData.is_irfs = true;
            }


            // console.log("collingggggg----------2222222222")
            result = await executeLaravelFrontAPI('projects', { ...paramsData }, 'GET');
            const newItems = result.data.data;
            const dataProjectDetails = result.data;
            if (scrollpage === 1) {
                setPage(page + 1);
                setPaginatedData([...paginatedData, ...newItems]);
            }
            if (scrollpage === 0) {
                setPage(1);
                setPaginatedData([...newItems]);
            }
            setTotalItems(dataProjectDetails.total);
            // setTimeout(() => {
            // }, 2000);
            isWishlistActive(false);
            if (window.location.pathname.includes('irfs')) {
                setPdp(true);
            }


            setHasMore(newItems.length === 5);
            flagPage = true;
        } catch (error) {
            console.error('Error fetching data:', error);
            setNoDataFound(true);
        } finally {
            setLoading(false);
            if (result.data?.total === 0) {
                setNoDataFound(true);
                setPaginatedData([]);
                setPage(1);
            }
        }
    }
    const [nearByLocation, setNearByLocation] = useState([]);

    const getNearByLocations = async (paginatedData, paramsData) => {
        if (paginatedData.length !== 0 && (!locality_name)) {
            const firstProject = paginatedData[0];

            if (firstProject.address.location) {
                try {
                    const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                        params: {
                            q: `${firstProject.address.location}, Pune, Maharashtra, India`,
                            format: 'json',
                        },
                    });

                    const latitude = parseFloat(response.data[0]?.lat);
                    const longitude = parseFloat(response.data[0]?.lon);

                    // Update paramsData directly since it's presumably an object passed by reference
                    paramsData = {
                        ...paramsData,
                        latitude,
                        longitude,
                        skiplocation: firstProject.address.location,
                    };

                    const locationresult = await executeLaravelFrontAPI('nearbylocation', paramsData, 'GET');
                    const nearbylocation = locationresult.data;
                    setNearByLocation(Object.values(nearbylocation).join(','));
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        console.error('Axios error:', error.message);
                        if (error.response) {
                            console.error('Server responded with status:', error.response.status);
                        } else if (error.request) {
                            console.error('No response received:', error.request);
                        } else {
                            console.error('Error setting up request:', error.message);
                        }
                    } else {
                        console.error('Non-Axios error:', error.message);
                    }
                    // Handling case when location data cannot be fetched
                    setNearByLocation('');
                }
            }
        } else if (locality_name) {
            try {
                const response = await axios.get('https://nominatim.openstreetmap.org/search', {
                    params: {
                        q: `${locality_name},Pune,Maharashtra India`,
                        format: 'json',
                    },
                });

                const latitude = parseFloat(response.data[0]?.lat);
                const longitude = parseFloat(response.data[0]?.lon);

                paramsData = {
                    ...paramsData,
                    latitude,
                    longitude,
                    skiplocation: locality_name,
                };

                const locationresult = await executeLaravelFrontAPI('nearbylocation', paramsData, 'GET');
                const nearbylocation = locationresult?.data;
                setNearByLocation(Object.values(nearbylocation).join(','));
            } catch (error) {
                console.error('Error fetching location:', error);
                setNearByLocation('');
            }
        }
    }


    const showEnquiryOverlay = (projectId = '', developerId = '') => {
        const overlay = document.getElementById('overlayEnquiry');
        if (overlay) {
            overlay.style.display = 'block';
            document.getElementById('enquiry_project_id').value = projectId;
            document.getElementById('enquiry_developer_id').value = developerId;
            document.getElementById('enquiry_locality').value = locality_name ? locality_name : '';
            document.getElementById('enquiry_triggered_from').value = 'End of listing page';

        }
    }

    // const getNearByProjects = async (paramsData, scrollpage) => {
    //     if (paramsData === undefined || paramsData === "") {
    //         paramsData = getAllParams1;
    //     }
    //     let result = {};
    //     try {
    //         if (flagPage === false || scrollpage === 0) {
    //             scrollpage = 0;
    //             newPage = 1;
    //             setPage(newPage);
    //             paramsData.page = newPage;
    //         } else {
    //             scrollpage = 1;
    //             newPage = page + 1;
    //             setPage(newPage);
    //             paramsData.page = newPage;
    //         }
    //         const path = location.pathname.split("/");
    //         const seoparam = path[path.length - 1].split("-");
    //         const seobedroom = seoparam[0];
    //         const seocity = seoparam[seoparam.length - 1];
    //         // SEO URL like: /2-bhk-flats-for-sale-in-wakad-pune
    //         const seolocality = seoparam[6];
    //         if (seoparam.length === 8 && seoparam[1] === 'bhk') {
    //             paramsData.city = seocity;
    //             paramsData.locality_name = seolocality;
    //             paramsData.bhk = seobedroom;
    //             paramsData.type = 'bhk';
    //             paramsData.searchtype = 'locality_name';
    //             setSeoTitle(`${seoparam[0]} ${seoparam[1]} Flats, Apartments for Sale in ${seolocality} ${seocity} - ${totalItems}+ Properties`)
    //             setSeoDiscription(`Find & Explore ${seoparam[0]} ${seoparam[1]}Flats for Sale in ${seolocality}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)

    //         }
    //         // SEO URL like: /2-bhk-flats-for-sale-in-pune
    //         if (seoparam.length === 7 && seoparam[1] === 'bhk') {
    //             paramsData.city = seocity;
    //             paramsData.bhk = seobedroom;
    //             paramsData.type = 'bhk';
    //         }
    //         // SEO URL like: /property-in-pune
    //         if (seoparam.length === 3 && seoparam[0] === 'property') {
    //             paramsData.city = seocity;
    //         }


    //         //SEO URL like: /ready-to-move-in-wakad-pune
    //         if (seoparam.length === 6 && seoparam[0] === 'ready' && seoparam[1] === 'to' && seoparam[2] === 'move') {
    //             paramsData.is_ready_to_move = true;
    //             paramsData.locality_name = seoparam[4];
    //             paramsData.searchtype = 'locality_name';
    //             setSeoTitle(`Buy Ready to Move Flats / Apartments in  ${seoparam[4]} ${seocity} - ${totalItems}+ Flats`)
    //             setSeoDiscription(`Find & Explore Ready to Move Flats & Apartments for Sale in ${seoparam[4]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
    //         }

    //         //SEO URL like: /what-a-view-in-wakad-pune
    //         if (seoparam.length === 6 && seoparam[0] === 'what' && seoparam[1] === 'a' && seoparam[2] === 'view') {
    //             paramsData.is_great_view = true;
    //             paramsData.locality_name = seoparam[4];
    //             paramsData.searchtype = 'locality_name';
    //             setSeoTitle(`Buy Flats / Apartments with a Great View in  ${seoparam[4]} ${seocity} - ${totalItems}+ Flats`)
    //             setSeoDiscription(`Find & Explore Flats & Apartments with a Great View for Sale in ${seoparam[4]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
    //         }

    //         //SEO URL like: /new-in-wakad-pune
    //         if (seoparam.length === 4 && seoparam[0] === 'new' && seoparam[1] === 'in') {
    //             paramsData.is_hot_selling = true;
    //             paramsData.locality_name = seoparam[2];
    //             paramsData.searchtype = 'locality_name';
    //             setSeoTitle(`Explore New Launch Flats / Apartments in  ${seoparam[2]} ${seocity} - ${totalItems}+ Flats`)
    //             setSeoDiscription(`Find & Explore New Launch Flats & Apartments for Sale in ${seoparam[2]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
    //         }

    //         //SEO URL like: /hot-selling-in-wakad-pune
    //         if (seoparam.length === 5 && seoparam[0] === 'hot' && seoparam[1] === 'selling') {
    //             paramsData.is_new_in_town = true;
    //             paramsData.locality_name = seoparam[3];
    //             paramsData.searchtype = 'locality_name';
    //             setSeoTitle(`Explore Hot Selling Flats / Apartments in  ${seoparam[3]} ${seocity} - ${totalItems}+ Flats`)
    //             setSeoDiscription(`Find & Explore Hot Selling Flats & Apartments for Sale in ${seoparam[3]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
    //         }

    //         //SEO URL like: affordable-homes-in-wakad-pune
    //         if (seoparam.length === 5 && seoparam[0] === 'affordable' && seoparam[1] === 'homes') {
    //             paramsData.is_affordable = true;
    //             paramsData.locality_name = seoparam[3];
    //             paramsData.searchtype = 'locality_name';
    //             setSeoTitle(`Affordable Housing Projects | Affordable Flats in  ${seoparam[3]} ${seocity}`)
    //             setSeoDiscription(`Find low budget affordable flats or apartments in  ${seoparam[3]}, ${seocity} for sale. Get verified property details for all listed affordable housing projects at BeyondWalls.`)
    //         }

    //         //SEO URL like: live-in-sky-in-wakad-pune
    //         if (seoparam.length === 5 && seoparam[0] === 'live' && seoparam[2] === 'sky') {
    //             paramsData.is_live_in_sky = true;
    //             paramsData.locality_name = seoparam[4];
    //             paramsData.searchtype = 'locality_name';
    //             setSeoTitle(`Explore High-Rise Building Flats / Apartments in  ${seoparam[4]} ${seocity} - ${totalItems}+ Flats`)
    //             setSeoDiscription(`Find & Explore High-Rise Building Flats & Apartments for Sale in ${seoparam[4]}, ${seocity} on BeyondWalls. Choose from verified ${totalItems}+ Flats in ${seocity}. ✓ Real photos, ✓ Maps & More!`)
    //         }

    //         if (window.location.pathname.includes('irfs') ) {
    //             paramsData.is_irfs = true;
    //         }

    //         result = await executeLaravelFrontAPI('projects', { ...paramsData }, 'GET');
    //         const newItems = result.data.data;
    //         const dataProjectDetails = result.data;
    //         if (scrollpage === 1) {
    //             setPage(page + 1);
    //             setPaginatedData([...paginatedData, ...newItems]);
    //         }
    //         if (scrollpage === 0) {
    //             setPage(1);
    //             setPaginatedData([...newItems]);
    //         }
    //         setTotalItems(result.data.total);
    //         isWishlistActive(false);
    //         if(window.location.pathname.includes('irfs') ) {
    //             setPdp(true);
    //         }


    //         setHasMore(newItems.length === 5);
    //         flagPage = true;
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //         setNoDataFound(true);
    //     } finally {
    //         setLoading(false);
    //         if (result.data?.total === 0) {
    //             setNoDataFound(true);
    //             setPaginatedData([]);
    //             setPage(1);
    //         }
    //     }
    // }

    function getSortProjectFilter(sortCriteria) {
        // setTotalItems(null);
        setActiveSort(sortCriteria);
        const params = getAllParams(location);
        if (locality_name?.length != 0 && locality_name !== undefined && locality_name !== '' && locality_name !== null) {
            params.locality_name = locality_name;
            params.searchtype = 'locality_name';
        }
        if (selectedStatus.length != 0) {
            params.project_status = selectedStatus;
        }
        if (selectedPossessionStatus.length != 0) {
            params.possession_months = selectedPossessionStatus;
        }
        if (numericParts.length !== 0) {
            params.bhk = numericParts ? numericParts : 0;
            params.type = nonNumericParts;
        }
        if (selectedConf.length != 0) {
            params.configurations = selectedConf.toString().toLowerCase();
        }
        if (sortCriteria.length != 0) {
            params.orderby = sortCriteria;
            //console.log(sortCriteria);
        }
        if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
            let minPrice = Math.floor(priceRange[0]);
            let maxPrice = Math.ceil(priceRange[1]);
            params.minprice = minPrice;
            params.maxprice = maxPrice;
        }
        if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
            let minBuilt = Math.floor(builtUpArea[0]);
            let maxBuilt = Math.ceil(builtUpArea[1]);
            params.carpet_min = minBuilt;
            params.carpet_max = maxBuilt;
        }
        let paramsURL = new URLSearchParams(location.search);
        paramsURL.set('orderby', sortCriteria);
        location.search = paramsURL.toString();
        const newUrl = `${currentUrl}?${location.search}`;
        navigate(newUrl, { replace: true });
        setSortCriteria(sortCriteria);
        //console.log("Callingggggg-----------444444444");
        getProjects(params, 0);
    }

    // const getProjectStatusFunc = async () => {
    //     try {
    //     const result= await executeLaravelFrontAPI('projectstatus', "", 'GET');
    //         getProjectStatus(result.data);
    //         // console.log(result.data)
    //     } catch (error) {
    //         console.error('Error parsing JSON:', error);
    //     } finally {

    //     }
    // };
    const getProjectBhkTypeFunc = async () => {
        try {
            const result = await executeLaravelFrontAPI('bhkconfigurations', "", 'GET');
            getProjectBhkType(result.data);
        } catch (error) {
            console.error('Error parsing JSON:', error);
        } finally {

        }
    };
    const filterByBhkType = (result) => {
        // return result.filter(item => !isNaN(item.name.charAt(0))) || [];
    };

    //const filteredByBhk = filterByBhkType(getProjectBhkTypeData);
    const filteredByBhk = getProjectBhkTypeData;

    {/* Configuration Tab Function */ }
    const [selectedTab, setSelectedTab] = useState(null);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const handleFilter = (newRange) => {
        const params = getAllParams(location);
        params.minprice = newRange[0];
        params.maxprice = newRange[1];
        setPage(1);
        getProjects(params);
        createGa4Event('filter_budget', 'Budget Filter', `${newRange[0]} - ${newRange[1]}`);
    };
    const handleFilterArea = (newRange) => {
        const params = getAllParams(location);
        params.carpet_min = newRange[0];
        params.carpet_max = newRange[1];
        setPage(1);
        getProjects(params);
        createGa4Event('filter_builtup', 'Built Up Filter', `${newRange[0]} - ${newRange[1]}`);
    };

    const getMinMaxCarpet = (project, configurationName) => {
        const carpetValues = [];

        project.towers.forEach((tower) => {
            const matchingConfigurations = tower.configurations.filter((config) => config.name === configurationName);

            if (matchingConfigurations.length > 0) {
                carpetValues.push(parseFloat(matchingConfigurations[0].carpet));
            }
        });

        const minCarpet = Math.min(...carpetValues);
        const maxCarpet = Math.max(...carpetValues);

        return { minCarpet, maxCarpet };
    };

    {/* Start Project Status Sort Function */ }
    const [sortOrder, setSortOrder] = useState('asc');
    const [sortOrderBHK, setSortOrderBHK] = useState('asc');
    // const [sortOrderConf, setSortOrderConf] = useState('asc');

    const statusOrder = {
        'New Project': 1,
        'Under Construction': 2,
        'Ready To Move': 3,
        // 'Lunch': 4,
        // 'ongoing': 5,
        // 'prelaunch': 6,
    };

    const toggleStatus = (status) => {
        setSelectedStatus((prevStatus) => {
            const newStatus = prevStatus.includes(status)
                ? prevStatus.filter(item => item !== status) // Remove if already selected
                : [...prevStatus, status]; // Add if not already selected
            // Update params and fetch projects
            const params = getAllParams(location);
            if (locality_name?.length != 0) {
                if (locality_name !== undefined) {
                    params.locality_name = locality_name;
                }
                params.searchtype = 'locality_name';
            }
            if (newStatus.length !== 0) {
                params.project_status = newStatus;
            }
            if (numericParts.length !== 0) {
                params.bhk = numericParts ? numericParts : 0;
                params.type = nonNumericParts;
            }
            if (selectedConf.length !== 0) {
                params.configurations = selectedConf.toString().toLowerCase();
            }
            if (sortCriteria.length !== 0) {
                params.orderby = sortCriteria;
            }
            if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
                let minPrice = Math.floor(priceRange[0]);
                let maxPrice = Math.ceil(priceRange[1]);
                params.minprice = minPrice;
                params.maxprice = maxPrice;
            }
            if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
                let minBuilt = Math.floor(builtUpArea[0]);
                let maxBuilt = Math.ceil(builtUpArea[1]);
                params.carpet_min = minBuilt;
                params.carpet_max = maxBuilt;
            }
            let paramsURL = new URLSearchParams(location.search);
            paramsURL.set('project_status', newStatus);
            if (newStatus.length === 0) {
                paramsURL.delete('project_status');
            }
            location.search = paramsURL.toString();
            // console.log('New Project Status :- ', location.search);
            const newUrl = `${currentUrl}?${location.search}`;
            navigate(newUrl, { replace: true });
            setCurrentPage(0);
            setPage(1);

            // params.page = 1;
            // console.log("Callinggggggggggggggggggggggg-----------------1111111111111111")
            // getProjects(params, 0);

            return newStatus; // Return the updated state value
        });
    };



    const togglePossessionStatus = (status) => {
        // console.log("status", status);
        // const newStatus = parseStatus(status);
        // console.log("statuschange", newStatus);
        setSelectedPossessionStatus((prevStatus) => {
            // console.log("prevStatus", prevStatus);
            const newStatus = prevStatus.includes(status)
                ? [] // Remove if already selected, resulting in an empty array
                : [status]; // Set as the only selected value

            // to return old exact array
            // const newStatus = prevStatus.includes(status)
            //     ? prevStatus.filter(item => item !== status) // Remove if already selected
            //     : [...prevStatus, status]; // Add if not already selected
            // Update params and fetch projects
            const params = getAllParams(location);
            if (locality_name?.length != 0) {
                if (locality_name !== undefined) {
                    params.locality_name = locality_name;
                }
                params.searchtype = 'locality_name';
            }
            if (newStatus.length !== 0) {
                params.possession_months = newStatus;
            }
            if (numericParts.length !== 0) {
                params.bhk = numericParts ? numericParts : 0;
                params.type = nonNumericParts;
            }
            if (selectedConf.length !== 0) {
                params.configurations = selectedConf.toString().toLowerCase();
            }
            if (sortCriteria.length !== 0) {
                params.orderby = sortCriteria;
            }
            if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
                let minPrice = Math.floor(priceRange[0]);
                let maxPrice = Math.ceil(priceRange[1]);
                params.minprice = minPrice;
                params.maxprice = maxPrice;
            }
            if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
                let minBuilt = Math.floor(builtUpArea[0]);
                let maxBuilt = Math.ceil(builtUpArea[1]);
                params.carpet_min = minBuilt;
                params.carpet_max = maxBuilt;
            }
            let paramsURL = new URLSearchParams(location.search);
            // calculateMonths(newStatus);
            // console.log("adaaarshh--"+newStatus);
            paramsURL.set('possession_months', newStatus);
            if (newStatus.length === 0) {
                paramsURL.delete('possession_months');
            }
            location.search = paramsURL.toString();
            // console.log('New Possession Status :- ', location.search);
            const newUrl = `${currentUrl}?${location.search}`;
            navigate(newUrl, { replace: true });
            setCurrentPage(0);
            setPage(1);

            params.page = 1;
            // getProjects(params, 0);

            return newStatus; // Return the updated state value
        });
    };


    {/* End Project Status Sort Function */ }

    {/* Start Project BHK Type Sort Function */ }
    const sortProjectsByBhkType = (status) => {
        const order = sortOrderBHK === 'asc' ? 'desc' : 'asc';
        const sorted = [...projects].sort((a, b) => {
            const orderFactor = order === 'asc' ? 1 : -1;
            return orderFactor * (statusOrder[a.configurations.name] - statusOrder[b.configurations.name]);
        });
        setSortOrderBHK(order);
        setSelectedTab(status);
        setCurrentPage(0);
    };


    const toggleBhkType = (bhkType) => {
        setSelectedBHK((prevBhkType) => {
            const newBhkType = prevBhkType.includes(bhkType)
                ? prevBhkType.filter(item => item !== bhkType) // Remove if already selected
                : [...prevBhkType, bhkType]; // Add if not already selected

            // console.log("Selected BHK Types:------", newBhkType);

            // Check if the newBhkType array is empty to display the "Data not found" message
            if (newBhkType.length === 0) {
                console.log("Data not found"); // Show message or handle the scenario
            }

            // Update params and fetch projects
            const params = getAllParams(location);

            if (locality_name?.length != 0) {
                if (locality_name !== undefined) {
                    params.locality_name = locality_name;
                    console.log(locality_name);
                }
                params.searchtype = 'locality_name';
            }
            if (selectedStatus.length !== 0) {
                params.project_status = selectedStatus;
            }
            if (selectedPossessionStatus.length != 0) {
                params.possession_months = selectedPossessionStatus;
            }
            getNumericParts(newBhkType.map(type => type.match(/^\d*\.?\d*/)[0] === "" ? '0' : type.match(/^\d*\.?\d*/)[0]));
            getNonNumericParts(newBhkType.map(type => type.match(/[a-zA-Z]+$/)[0].toLowerCase()));

            const numericPartsNew = newBhkType.map(type => type.match(/^\d*\.?\d*/)[0] === "" ? '0' : type.match(/^\d*\.?\d*/)[0]);
            const nonNumericPartsNew = newBhkType.map(type => type.match(/[a-zA-Z]+$/)[0].toLowerCase());
            if (numericPartsNew.length !== 0) {
                params.bhk = numericPartsNew ? numericPartsNew : 0;
                params.type = nonNumericPartsNew;
            }
            if (selectedConf.length !== 0) {
                params.configurations = selectedConf.toString().toLowerCase();
            }
            if (sortCriteria.length !== 0) {
                params.orderby = sortCriteria;
            }
            if (priceRange[0] !== 500000 || priceRange[1] !== 50000000) {
                let minPrice = Math.floor(priceRange[0]);
                let maxPrice = Math.ceil(priceRange[1]);
                params.minprice = minPrice;
                params.maxprice = maxPrice;
            }
            if (builtUpArea[0] !== 100 || builtUpArea[1] !== 5000) {
                let minBuilt = Math.floor(builtUpArea[0]);
                let maxBuilt = Math.ceil(builtUpArea[1]);
                params.carpet_min = minBuilt;
                params.carpet_max = maxBuilt;
            }
            let paramsURL = new URLSearchParams(location.search);
            let newBhkTypeArray = Array.isArray(newBhkType) ? newBhkType : newBhkType.split(',');
            paramsURL.set('configuration', newBhkTypeArray);
            let numericPartsNewArray = Array.isArray(numericPartsNew) ? numericPartsNew : numericPartsNew.split(',');
            paramsURL.set('bhk', numericPartsNewArray);
            let nonNumericPartsNewArray = Array.isArray(nonNumericPartsNew) ? nonNumericPartsNew : nonNumericPartsNew.split(',');
            paramsURL.set('type', nonNumericPartsNewArray);
            if (newBhkType.length === 0) {
                paramsURL.delete('configuration');
                paramsURL.delete('bhk');
                paramsURL.delete('type');
            }
            location.search = paramsURL.toString();
            const newUrl = `${currentUrl}?${location.search}`;
            navigate(newUrl, { replace: true });

            setSelectedTab(bhkType);
            setCurrentPage(0);
            setPage(1);
            // console.log("Callingggggg-----------55555555555");
            // getProjects(params, 0);

            return newBhkType;
        });
    };




    const toggleShowAllBhk = () => {
        setShowAllBhk(!showAllBhk);

    };


    const clearUrlParams = (state) => {
        if (state == true) {
            const params = new URLSearchParams(location.search);
            localStorage.removeItem('selectedValues');
            // params.delete('configuration');
            // params.delete('bhk');
            // params.delete('type');
            // params.delete('minprice');
            // params.delete('maxprice');
            // params.delete('project_status');
            ['configuration', 'bhk', 'type', 'minprice', 'maxprice', 'project_status', 'carpet_min', 'carpet_max', 'searchtype', 'locality_name', 'possession_months', 'orderby', 'nlp'].forEach(param => params.delete(param));
            getNumericParts([])
            setSelectedPossessionStatus([]);
            setSelectedStatus([]);
            setSelectedBHK([]);
            setSelectedConf([]);
            setSortCriteria('relevance');
            setPriceRange([500000, 50000000]);
            setBuiltRange([100, 5000]);
            setAllParams1({});
            setSelectedBHK([]);
            getProjectBhkType([])
            setShowAllBhk(false);
            setParams([])
            setSelectedTab(null)
            setSearchOptions([]);
            setPaginatedData([])
            setCurrentPage(0);
            setPage(1);
            const newUrl = `${currentUrl}?${decodeURIComponent(params)}`;
            navigate(newUrl);
        }
    }

    // const toggleShowAllConf = () => {
    //     setShowAllConf(!showAllConf);
    // };

    const convertToJSON = (data) => {
        return JSON.stringify(data, (key, value) => {
            if (Array.isArray(value)) {
                return value.map((item) => convertToJSON(item));
            } else if (typeof value === 'object') {
                return convertToJSON(value);
            }
            return value;
        });
    };

    const handleShowFilterSidebarBtn = () => {
        const rightSideDiv = document.querySelector('.filter-sidebar');
        rightSideDiv.classList.toggle('active');
    }
    const handleHideFilterSidebarBtn = () => {
        // const filterForm = document.querySelector('.filter-sidebar');
        // filterForm.reset();
        handleShowFilterSidebarBtn();
        window.scrollTo(0, 0);
    }
    const handleShowSortSidebarBtn = () => {
        const sortSideBar = document.getElementById('sort-filter');
        sortSideBar.classList.toggle('active');
    }
    const handleHideSortSidebarBtn = () => {
        const sortSideBar = document.getElementById('sort-filter');
        sortSideBar.classList.remove('active');
    }
    const searchInputRef = useRef(null);
    const suggestionContainerRef = useRef(null);

    const handleOutsideClick = (e) => {
        if (
            suggestionContainerRef.current &&
            !suggestionContainerRef.current.contains(e.target) &&
            searchInputRef.current &&
            !searchInputRef.current.contains(e.target)
        ) {
            showSearchResult(false);
            // console.log("Outside click");
        }
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992);
        };

        // Add event listener to track window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{seoTitle}</title>
                <meta name="description" content={seoDescription} />
                {/* <link rel="canonical" href={`${appUrl}/${currentUrl}`} /> */}
            </Helmet>
            {/* <Header onCityChange={handleCityChange}/> */}
            <React.Fragment key={pageDetails.pageslug}>
                <div className={isPdp ? 'irfslist-class' : ''}>
                    <main className='plp-page pb-5 pt-lg-5 pt-0 mt-5'>
                        <section className="pt-lg-3 pt-2">
                            <div className="searchlist hero-searchmain position-relative col-lg-8 col-12 align-self-center d-block d-lg-none" ref={searchInputRef}>
                                <div className="text-start position-relative">
                                    <div className="search-component">
                                        {isMobile ? (
                                            <SearchPopupIrfs buttonShow={true} iconShow={false} />
                                        ) : (
                                            <SearchInputIrfs />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="px-2 px-sm-4">
                                <div className="row">
                                    <div className="col-12 mb-sm-4 mb-2">
                                        <div className="d-flex row">
                                            <div className='col-lg-6 col-md-4 col-8'>
                                                {paginatedData ? (
                                                    paginatedData.length === 0 && loading ? (
                                                        (noDataFound ? (
                                                            <></>
                                                        ) : (
                                                            <Placeholder animation="wave" >
                                                                <Placeholder xs={12} className="custom-loading-skeleton" />
                                                            </Placeholder>
                                                        ))

                                                    ) : (
                                                        <h1 className="fs-6 mt-2 fw-bold">
                                                            {
                                                                urlChanged ? (
                                                                    // <span className="text-primary text-orange">Calculating ...</span>
                                                                    <Spinner animation="grow" />
                                                                    // <Loader />
                                                                ) : (
                                                                    totalItems > 0 && (
                                                                        <>
                                                                            <span className="text-primary text-orange">Showing </span>
                                                                            <span id="search-count" className="text-primary text-orange">{totalItems} </span>
                                                                            {totalItems === 1 ? "Property" : "Properties"}
                                                                        </>
                                                                    )
                                                                )
                                                            }
                                                        </h1>

                                                    )
                                                ) : (
                                                    <Placeholder animation="wave" >
                                                        <Placeholder xs={12} className="custom-loading-skeleton" />
                                                    </Placeholder>
                                                )}
                                            </div>
                                            <div className='col-lg-6 col-md-8 col-4 row pe-0'>
                                                <div className='col-12 text-end d-inline-flex align-items-center justify-content-end pe-0 text-end'>

                                                    <span className='me-2 hidden-ipro'>Sort by: </span>
                                                    <select className="sort-filter form-select form-select-sm hidden-ipro rounded-pill" value={sortCriteria} onChange={(e) => [getSortProjectFilter(e.target.value), createGa4Event('On Chnage', 'Configuration Type Filter', e.target.value)]} style={{ "width": "200px" }}>
                                                        <option value="relevance">Relevance</option>
                                                        <option value="recentlyLaunched">Recently Launched</option>
                                                        <option value="priceLowToHigh">Price: Low to High</option>
                                                        <option value="priceHighToLow">Price: High to Low</option>
                                                        <option value="sqftLowToHigh">Sq. Ft.: Low to High</option>
                                                        <option value="sqftHighToLow">Sq. Ft.: High to Low</option>
                                                        <option value="possessionDate">Possession Date (Recent First)</option>
                                                        {/* <option value="updatedat">Latest Updated</option> */}
                                                    </select>
                                                    <a className={`text-primary ms-2 text-decoration-underline border p-1 border-primary border-orange rounded-pill`} onClick={() => createGa4Event('On Click', 'List View', 'List View')}>
                                                        <Icon className='i-icon' icon="uim:list-ul" style={{ "color": "#43A66A", "width": "40px", "height": "25px" }} />
                                                    </a>
                                                    {isPdp == true ? (
                                                        <Link to={`${appAbsPath}/irfs/projects/map-view${location.search}`} className={`text-primary ms-2 text-decoration-underline border p-1 rounded-pill`} onClick={() => createGa4Event('On Click', 'Map View', 'Map View')}>
                                                            <Icon className='' icon="uiw:map" style={{ "color": "#6b6b6b", "width": "40px", "height": "25px" }} />
                                                        </Link>
                                                    ) : (
                                                        <Link to={`${appAbsPath}/projects/map-view${location.search}`} className={`text-primary ms-2 text-decoration-underline border p-1 rounded-pill`} onClick={() => createGa4Event('On Click', 'Map View', 'Map View')}>
                                                            <Icon className='' icon="uiw:map" style={{ "color": "#6b6b6b", "width": "40px", "height": "25px" }} />
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>

                                            <section className="map-sec">
                                                <div id="image" style={{ "display": "none" }}>
                                                    <img src={require("../../../Assets/img//mapview-img.webp")} className="d-block rounded w-100 object-fit-cover h-100 images-corousal" />
                                                </div>
                                            </section>

                                        </div>
                                    </div>
                                </div>
                                <div className="row flex-column-reverse flex-md-row">
                                    <div className="d-flex flex-column gap-4 rounded project-listing-main col-12 col-xl-9">

                                        <div className='min-height-100vh'>
                                            {paginatedData ? (
                                                paginatedData.length === 0 && !loading ? (
                                                    totalItems === 0 ? (
                                                        <>
                                                            <div className="noProjectWrapper">
                                                                <p className="text-center" style={{ textWrap: 'balance' }}>
                                                                    <Icon
                                                                        icon="fa6-regular:face-sad-tear"
                                                                        className="me-1 mb-1 align-middle"
                                                                        color="#667486"
                                                                    />
                                                                    Sorry, we couldnt find any projects matching your criteria.
                                                                    <br />
                                                                    Explore similar nearby projects or refine your search for more options.
                                                                </p>
                                                            </div>
                                                            <div className=" w-100 ml-0">
                                                                <RelatedProjects
                                                                    isProjectListing="true"
                                                                    locationName={nearByLocation ?? ''}
                                                                    slidesToShow={[3, 3, 3, 2, 1]}
                                                                    is_irfs={isPdp}
                                                                    selectedTab={selectedBHK || selectedConf}
                                                                    handleTabClick={handleTabClick}
                                                                    getMinMaxCarpet={getMinMaxCarpet}
                                                                    appAbsPath={appAbsPath}
                                                                    userdetails={userdetails}
                                                                    isWishlistActive={isWishList}
                                                                    loggeduserdetails={parsedUserData}
                                                                    locality_name={nearByLocation}
                                                                    totalListProjCount={totalItems}
                                                                />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <Loader />
                                                    )
                                                ) : (
                                                    <div className="row">
                                                        <Suspense fallback={<Loader />}>
                                                            <InfiniteScroll
                                                                dataLength={paginatedData.length} // Total number of items fetched so far
                                                                next={getProjects} // Function to fetch more projects
                                                                hasMore={hasMore} // Boolean to indicate if there are more projects to load
                                                                loader={
                                                                    <h4 className='loaderWrapper'>
                                                                        <span className="custom-loader m-auto"></span>
                                                                    </h4>
                                                                }
                                                                endMessage={
                                                                    <>
                                                                        <div className=" w-100 ml-0">
                                                                            <RelatedProjects
                                                                                isProjectListing="true"
                                                                                locationName={nearByLocation ?? ''}
                                                                                slidesToShow={[3, 3, 3, 2, 1]}
                                                                                is_irfs={isPdp}
                                                                                selectedTab={selectedBHK || selectedConf}
                                                                                handleTabClick={handleTabClick}
                                                                                getMinMaxCarpet={getMinMaxCarpet}
                                                                                appAbsPath={appAbsPath}
                                                                                userdetails={userdetails}
                                                                                isWishlistActive={isWishList}
                                                                                loggeduserdetails={parsedUserData}
                                                                                locality_name={nearByLocation}
                                                                                totalListProjCount={totalItems}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                }
                                                            >
                                                                {paginatedData.map((project, index) => (
                                                                    <ProjectCardListing
                                                                        key={index}
                                                                        project={project}
                                                                        selectedTab={selectedBHK || selectedConf}
                                                                        handleTabClick={handleTabClick}
                                                                        getMinMaxCarpet={getMinMaxCarpet}
                                                                        appAbsPath={appAbsPath}
                                                                        userdetails={userdetails}
                                                                        isWishlistActive={isWishList}
                                                                        loggeduserdetails={parsedUserData}
                                                                        locality_name={locality_name}
                                                                    />
                                                                ))}
                                                            </InfiniteScroll>
                                                        </Suspense>
                                                    </div>
                                                )
                                            ) : (
                                                <Loader />
                                            )}
                                        </div>


                                    </div>


                                    <div className="col-12 col-md-3 hidden-ipro">
                                        <FilterSidebar
                                            selectedStatus={selectedStatus} toggleStatus={toggleStatus}
                                            togglePossessionStatus={togglePossessionStatus} selectedPossessionStatus={selectedPossessionStatus}
                                            // sortProjectsByStatus={sortProjectsByStatus}
                                            filteredByBhk={filteredByBhk} initialDisplayLimit={initialDisplayLimit} showAllBhk={showAllBhk} toggleShowAllBhk={toggleShowAllBhk} selectedBHK={selectedBHK}
                                            toggleBhkType={toggleBhkType} sortProjectsByBhkType={sortProjectsByBhkType} setSelectedTab={setSelectedTab}
                                            priceRange={priceRange} handleSliderChange={handleSliderChange} handleSliderChangeComplete={handleSliderChangeComplete}
                                            builtUpArea={builtUpArea} handleSliderChangeArea={handleSliderChangeArea} handleSliderChangeCompleteArea={handleSliderChangeCompleteArea}
                                            getProjectstatusData={getProjectstatusData} clearUrlParams={clearUrlParams}
                                        />
                                    </div>
                                </div >
                            </div >

                        </section >

                        <MobileFilter
                            visible={visible}
                            handleShowSortSidebarBtn={handleShowSortSidebarBtn}
                            // handleShowFilterSidebarBtn={handleShowFilterSidebarBtn}
                            clearUrlParams={clearUrlParams}
                            activeSort={activeSort}
                            getSortProjectFilter={getSortProjectFilter}
                            handleHideSortSidebarBtn={handleHideSortSidebarBtn}
                            hasParams={hasParams}
                            createGa4Event={createGa4Event}
                            filteredByBhk={filteredByBhk}
                            selectedBHK={selectedBHK}
                            showAllBhk={showAllBhk}
                            initialDisplayLimit={initialDisplayLimit}
                            toggleBhkType={toggleBhkType}
                            sortProjectsByBhkType={sortProjectsByBhkType}
                            setSelectedTab={setSelectedTab}

                            priceRange={priceRange}
                            handleSliderChange={handleSliderChange}
                            handleSliderChangeComplete={handleSliderChangeComplete}
                            builtUpArea={builtUpArea}
                            handleSliderChangeArea={handleSliderChangeArea}
                            handleSliderChangeCompleteArea={handleSliderChangeCompleteArea}
                            getProjectstatusData={getProjectstatusData}

                            selectedStatus={selectedStatus} toggleStatus={toggleStatus}
                            selectedPossessionStatus={selectedPossessionStatus} togglePossessionStatus={togglePossessionStatus}
                            handleFilterArea={handleFilterArea}
                        />

                    </main>
                </div>
            </React.Fragment>
        </>
    )
}
export default ProjectList;