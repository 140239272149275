import React, { useEffect, useState, useRef } from 'react';
import { userToken, executeLaravelAPI, showToastMessage } from '../../Utils';
import ReactPaginate from 'react-paginate';
const itemsPerPageOptions = [20, 50, 100, 500, 1000];
const Leads = () => {
    const pageDetails = { 'pagetitle': 'Leads', 'pageslug': 'leads' };
    
    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [ setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [sort, setSort] = useState('id');
    const [order, setOrder] = useState('asc');
    const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
    const [searchQuery, setSearchQuery] = useState('');
    const searchInputRef = useRef(null);
    const inputRef = useRef(null);


    useEffect(() => {
        document.title = pageDetails.pagetitle;
        fetchLeads();
    }
        , [currentPage, sort]);

    const fetchProjectName = async (id) => {
        const response = await executeLaravelAPI('project/view/' + id, '', 'GET', userToken);
        if (response.status === 200) {
            return response.data.name;
        }
        return '';

    }
    const fetchDeveloperName = async (id) => {
        const response = await executeLaravelAPI('developer/' + id, '', 'GET', userToken);
        if (response.status === 200) {
            return response.data.name;
        }
        return '';

    }
    const fetchLeads = async () => {
        setLoading(true);
        setError('');

        try {
            const response = await executeLaravelAPI('enquiries', '', 'GET', userToken);
            if (response.status === 200) {

                const leadsData = response.data.data;
                for (let i = 0; i < leadsData.length; i++) {
                    const lead = leadsData[i];
                    const projectId = lead.project_id;
                    const projectName = await fetchProjectName(projectId);

                    const developerId = lead.developer_id;
                    const developerName = await fetchDeveloperName(developerId);

                    leadsData[i].developerName = developerName;
                    leadsData[i].projectName = projectName;
                }

                setLeads(leadsData);
                setTotalPages(response.data.last_page);
                setTotalItems(response.data.total);
                setLoading(false);
                console.log(leadsData);
            } else {
                setError('Error fetching leads');
                setLoading(false);
            }
        } catch (error) {
            setError('Error fetching leads');
            setLoading(false);
        }

    }

    const handleSort = (field) => {
        if (field === sort) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setSort(field);
            setOrder('asc');
        }
    }

    const handlePageClick = ({ selected }) => {
        // alert(selected);
        setCurrentPage(selected);
        fetchLeads((selected + 1));
    };
    const handleItemsPerPageChange = (e) => {
        const selectedValue = parseInt(e.target.value, 10);
        setItemsPerPage(selectedValue);
        setCurrentPage(0); // Reset to the first page when changing items per page
    };

   

    let isApiCallInProgress = false;
    let debounceTimer; // Timer for debouncing API calls
    
    async function executeApiCall(inputValue) {
        if (isApiCallInProgress) {
            return; // Exit if an API call is already in progress
        }
    
        isApiCallInProgress = true;
        setLoading(true); // Indicate the loading state.
    
        try {
            if (inputValue !== '') {
                const response = await executeLaravelAPI('enquiry/' + inputValue, '', 'GET', userToken);
    
                if (response.status === 200) {
                    const leadsData = response.data.leads;
    
                    for (let i = 0; i < leadsData.length; i++) {
                        const lead = leadsData[i];
                        lead.projectName = await fetchProjectName(lead.project_id);
                        lead.developerName = await fetchDeveloperName(lead.developer_id);
                    }
    
                    console.log('Updated leads data:', leadsData);
                } else {
                    showToastMessage('Lead Not Found', 'top-right', 'warning');
                    setError('Error fetching leads');
                }
            } else {
                showToastMessage('Input is empty', 'top-right', 'warning');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            showToastMessage('Something went wrong', 'top-right', 'error');
            setError(`Error fetching data: ${error.message}`);
        } finally {
            setLoading(false);
            isApiCallInProgress = false;
        }
    }
    
    const handleInputChange = (e) => {
        clearTimeout(debounceTimer);
        const inputValue = e.target.value.trim();
        setSearchQuery(inputValue);
    
        debounceTimer = setTimeout(() => {
            if (!isApiCallInProgress && inputValue !== '') {
                executeApiCall(inputValue);
            }
        }, 2000);
    };
    

    return (
        <>
            <section className="content">
                <div className="row">
                    <div className='col-lg-12'>
                        <div className='position-relative' ref={searchInputRef}>
                            <div className='position-realtive'>
                                <input
                                    type="search"
                                    className='form-control search-input shadow-none border-none'
                                    placeholder="Search by Lead ID eg. 1001"
                                    value={searchQuery}
                                    onChange={handleInputChange}
                                    ref={inputRef}
                                    autoComplete='off'
                                    style={{ background: "url('https://api.iconify.design/ion/search-outline.svg') no-repeat 10px center / 24px" }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-5">
                        <div className="box">
                            <div className="box-header with-border">
                                <h3 className="box-title">Leads</h3>
                            </div>
                            <div className="box-body">

                                <div className="box-body table-container mt-4 hr-scroll-style">
                                    <table className="table table-resposive table-hover dashboard-table shadow-sm mb-0">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '10px' }}>Id</th>
                                                <th onClick={() => handleSort('name')}>Name</th>
                                                <th onClick={() => handleSort('email')}>Email</th>
                                                <th onClick={() => handleSort('phone')}>Phone</th>
                                                <th onClick={() => handleSort('status')}>Project</th>
                                                <th onClick={() => handleSort('status')}>Developer</th>
                                                {/* <th style={{ width: '40px' }}>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (
                                                <tr><td colSpan={7}><div className='loading'><span className="loadingspan">Loading...</span><span className="custom-loader"></span></div></td></tr>
                                            ) : (
                                                leads.map((lead, index) => (
                                                    <React.Fragment key={`fragment-${lead.id}-${index}`}>

                                                        <tr key={lead.id} >
                                                            <td className='sticky-column'> {lead.id}</td>
                                                            <td className='sticky-column column-snd'>{lead.name} </td>
                                                            <td>{lead.email}</td>
                                                            <td>
                                                                {lead.phone}
                                                            </td>
                                                            <td>{lead.projectName}</td>
                                                            <td className="text-capitalize">
                                                                {lead.developerName}
                                                            </td>

                                                            {/* <td className='sticky-column'>
                                                        <a href="javascript:;" onClick={() => toggleDetails(index, project.id, project.address.id)} data-project-id={project.id} data-index={index + 1}>
                                                            { buttonIcon[(index)] ? <Icon icon='ion:close-outline' style={{ fontSize: '30px', color: '#192951' }} /> : <Icon icon='fe:edit' style={{ fontSize: '24px', color: '#192951' }} />}
                                                        </a>
                                                    </td> */}
                                                        </tr>
                                                    </React.Fragment>
                                                ))
                                            )
                                            }
                                        </tbody>
                                        <tfoot></tfoot>
                                    </table>
                                </div>


                                <div className='row mt-5 align-items-center'>
                                    <div className='col-lg-4'>
                                        {/* Display "Showing x-y of z items" */}
                                        <p className='graylight mb-0'>Showing {currentPage * itemsPerPage + 1}-{Math.min((currentPage + 1) * itemsPerPage, totalItems)} of {totalItems} items</p>
                                    </div>
                                    <div className='col-lg-8 d-flex justify-content-end align-items-center'>
                                        {/* Render pagination component */}
                                        <div className='me-3'>
                                            <span className='graylight'>Results per page </span>
                                            <select className='showPerPage' value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                                {itemsPerPageOptions.map((option) => (
                                                    <option key={option} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <ReactPaginate
                                            pageCount={Math.ceil(totalItems / itemsPerPage)}
                                            onPageChange={handlePageClick}
                                            containerClassName={'pagination justify-content-end mb-0'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            activeClassName={'active'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            breakClassName={'page-item'}
                                            breakLinkClassName={'page-link'}
                                            disableInitialCallback={true}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Leads;
