import React from 'react';
const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="main-footer">
            <strong>Copyright &copy; {currentYear} <a href="https://www.beyondwalls.com/" target="_blank" rel="noreferrer">Beyondwalls</a>.</strong> All rights reserved.
        </footer>
    );
}
export default Footer;
