import Cookies from 'js-cookie';
import { Table } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { executeLaravelAPI, userToken } from './Utils';


export const setCookie = (name, value) => {
    Cookies.set(name, value, {
        sameSite: 'strict',
        secure: true,
    });
};

export const getCookie = (name) => {
    return Cookies.get(name);
};

// #check if user loged in or not

const getUserLogedInData = () => {
    const userLogedInData = getCookie('userData');
    if (userLogedInData === undefined || userLogedInData === null || userLogedInData === '') {
        return {};
    }
    let userDataJson = JSON.parse(userLogedInData);
    return userLogedInData ? userDataJson : {};

}

const userdata = getUserLogedInData();



export const setUserId = async (req, res) => {
    let userId = getCookie('userId');
    if (userId === undefined || userId === null || userId === '') {
        userId = uuidv4();
        setCookie('userId', userId);
    }

    if (Object.keys(userdata).length !== 0 && (userdata !== null || userdata !== undefined || userdata !== '')) {
        if (userdata.cookie_user_id == null || userdata.cookie_user_id == undefined || userdata.cookie_user_id == '') {
            const data = {
                user_id: userId
            };
            const result = await executeLaravelAPI(`cookieidinuser`, data, 'POST', userToken);
        } else {
            const data = {
                user_id: userId
            };
            const result = await executeLaravelAPI(`cookieidinuser`, data, 'POST', userToken);
            setCookie('userId', userdata.cookie_user_id);
        }
    }
    return getCookie('userId');
}

export const setUserProjectView = async (projectID) => {
    // Get the current 'userProjectView' cookie
    const cookie = getCookie('userProjectView');
    let userProjectView = cookie ? decodeURIComponent(cookie).split(',') : [];

    // Get the project ID from the request parameters
    const projectId = projectID;
    if (!projectId) {
        return false;
    }

    // Add the project ID if it doesn't already exist in the array
    if (!userProjectView.includes(projectId.toString())) {
        userProjectView.push(projectId.toString());
    }

    // Ensure the project IDs are unique
    userProjectView = Array.from(new Set(userProjectView));

    // Update the 'userProjectView' cookie
    const updatedUserProjectView = encodeURIComponent(userProjectView.join(','));
    if (Object.keys(userdata).length !== 0 && (userdata !== null || userdata !== undefined || userdata !== ''))  {
        const data = {
            project_id: userProjectView
        };
        const result = await executeLaravelAPI(`cookieuserprojectviewdata`, data, 'POST', userToken);
    }
    setCookie('userProjectView', updatedUserProjectView);

    // Return the updated view as a response
    return getCookie('userProjectView');
};

export const setUserProjectCompare = async (projectIDs) => {

    // Get the current 'userProjectCompare' cookie
    const cookie = getCookie('userProjectCompare');
    let userProjectCompare = cookie ? decodeURIComponent(cookie).split(',') : [];

    // Check if projectIDs is an array
    if (Array.isArray(projectIDs)) {
        // Convert the array of project IDs to a comma-separated string
        const projectIdsString = projectIDs.join(',');

        // Split the string by comma and trim any whitespace
        const projectIdsArray = projectIdsString.split(',').map(id => id.trim());

        if (!projectIdsArray || projectIdsArray.length === 0) {
            return false;
        }

        // Add the project IDs if they don't already exist in the array
        projectIdsArray.forEach((projectId) => {
            if (!userProjectCompare.includes(projectId.toString())) {
                userProjectCompare.push(projectId.toString());
            }
        });

        // Ensure the project IDs are unique
        userProjectCompare = Array.from(new Set(userProjectCompare));

        // Update the 'userProjectCompare' cookie
        const updatedUserProjectCompare = encodeURIComponent(userProjectCompare.join(','));

        if (Object.keys(userdata).length !== 0 && (userdata !== null || userdata !== undefined || userdata !== ''))  {
            const data = {
                project_id: userProjectCompare
            };
            const result = await executeLaravelAPI(`cookieuserprojectcomparedata`, data, 'POST', userToken);
        }


        setCookie('userProjectCompare', updatedUserProjectCompare);

        // console.log('Updated userProjectCompare:', getCookie('userProjectCompare'));
        // Return the updated view as a response
        return getCookie('userProjectCompare');
    } else {
        console.log('Invalid input format for projectIDs.');
        return false;
    }
}; 


export const setProjectStatusFilter = (status) => {
    // Get the current 'projectStatusFilter' cookie
    const cookie = getCookie('userProjectStatusFilter');
    let projectStatusFilter = cookie ? decodeURIComponent(cookie).split(',') : [];

    // Get the project status from the request parameters
    const projectStatus = status;
    if (!projectStatus) {
        return false;
    }

    // Add the project status if it doesn't already exist in the array
    if (!projectStatusFilter.includes(projectStatus.toString())) {
        projectStatusFilter.push(projectStatus.toString());
    }

    // Ensure the project status are unique
    projectStatusFilter = Array.from(new Set(projectStatusFilter));

    // Update the 'projectStatusFilter' cookie
    const updatedProjectStatusFilter = encodeURIComponent(projectStatusFilter.join(','));

    if (Object.keys(userdata).length !== 0 && (userdata !== null || userdata !== undefined || userdata !== ''))  {
        const data = {
            project_status: projectStatusFilter
        };
        const result = executeLaravelAPI(`cookieuserprojectstatusfilterdata`, data, 'POST', userToken);
    }

    setCookie('userProjectStatusFilter', updatedProjectStatusFilter);

    // Return the updated view as a response
    return getCookie('userProjectStatusFilter');
}

export const setProjectConfigurationFilter = (configuration) => {
    // Get the current 'projectConfigurationFilter' cookie
    const cookie = getCookie('userProjectConfigurationFilter');
    let projectConfigurationFilter = cookie ? decodeURIComponent(cookie).split(',') : [];

    // Get the project configuration from the request parameters
    const projectConfiguration = configuration;
    if (!projectConfiguration) {
        return false;
    }

    // Add the project configuration if it doesn't already exist in the array
    if (!projectConfigurationFilter.includes(projectConfiguration.toString())) {
        projectConfigurationFilter.push(projectConfiguration.toString());
    }

    // Ensure the project configurations are unique
    projectConfigurationFilter = Array.from(new Set(projectConfigurationFilter));

    // Update the 'projectConfigurationFilter' cookie
    const updatedProjectConfigurationFilter = encodeURIComponent(projectConfigurationFilter.join(','));

    if (Object.keys(userdata).length !== 0 && (userdata !== null || userdata !== undefined || userdata !== ''))  {
        const data = {
            project_configuration: projectConfigurationFilter
        };
        const result = executeLaravelAPI(`cookieuserprojectconfigurationfilterdata`, data, 'POST', userToken);
    }

    setCookie('userProjectConfigurationFilter', updatedProjectConfigurationFilter);

    // Return the updated view as a response
    return getCookie('userProjectConfigurationFilter');
}

export const setProjectMaxBudgetFilter = (budget) => {
    // Get the current 'projectBudgetFilter' cookie
    const cookie = getCookie('userProjectMaxBudgetFilter');
    let projectBudgetFilter = decodeURIComponent(cookie);

    // Get the project budget from the request parameters
    const projectBudget = budget;
    if (!projectBudget) {
        return false;
    }

    // Add the project budget if it doesn't already exist in the array
    if (projectBudgetFilter !== "" && projectBudgetFilter !== null && projectBudgetFilter !== undefined) {
        projectBudgetFilter = budget;
    } else {
        projectBudgetFilter = projectBudget;
    }

    // Ensure the project budgets are unique
    // projectBudgetFilter = Array.from(new Set(projectBudgetFilter));

    // Update the 'projectBudgetFilter' cookie
    const updatedProjectBudgetFilter = encodeURIComponent(projectBudgetFilter);
    if (Object.keys(userdata).length !== 0 && (userdata !== null || userdata !== undefined || userdata !== ''))  {
        const data = {
            project_max_budget: projectBudgetFilter
        };
        const result = executeLaravelAPI(`cookieuserprojectmaxbudgetfilterdata`, data, 'POST', userToken);
    }
    setCookie('userProjectMaxBudgetFilter', updatedProjectBudgetFilter);

    // Return the updated view as a response
    return getCookie('userProjectMaxBudgetFilter');
}

export const setProjectSearchProjectStatus = (status) => {
    // Get the current 'userProjectSearchProjectStatus' cookie
    const cookie = getCookie('userProjectSearchProjectStatus');
    let projectSearchProjectStatus = cookie ? decodeURIComponent(cookie).split(',') : [];

    // Get the project status from the request parameters
    const projectStatus = status;
    if (!projectStatus) {
        return false;
    }

    // Add the project Status if it doesn't already exist in the array
    if (!projectSearchProjectStatus.includes(projectStatus.toString())) {
        projectSearchProjectStatus.push(projectStatus.toString());
    }

    // Ensure the project Statuses are unique
    projectSearchProjectStatus = Array.from(new Set(projectSearchProjectStatus));

    // Update the 'projectSearchProjectStatus' cookie
    const updatedProjectSearchProjectStatus = encodeURIComponent(projectSearchProjectStatus.join(','));
    if (Object.keys(userdata).length !== 0 && (userdata !== null || userdata !== undefined || userdata !== ''))  {
        const data = {
            project_search_project_status: projectSearchProjectStatus
        };
        const result = executeLaravelAPI(`cookieuserprojectsearchprojectstatusdata`, data, 'POST', userToken);
    }
    setCookie('userProjectSearchProjectStatus', updatedProjectSearchProjectStatus);

    // Return the updated view as a response
    return getCookie('userProjectSearchProjectStatus');
}

export const setProjectSearchPossessionMonths = (months) => {
    // Get the current 'userProjectSearchPossessionMonths' cookie
    const cookie = getCookie('userProjectSearchPossessionMonths');
    let projectSearchPossessionMonths = cookie ? decodeURIComponent(cookie).split(',') : [];

    // Get the project possession months from the request parameters
    const projectPossessionMonths = months;
    if (!projectPossessionMonths) {
        return false;
    }

    // Add the project Possession Months if it doesn't already exist in the array
    if (!projectSearchPossessionMonths.includes(projectPossessionMonths.toString())) {
        projectSearchPossessionMonths.push(projectPossessionMonths.toString());
    }

    // Ensure the project Possession Months are unique
    projectSearchPossessionMonths = Array.from(new Set(projectSearchPossessionMonths));

    // Update the 'projectSearchPossessionMonths' cookie
    const updatedProjectSearchPossessionMonths = encodeURIComponent(projectSearchPossessionMonths.join(','));
    if (Object.keys(userdata).length !== 0 && (userdata !== null || userdata !== undefined || userdata !== ''))  {
        const data = {

            project_search_possession_months: projectSearchPossessionMonths
        };
        const result = executeLaravelAPI(`cookieuserprojectsearchpossessionmonthsdata`, data, 'POST', userToken);
    }
    setCookie('userProjectSearchPossessionMonths', updatedProjectSearchPossessionMonths);

    // Return the updated view as a response
    return getCookie('userProjectSearchPossessionMonths');
}

export const setProjectSearchLocation = (location) => {
    // Get the current 'userProjectSearchLocation' cookie
    const cookie = getCookie('userProjectSearchLocation');
    let projectSearchLocation = cookie ? decodeURIComponent(cookie).split(',') : [];

    // Get the project location from the request parameters
    const projectLocation = location;
    if (!projectLocation) {
        return false;
    }

    // Add the project Location if it doesn't already exist in the array
    if (!projectSearchLocation.includes(projectLocation.toString())) {
        projectSearchLocation.push(projectLocation.toString());
    }

    // Ensure the project Locations are unique
    projectSearchLocation = Array.from(new Set(projectSearchLocation));

    // Update the 'projectSearchLocation' cookie
    const updatedProjectSearchLocation = encodeURIComponent(projectSearchLocation.join(','));
    if (Object.keys(userdata).length !== 0 && (userdata !== null || userdata !== undefined || userdata !== ''))  {
        const data = {
            project_search_location: projectSearchLocation
        };
        const result = executeLaravelAPI(`cookieuserprojectsearchlocationdata`, data, 'POST', userToken);
    }

    setCookie('userProjectSearchLocation', updatedProjectSearchLocation);

    // Return the updated view as a response
    return getCookie('userProjectSearchLocation');
}

export const setProjectSearchDeveloper = (developer) => {
    // Get the current 'userProjectSearchDeveloper' cookie
    const cookie = getCookie('userProjectSearchDeveloper');
    let projectSearchDeveloper = cookie ? decodeURIComponent(cookie).split(',') : [];

    // Get the project developer from the request parameters
    const projectDeveloper = developer;
    if (!projectDeveloper) {
        return false;
    }

    // Add the project Developer if it doesn't already exist in the array
    if (!projectSearchDeveloper.includes(projectDeveloper.toString())) {
        projectSearchDeveloper.push(projectDeveloper.toString());
    }

    // Ensure the project Developers are unique
    projectSearchDeveloper = Array.from(new Set(projectSearchDeveloper));

    // Update the 'projectSearchDeveloper' cookie
    const updatedProjectSearchDeveloper = encodeURIComponent(projectSearchDeveloper.join(','));

    if (Object.keys(userdata).length !== 0 && (userdata !== null || userdata !== undefined || userdata !== ''))  {
        const data = {
            project_search_developer: projectSearchDeveloper
        };
        const result = executeLaravelAPI(`cookieuserprojectsearchdeveloperdata`, data, 'POST', userToken);
    }
    setCookie('userProjectSearchDeveloper', updatedProjectSearchDeveloper);

    // Return the updated view as a response
    return getCookie('userProjectSearchDeveloper');
}

export const setProjectSearchConfiguration = (configuration) => {
    // Get the current 'userProjectSearchConfiguration' cookie
    const cookie = getCookie('userProjectSearchConfiguration');
    let projectSearchConfiguration = cookie ? decodeURIComponent(cookie).split(',') : [];

    // Get the project configuration from the request parameters
    const projectConfiguration = configuration;
    if (!projectConfiguration) {
        return false;
    }

    // Add the project Configuration if it doesn't already exist in the array
    if (!projectSearchConfiguration.includes(projectConfiguration.toString())) {
        projectSearchConfiguration.push(projectConfiguration.toString());
    }

    // Ensure the project Configurations are unique
    projectSearchConfiguration = Array.from(new Set(projectSearchConfiguration));

    // Update the 'projectSearchConfiguration' cookie
    const updatedProjectSearchConfiguration = encodeURIComponent(projectSearchConfiguration.join(','));

    if (Object.keys(userdata).length !== 0 && (userdata !== null || userdata !== undefined || userdata !== ''))  {
        const data = {
            project_search_configuration: projectSearchConfiguration
        };
        const result = executeLaravelAPI(`cookieuserprojectsearchconfigurationdata`, data, 'POST', userToken);
    }
    setCookie('userProjectSearchConfiguration', updatedProjectSearchConfiguration);

    // Return the updated view as a response
    return getCookie('userProjectSearchConfiguration');
}
export const setProjectSearchProject = (project) => {
    // Get the current 'userProjectSearchProject' cookie
    const cookie = getCookie('userProjectSearchProject');
    let projectSearchProject = cookie ? decodeURIComponent(cookie).split(',') : [];

    // Get the project project from the request parameters
    const projectProject = project;
    if (!projectProject) {
        return false;
    }

    // Add the project Project if it doesn't already exist in the array
    if (!projectSearchProject.includes(projectProject.toString())) {
        projectSearchProject.push(projectProject.toString());
    }

    // Ensure the project Projects are unique
    projectSearchProject = Array.from(new Set(projectSearchProject));

    // Update the 'projectSearchProject' cookie
    const updatedProjectSearchProject = encodeURIComponent(projectSearchProject.join(','));
    if (Object.keys(userdata).length !== 0 && (userdata !== null || userdata !== undefined || userdata !== ''))  {
        const data = {
            project_search_project: projectSearchProject
        };
        const result = executeLaravelAPI(`cookieuserprojectsearchprojectdata`, data, 'POST', userToken);
    }
    setCookie('userProjectSearchProject', updatedProjectSearchProject);

    // Return the updated view as a response
    return getCookie('userProjectSearchProject');
}
export const setUserDownloadBrochure = (projectID) => {

    const cookie = getCookie('userDownloadBrochure');
    let userDownloadBrochure = cookie ? decodeURIComponent(cookie).split(',') : [];

    const projectId = projectID;
    if (!projectId) {
        return false;
    }

    if (!userDownloadBrochure.includes(projectId.toString())) {
        userDownloadBrochure.push(projectId.toString());
    }

    userDownloadBrochure = Array.from(new Set(userDownloadBrochure));

    const updatedUserDownloadBrochure = encodeURIComponent(userDownloadBrochure.join(','));

    if(userdata !== null || userdata !== undefined || userdata !== ''){
        const data = {
            user_download_brochure_project_name: userDownloadBrochure
        };
        const result = executeLaravelAPI(`cookieuserdownloadbrochuredata`, data, 'POST', userToken);
    }

    setCookie('userDownloadBrochure', updatedUserDownloadBrochure);

    return getCookie('userDownloadBrochure');

}


// # update or save all the data from cookies it db after user loged in



export const updateAllCookiesData = async () => {
    // alert("updateAllCookiesData")
    // Assuming userdata should be defined or imported elsewhere in your application.
    if (typeof userdata !== 'undefined' && userdata) {
        const data = {
            cookie_user_id: getCookie('userId') ? decodeURIComponent(getCookie('userId')) : null,
            project_view_id: getCookie('userProjectView') ? decodeURIComponent(getCookie('userProjectView')) : null,
            project_id_compare: getCookie('userProjectCompare') ? decodeURIComponent(getCookie('userProjectCompare')) : null,
            project_status: getCookie('userProjectStatusFilter') ? decodeURIComponent(getCookie('userProjectStatusFilter')) : null,
            project_configuration: getCookie('userProjectConfigurationFilter') ? decodeURIComponent(getCookie('userProjectConfigurationFilter')) : null,
            project_max_budget: getCookie('userProjectMaxBudgetFilter') ? decodeURIComponent(getCookie('userProjectMaxBudgetFilter')) : null,
            project_search_location: getCookie('userProjectSearchLocation') ? decodeURIComponent(getCookie('userProjectSearchLocation')) : null,
            project_search_developer: getCookie('userProjectSearchDeveloper') ? decodeURIComponent(getCookie('userProjectSearchDeveloper')) : null,
            project_search_configuration: getCookie('userProjectSearchConfiguration') ? decodeURIComponent(getCookie('userProjectSearchConfiguration')) : null,
            user_download_brochure: getCookie('userDownloadBrochure') ? decodeURIComponent(getCookie('userDownloadBrochure')) : null,
        };

        // console.log("updateAllCookiesData", data);
        // Uncomment and modify the following line according to your API calling function
        const result = await executeLaravelAPI(`cookieuseralldata`, data, 'POST', userToken); 

        return result;
    } else {
        console.log("No userdata found or userdata is not defined."); 
        return false;
    }
}

