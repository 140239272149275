import React, { useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import { executeLaravelAPI, formatNumber, userToken } from './../../Admin/Utils';
import Loader from '../../Loader';
import LoginPopup from '../Layouts/LoginPopup.js';
// import { compileString } from 'sass';
const UserActivityData = ({ onLogin }) => {
    const { selldoid } = useParams();
    // const [userActivity, setUserActivity] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [userActivityData, setUserActivityData] = useState([]);
    const [developerName, setDeveloperName] = useState([]);
    const [projectViewName, setProjectViewName] = useState([]);
    const [projectCompareName, setProjectCompareName] = useState([]);
    const [projectWishlistName, setProjectWishlistName] = useState([]);
    const [searchConfig, setSearchConfig] = useState([]);
    const [searchLocation, setSearchLocation] = useState([]);
    const [searchBHK, setSearchBHK] = useState([]);
    const [filterPrice, setFilterPrice] = useState([]);
    const [filterProjectStatus, setFilterProjectStatus] = useState([]);
    const [filterConfig, setFilterConfig] = useState([]);
    // const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
    const [isUserLogin, setIsUserLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [userProjectsBrochure, setUserProjectsBrochure] = useState([]);

    const handleShowLogin = () => {
        setShowRegister(false);
    };

    const handleShowSignup = () => {
        setShowRegister(true);
    };
    // if isUserLogin is false show popup to login
    const handleRegisterUserClick = (formRequest) => {
        document.getElementById('overlay').style.display = 'block';
    }
    const handleRegisterUserFormHideClick = () => {
        document.getElementById('overlay').style.display = 'none';
    }
    useEffect(() => {
        const fetchUserActivity = async () => {
            const result = await executeLaravelAPI(`cookieuserdata/${selldoid}`, '', 'GET');
            if (!result.data) {
                console.error("No data found for user.");
                return;
            }
            const dataProjectDetails = result.data;
            // console.log("asdasdasdad" + dataProjectDetails.wishlist)
            // Fetch developer name
            await fetchDeveloperName(dataProjectDetails.user_project_search_developer);

            // Fetch and set project details concurrently
            const projectDetailsPromises = [
                fetchAndSetNameArray(dataProjectDetails.user_projects_view, setProjectViewName, "Project view details are missing."),
                fetchAndSetNameArray(dataProjectDetails.user_projects_compare, setProjectCompareName, "Project compare details are missing."),
                fetchAndSetNameArray(dataProjectDetails.wishlist, setProjectWishlistName, "Project wishlist details are missing."),
                fetchAndSetNameArray(dataProjectDetails.user_download_brochure, setUserProjectsBrochure, "Project brochure details are missing.")

            ];

            await Promise.all(projectDetailsPromises);
            // Set search configurations
            setConfigurations(dataProjectDetails);
        };

        async function fetchDeveloperName(developerId) {
            if (!developerId) {
                console.error("Developer ID is missing.");
                return;
            }
            const developerName = await executeLaravelAPI(`developer/${developerId}`, '', 'GET');
            if (developerName.data && developerName.data.name) {
                setDeveloperName(developerName.data.name);
            } else {
                console.error("Developer data is not complete or missing.");
            }
        }

        async function fetchAndSetNameArray(dataString, setFunction, errorMessage) {
            if (!dataString) {
                console.error(errorMessage);
                return;
            }

            const ids = getSplitArray(dataString);


            const namesArray = await Promise.all(ids.map(async id => {
                try {
                    // console.log("dataStringid" + id)
                    const response = await executeLaravelAPI(`project/view/${id}`, '', 'GET');
                    return response.data && response.data.name ? response.data.name : null;
                } catch (error) {
                    console.error(`Error fetching data for project view ID: ${id}:`, error);
                    return null;
                }
            }));
            setFunction(namesArray.filter(name => name)); // Filter out null values
        }

        function setConfigurations(dataProjectDetails) {
            setSearchConfig(getSplitArray(dataProjectDetails.user_project_search_configuration));
            setSearchLocation(getSplitArray(dataProjectDetails.user_project_search_location));
            setFilterConfig(getSplitArray(dataProjectDetails.user_projects_configuration_filter));
            setFilterProjectStatus(getSplitArray(dataProjectDetails.user_project_status_filter));
            setSearchBHK(getSplitArray(dataProjectDetails.user_project_search_configuration));
            setFilterPrice(getSplitArray(dataProjectDetails.user_projects_max_budget_filter));
        }

        function getSplitArray(data, defaultValue = []) {
            if (typeof data === 'string') {
                // Remove brackets and whitespace, then split by comma
                return data.replace(/[\[\]\s]/g, '').split(',');
            }
            return defaultValue;
        }


        if (selldoid) {
            fetchUserActivity();
        }
        loading && setLoading(false);
    }, [selldoid]);

    // check if user logged in or not
    useEffect(() => {
        //progressBar(setLoading, setProgress);
        if (userToken !== null) {
            const checkAuthentication = async () => {
                try {
                    // Make a request to your Laravel backend to check if the user is authenticated
                    const userdata = await executeLaravelAPI('check-auth', '', 'GET', userToken);
                    if (userdata.status === 200) {
                        // console.log('User Logged In ' + userDetails.user.name);
                        setIsUserLogin(true);
                    } else {

                        setIsUserLogin(false);
                    }
                } catch (error) {
                    console.error('Error checking authentication:', error);
                }
            };
            checkAuthentication();
        } else {
            setIsUserLogin(false);
            //window.location.href=`${appAbsPath}`;
        }

        if (!isUserLogin) {
            handleRegisterUserFormHideClick();
            handleRegisterUserClick();
            handleShowLogin()
        }
    }, []);





    return (
        <>
            {isUserLogin && (

                <main className="project-detail-main pb-1 pt-1 mt-1 user-activity-data-contnet">
                    <section className="project-main pt-3">
                        <div className="px-3 px-md-5 px-sm-5"></div>
                        <div className="container-fluid mt-2">
                            {loading ? <Loader /> : (
                                (
                                    <div>
                                        <Row className="g-4 mt-3">

                                            {developerName!="" || developerName!=null  && (<Col md={4} sm={6}>
                                                <Card className="h-100">
                                                    <Card.Header>Developer</Card.Header>
                                                    <Card.Body>{developerName}</Card.Body>
                                                </Card>
                                            </Col>)}

                                            {projectViewName && (<Col md={4} sm={6}>
                                                <Card className="h-100">
                                                    <Card.Header>Project View</Card.Header>
                                                    <Card.Body>
                                                        <ul>{projectViewName.map((name, index) => <li key={index}>• {name}</li>)}</ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>)}

                                            <Col md={4} sm={6}>
                                                <Card className="h-100">
                                                    <Card.Header>Project Compare</Card.Header>
                                                    <Card.Body>
                                                        <ul>{projectCompareName.map((name, index) => <li key={index}>• {name}</li>)}</ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={4} sm={6}>
                                                <Card className="h-100">
                                                    <Card.Header>Project Wishlist</Card.Header>
                                                    <Card.Body>
                                                        <ul>{projectWishlistName.map((name, index) => <li key={index}>• {name}</li>)}</ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            <Col md={4} sm={6}>
                                                <Card className="h-100">
                                                    <Card.Header>Searched Configurations</Card.Header>
                                                    <Card.Body>
                                                        <ul>{searchConfig.map((config, index) => <li key={index}>• {config}</li>)}</ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={4} sm={6}>
                                                <Card className="h-100">
                                                    <Card.Header>Searched Locations</Card.Header>
                                                    <Card.Body>
                                                        <ul>{searchLocation.map((location, index) => <li key={index}>• {location}</li>)}</ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            <Col md={4} sm={6}>
                                                <Card className="h-100">
                                                    <Card.Header>Searched BHKs</Card.Header>
                                                    <Card.Body>
                                                        <ul>{searchBHK.map((bhk, index) => <li key={index}>• {bhk}</li>)}</ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={4} sm={6}>
                                                <Card className="h-100">
                                                    <Card.Header>Max Budget</Card.Header>
                                                    <Card.Body>
                                                        <ul>{filterPrice.map((price, index) => <li key={index}>• {formatNumber(price)}</li>)}</ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            <Col md={4} sm={6}>
                                                <Card className="h-100">
                                                    <Card.Header>Project Status Filters</Card.Header>
                                                    <Card.Body>
                                                        <ul>{filterProjectStatus.map((status, index) => <li key={index}>• {status}</li>)}</ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={4} sm={6}>
                                                <Card className="h-100">
                                                    <Card.Header>Configuration Filters</Card.Header>
                                                    <Card.Body>
                                                        <ul>{filterConfig.map((config, index) => <li key={index}>• {config}</li>)}</ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col md={4} sm={6}>
                                                <Card className="h-100">
                                                    <Card.Header>Brochure Downloaded</Card.Header>
                                                    <Card.Body>
                                                        <ul>{userProjectsBrochure.map((brochure, index) => <li key={index}>• {brochure}</li>)}</ul>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            )}
                        </div>
                    </section>

                </main>
            )}
            {isUserLogin === false && (
                <main className="user-activity-data">
                <LoginPopup onLogin={onLogin} handleRegisterUserFormHideClick={handleRegisterUserFormHideClick} />
                </main>
            )}
        </>
    );

}

export default UserActivityData;
