import React from 'react';

const CircularProgress = ({ percentage , color }) => {
  const radius = 20; // Radius of the circle
  const strokeWidth = 6; // Width of the progress bar stroke
  const circumference = 2 * Math.PI * radius; // Circumference of the circle

  const adjustedPercentage = percentage * .82;
  // Calculate the dash offset based on the percentage
  const dashOffset = circumference - (adjustedPercentage / 100) * circumference;

  return (
    <svg width={radius * 2} height={radius * 2} className='ms-2'>
      <circle
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        fill="none"
        stroke="#ddd" // Background color of the progress bar
        strokeWidth={strokeWidth}
      />
      <circle
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        fill="none"
        stroke={color ? color : '#43A66A'} // Progress bar color
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={dashOffset}
        transform={`rotate(-90 ${radius} ${radius})`}
        strokeLinecap="round"
        className='circle-progress'
      />
      <text
        x={radius}
        y={radius}
        textAnchor="middle"
        dominantBaseline="middle"
        fill="#333" // Text color
        fontSize="12px" // Text size
      >
        {`${Math.round(percentage)}`}
      </text>
    </svg>
  );
};

export default CircularProgress;
