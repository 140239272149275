import React, { useState, useContext } from 'react';
import { userToken, appAbsPath, createGa4Event, formatDateReadable } from '../../Admin/Utils';
import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
import html2canvas from 'html2canvas';
import bwLogo from '../../../Assets/img/beyondwalls-logoirfs.svg';
import cStar from '../../../Assets/img/cstar.svg';
import Celleft from '../../../Assets/img/celleft.png';
import IrfsLogo from '../../../Assets/img/irfscuponlogo.png';
import Celright from '../../../Assets/img/celright.png';
import IrfsLogobg from '../../../Assets/img/irfslogobg.png';
import Sirfs from '../../../Assets/img/sharmanirfs.png';

const CouponTag = ({ couponCode, offerName, projectName, projectSlug, userName, userPhone, offerExpiresAt, userTokenCheckDisable = false }) => {
    const {  setDownloadCouponBtn } = useContext(SearchVisibilityContext);
    const [ setThankYou] = useState(false); // Thank You step state


    const currentDateForMonth = new Date();
    const currentDateForDays = new Date();
    const expiryMonth3 = new Date(currentDateForMonth.setMonth(currentDateForMonth.getMonth() + 3));
    const expiryDay10 = new Date(currentDateForDays.setDate(currentDateForDays.getDate() + 10));

    // const offerExpiryDate = formatDateReadable(offerExpiresAt);
    const offerExpiryMonth3 = formatDateReadable(expiryMonth3);
    const offerExpiryDay10 = formatDateReadable(expiryDay10);

    const downloadCouponpdf = () => {
        const overlayContent = document.querySelector('.overlay-content');
        const overlayContentCreateUser = document.querySelector('.overlay-content-createUser');
        const input = document.querySelector('.coupon-wrapper');
        const excludeDiv = input ? input.querySelector('.exclude-this') : null;

        // Check if the input and necessary div elements are present
        if (!input) {
            console.error('Coupon wrapper not found');
            return;
        }

        if (!overlayContent) {
            console.error('Overlay content not found');
            return;
        }

        const originalTransform = overlayContent ? overlayContent.style.transform : null;
        if (overlayContent) {
            overlayContent.style.transform = 'translate(-50%, -50%) scale(1)';
        }

        const originalTransformCreateUser = overlayContentCreateUser ? overlayContentCreateUser.style.transform : null;
        if (overlayContentCreateUser) {
            overlayContentCreateUser.style.transform = 'translate(-50%, -50%) scale(1)';
        }

        if (excludeDiv) {
            excludeDiv.style.display = 'none';
        }

        html2canvas(input, { useCORS: false, scale: 2, logging: false, allowTaint: false })
            .then((canvas) => {
                if (overlayContent) {
                    overlayContent.style.transform = originalTransform;
                }
                if (overlayContentCreateUser) {
                    overlayContentCreateUser.style.transform = originalTransformCreateUser;
                }

                // Restore the excluded div's visibility
                if (excludeDiv) {
                    excludeDiv.style.display = '';
                }

                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png');
                link.download = 'coupon.png';
                link.click();
                setDownloadCouponBtn(false);
                setThankYou(false);

                if (!userTokenCheckDisable && !userToken) {
                    setTimeout(() => {
                        window.location.href = `${appAbsPath + window.location.href}`;
                    }, 3000);
                }
            })
            .catch((error) => {
                console.error("Could not download the coupon:", error);
                // Restore the excluded div's visibility in case of error
                if (excludeDiv) {
                    excludeDiv.style.display = '';
                }
            });
    };

    return (
        <div className="coupon-wrapper">
            <div className="thank-you-step">
                <div className="cuponwrap">
                    <img src={bwLogo} alt="Beyond Walls Logo" className="bwlogo" style={{ width: "140px", margin: "0 auto", display: "block", paddingTop: "15px" }} />
                    <p className="tokentitle">To Avail this Offer</p>
                    <div className="cuponheader" style={{ paddingTop: "15px" }}>
                        <div className="expidiv">
                            <p className="cuponhead exphead">VISIT BEFORE</p>
                            <p className="expdate">
                                {projectSlug !== 'geras-island-of-joy-project-c' ? (
                                    <>
                                        {offerExpiryDay10}
                                    </>
                                ) : (
                                    <>
                                        30 Oct 2024
                                    </>
                                )}
                            </p>
                        </div>

                        {projectSlug !== 'geras-island-of-joy-project-c' &&
                            <div className="expidiv">
                                <p className="cuponhead exphead">BOOK BEFORE</p>
                                <p className="expdate">{offerExpiryMonth3}</p>
                            </div>
                        }
                    </div>
                    <div className="cuponbody">
                        <div className="cuponheaddiv">
                            <p className="tokenidnum"><span >ID: </span>{couponCode}</p>
                        </div>
                        <div className="offerdiv" style={{ marginTop: "10px" }}>
                            <div className="text-offerdiv">
                                <img src={cStar} alt="Star" className="cstar1" />
                                <span className="text-offer">{offerName}</span>
                                <img src={cStar} alt="Star" className="cstar1" />
                            </div>
                            <p className="cupon-proj">Project: <span>{projectName}</span></p>
                            <div className="logodiv">
                                <div className="logodivflex">
                                    <img src={Celleft} alt="Logo Left" className="celleft" />
                                    <img src={IrfsLogo} alt="IRFS Logo" className="irfs-logo" />
                                    <img src={Celright} alt="Logo Right" className="celright" />
                                </div>
                                <img src={IrfsLogobg} alt="IRFS Logo Background" className="irfslogobg" />
                            </div>
                            <div className="text-offerdiv1">
                                <img src={cStar} alt="Star" className="cstar1" />
                                <img src={cStar} alt="Star" className="cstar1" />
                            </div>
                        </div>
                        <div className="cuponfooter">
                            <div>
                                <p className="uhead">Name: <span>{userName}</span></p>
                                <p className="uhead">Ph No: <span>{userPhone}</span></p>
                            </div>
                            <img src={Sirfs} alt="IRFS Logo" className="sirfs" />
                        </div>
                    </div>
                    <div className="download-btn exclude-this">
                        <button type="button" onClick={downloadCouponpdf} className="btn sc register-btn px-4 fs-6 download-cupon-btn">
                            <span className="signin text-uppercase">Download Free Coupon</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CouponTag;
