import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {appAbsPath} from '../Utils';
import { Icon } from '@iconify/react';

class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({[menuState] : false});
    } else if(Object.keys(this.state).length === 0) {
      this.setState({[menuState] : true});
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({[i]: false});
      });
      this.setState({[menuState] : true});
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const sidebarElement = document.querySelector('#sidebar');
    if (sidebarElement) {
      sidebarElement.classList.remove('active');
    }
    Object.keys(this.state).forEach(i => {
      this.setState({[i]: false});
    });

  //   const dropdownPaths = [
  //     {path:'/apps', state: 'appsMenuOpen'},
  //     {path:'/basic-ui', state: 'basicUiMenuOpen'},
  //     {path:'/advanced-ui', state: 'advancedUiMenuOpen'},
  //     {path:'/form-elements', state: 'formElementsMenuOpen'},
  //     {path:'/tables', state: 'tablesMenuOpen'},
  //     {path:'/maps', state: 'mapsMenuOpen'},
  //     {path:'/icons', state: 'iconsMenuOpen'},
  //     {path:'/charts', state: 'chartsMenuOpen'},
  //     {path:'/user-pages', state: 'userPagesMenuOpen'},
  //     {path:'/error-pages', state: 'errorPagesMenuOpen'},
  //     {path:'/general-pages', state: 'generalPagesMenuOpen'},
  //     {path:'/ecommerce', state: 'ecommercePagesMenuOpen'},
  //   ];

  //   dropdownPaths.forEach((obj => {
  //     if (this.isPathActive(obj.path)) {
  //       this.setState({[obj.state] : true})
  //     }
  //   }));
 
  }

  render () {
    return (
      <nav className="sidebar sidebar-offcanvas pt-5" id="sidebar">
        <ul className="nav">
          <li className="nav-item active">
            <NavLink className="nav-link" to="/dashboard">
              <Icon icon="solar:home-linear" style={{width:'25px', height:'25px'}}/>
              <span className="menu-title mt-2">Home</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/portaladmin/projects">
              <Icon icon="system-uicons:list"  style={{width:'38px', height:'38px'}} />
              <span className="menu-title mt-2">All Project</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${appAbsPath}/portaladmin/verifiedprojects`}>
            <Icon icon="solar:verified-check-linear"  style={{width:'30px', height:'30px'}} />
              <span className="menu-title mt-2">Verified Projects</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${appAbsPath}/portaladmin/updatedprojects`}>
            <Icon icon="system-uicons:duplicate-alt" style={{width:'30px', height:'30px'}} />
              <span className="menu-title mt-2">Updated Projects</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${appAbsPath}/portaladmin/disabledprojects`}>
            <Icon icon="system-uicons:button-minus" style={{width:'30px', height:'30px'}} />
              <span className="menu-title mt-2">Disabled Projects</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${appAbsPath}/portaladmin/leads`}>
            <Icon icon="mdi:leads-outline" style={{width:'30px', height:'30px'}} />
              <span className="menu-title mt-2">Leads</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to={`${appAbsPath}/portaladmin/shortupdates`}>
            <Icon icon="mdi:developer-board" style={{width:'30px', height:'30px'}} />
            <span className="menu-title mt-2">Short Updates</span>
            </NavLink>
          </li>  
          <li className="nav-item">
          <NavLink className="nav-link" to={`${appAbsPath}/portaladmin/developers`}>
              <span className="menu-title">Developers</span>
              <i className="mdi mdi-home menu-icon"></i>
            </NavLink>
          </li>
          <li className="nav-item">
          <NavLink className="nav-link" to={`${appAbsPath}/portaladmin/offers`}>
              <span className="menu-title">Offers</span>
              <i className="mdi mdi-home menu-icon"></i>
            </NavLink>
          </li>
          <li className="nav-item">
          <NavLink className="nav-link" to={`${appAbsPath}/portaladmin/couponlist`}>
              <span className="menu-title">All Coupons</span>
              <i className="mdi mdi-home menu-icon"></i>
            </NavLink>
          </li>

          <li className="nav-item">
          <NavLink className="nav-link" to={`${appAbsPath}/portaladmin/add/location`}>
              <span className="menu-title">Location</span>
              <i className="mdi mdi-home menu-icon"></i>
            </NavLink>
          </li>
          <li className="nav-item">
          <NavLink className="nav-link" to={`${appAbsPath}/portaladmin/users`}>
              <span className="menu-title">Role Edit</span>
              <i className="mdi mdi-home menu-icon"></i>
            </NavLink>
          </li>
          { /*<li className="nav-item">
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title"><Trans>Settings</Trans></span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li> */}
        </ul>
      </nav>
    );
  }

  // isPathActive(path) {
  //   return this.props.location.pathname === path;
  // }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default Sidebar;
