import React from "react";
import { parsedUserData } from "../../../Admin/Utils";

// console.log('parsedUserData:', parsedUserData);

const Breadcrumbs = ({ breadcrumbs }) => {
    return (
        <section class="navigation-bar mb-5">
        <div class="container">
            <div class="row">
                <nav class="py-3 navbar-expand ">
                    <div class="">
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                        <li class="nav-item ">
                            <a class="nav-link roc-font usp" href="/profile">Profile</a>
                        </li>
                        {parsedUserData.userrole !== 'developer' &&(
                        <li class="nav-item ">
                            <a class="nav-link roc-font usp" href="/profile/my-coupons">My Coupons</a>
                        </li>
                        )}

                        {parsedUserData.userrole === 'developer' &&(
                            <li class="nav-item">
                                <a class="nav-link roc-font usp" href="/profile/user-coupons">User Coupons</a>
                            </li>
                        )}
                        </ul>
                    </div>
                    </div>
                </nav>
            </div>
        </div>
    </section>
    );

}

export default Breadcrumbs;