import React, { useEffect, useState, useContext } from 'react';
import { executeLaravelFrontAPI, s3url } from './../../Admin/Utils';
import { CompareProjectContext } from '../Layouts/CompareProjectContext';
import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
// import defaultImg from '../../../images/default-floor-plan.png';
import { Icon } from '@iconify/react';
import Slider from 'react-slick';
import Lightbox from 'react-image-lightbox';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import $ from 'jquery';


const ProjectDetailUnitFloorPlan = ({ projectSlug, projectId, developerId }) => {

    const [project, setProject] = useState({});
    const [unitPlan, setUnitPlan] = useState({});
    const [floorPlan, setFloorPlan] = useState({});
    const [selectedPlan, setSelectedPlan] = useState('unitplan');
    const { isProjectId, setProjectId } = useContext(CompareProjectContext);
    const { isDeveloperId, setDeveloperId } = useContext(CompareProjectContext);
    const { priceFormSubmitted } = useContext(SearchVisibilityContext);

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [images, setImages] = useState([]);

    const openLightbox = (index, planType) => {
            const imageUrls = planType.map(asset => `${s3url}/configuration-assets${asset.url}`);
            setImages(imageUrls);
            setPhotoIndex(index);
            setLightboxOpen(true);

             // Force repaint to prevent the loader issue
            setTimeout(() => {
                window.scrollBy(0, 1);  // Scroll slightly
                window.scrollBy(0, -1); // Scroll back
            }, 0);
    };

    useEffect(() => {
        if (projectSlug) {
            getProjectDetails();
        }
    }, [projectSlug]);

    const getProjectDetails = async () => {
        try {
            const result = await executeLaravelFrontAPI(`project/${projectSlug}`, '', 'GET');
            // console.log('result:', result.data);
            const projectDetails = result.data;
            // setProject(projectDetails);
            setUnitPlan(projectDetails.unitplan);
            setFloorPlan(projectDetails.floorplan);

        } catch (error) {
            console.error('Error fetching project details:', error);
        }
    };

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        speed: 800,
        centerMode: false,
        autoplay: false,
        autoplaySpeed: 3000,
        prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className='prev-arrow' />,
        nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className='next-arrow' />
    };

    const handleConfigurationClick = (configuration, event) => {
        setSelectedPlan(configuration);
        $('.config-item').removeClass('is--active');
        $(event.target).addClass('is--active');


    }
    const handleClickEnquiryForm = () => {
        setProjectId(projectId);
        setDeveloperId(developerId);
        document.getElementById('overlayEnquiry').style.display = 'block';
        document.getElementById('enquiry_triggered_from').value = 'Unit Plan/Floor Plan - Project Detail Page';
    }

    return (
        <div className="-detail floorplan-unitplan-wrapper">
            <div className="layout-desc bg-white">
                <div className="configurations-listing tab__list overflow-auto">
                    <div className={`text-uppercase tab__item me-2 me-md-3 config-item is--active`} onClick={(event) => handleConfigurationClick('unitplan', event)}>
                        Unit Plan
                    </div>
                    <div className={`text-uppercase tab__item me-2 me-md-3 config-item `} onClick={(event) => handleConfigurationClick('floorplan', event)}>
                        Floor Plan
                    </div>
                </div>


                {selectedPlan === 'unitplan' ? (
                    <div className="selected-carpet-details">
                        {Array.isArray(unitPlan) && unitPlan.length >= 1 ? (
                            <Slider {...settings}>
                                {unitPlan.map((asset, index) => (
                                    <div key={index} data-index-id={index} className="d-flex flex-column align-items-center">
                                        <img
                                            src={`${s3url}/configuration-assets${asset.url}`}
                                            alt={asset.name}
                                            className="img-fluid"
                                            onClick={() => openLightbox(index, unitPlan)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div className="d-flex flex-column align-items-center my-2">
                                {priceFormSubmitted ? (
                                    <p className='mt-2'>Thanks for filling the form, Our team will contact you shortly.</p>
                                ) : (
                                    <button className='btn sc text-uppercase' onClick={() => handleClickEnquiryForm()}>Request Unit Plans</button>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="selected-carpet-details">
                        {Array.isArray(floorPlan) && floorPlan.length >= 1 ? (
                            <Slider {...settings}>
                                {floorPlan.map((asset, index) => (
                                    <div key={index} data-index-id={index} className="d-flex flex-column align-items-center">
                                        <img
                                            src={`${s3url}/configuration-assets${asset.url}`}
                                            alt={asset.name}
                                            className="img-fluid"
                                            onClick={() => openLightbox(index, floorPlan)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        ) : (
                            <div className="d-flex flex-column align-items-center my-2">
                                {priceFormSubmitted ? (
                                    <p className='mt-2'>Thanks for filling the form, Our team will contact you shortly.</p>
                                ) : (
                                    <button className='btn sc text-uppercase' onClick={() => handleClickEnquiryForm()}>Request Floor Plans</button>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {lightboxOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        onCloseRequest={() => setLightboxOpen(false)}
                        onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                        onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                        toolbarButtons={[
                            <div key="index-info" className='start-0 top-0 ps-3 position-absolute'>
                                {photoIndex + 1} / {images.length}
                            </div>,
                        ]}
                    />
                )}
            </div>
        </div>
    );


}

export default ProjectDetailUnitFloorPlan;