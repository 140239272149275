import React from "react";
import { Accordion } from 'react-bootstrap';

const Faq = () => {
    return (
        <section className='py-5 faq'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <h2 className="sec-title fw-bold text-center">FAQs</h2>
                    </div>
                </div>
            </div>

            <Accordion defaultActiveKey="0" className='mainSectionAccordion'>
                <Accordion.Item eventKey={0}>
                    <Accordion.Header>
                        <div className="pdplist-background col-12">
                            <p className="fs-5 text-plp fw-bold m-0">What Is Real Estate Marketing?</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p className="fs-5 text-plp m-0">
                            Real estate marketing refers to the process of promoting and selling real estate properties through various channels such as online advertising, social media, and direct mail. The goal of real estate marketing is to generate leads and attract potential buyers or renters to the properties being marketed.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey={1}>
                    <Accordion.Header>
                        <div className="pdplist-background col-12">
                            <p className="fs-5 text-plp fw-bold m-0">What are the benefits of Real Estate Marketing?</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p className="fs-5 text-plp m-0">
                            Real estate marketing offers benefits such as increased exposure, lead generation, and brand awareness. Digital marketing for real estate in India is a powerful tool that helps to reach the right audience, generate leads, and build brand loyalty, while also being cost-effective and measurable.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey={2}>
                    <Accordion.Header>
                        <div className="pdplist-background col-12">
                            <p className="fs-5 text-plp fw-bold m-0">What are real estate marketing strategies?</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p className="fs-5 text-plp m-0">
                            Real estate marketing strategies include using social media, virtual tours, professional photography, and direct mail to promote properties. Effective real estate marketing can lead to increased visibility, more leads, and ultimately, higher sales. A successful real estate marketing and sales campaign requires a targeted approach that addresses the needs and wants of potential buyers.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey={3}>
                    <Accordion.Header>
                        <div className="pdplist-background col-12">
                            <p className="fs-5 text-plp fw-bold m-0">What is a Real Estate Marketing Plan?</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p className="fs-5 text-plp m-0">
                            A real estate marketing plan outlines strategies and tactics for promoting and selling a property, including the use of digital media, direct mail, and other real estate marketing services. It helps agents and property owners identify target audiences, create compelling messaging, and establish measurable goals to increase visibility and drive sales.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey={4}>
                    <Accordion.Header>
                        <div className="pdplist-background col-12">
                            <p className="fs-5 text-plp fw-bold m-0">How important is online real estate marketing?</p>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <p className="fs-5 text-plp m-0">
                            Online real estate marketing is crucial in today's digital age. A real estate marketing agency can help create a strong online presence through social media, email marketing, and search engine optimization. Online marketing can reach a wider audience, generate more leads, and ultimately increase sales for real estate businesses.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </section>
    );
};

export default Faq;
