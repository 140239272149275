import React, { useState } from 'react';
import Rating from 'react-rating-stars-component';

const ProjectRatingSet = ({ rating, setRating, size, edit }) => {
    return (
        <Rating
          count={5}
          size={size ? size : 40}
          activeColor="#DEA200" // Yellow color for active (filled) stars
          color="#E2E2E2" // Default color of inactive stars
          value={rating}
          a11y={edit ? true : false} // Enable accessibility
          onChange={(newRating) => setRating(newRating)}
          isHalf={true} // Allow half star ratings
          edit={edit ? true : false} // Make the rating interactive
        />
      );
};

export default ProjectRatingSet;