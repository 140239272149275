import React from 'react';
import Banner from './Banner';
import PuneLocal from './PuneLocal';

const PuneLocalities = ({}) => {
    return(<>
        <main className='plp-page pb-5 mt-5 bg-white'>
            <Banner/>
            <PuneLocal/>
        </main>
    </>);
};

export default PuneLocalities;