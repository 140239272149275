
import React, { useState,createContext } from 'react';

export const SearchVisibilityContext = createContext();

export const SearchVisibilityProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [downloadCouponBtn, setDownloadCouponBtn] = useState(false);
  const [couponData, setCouponData] = useState({});
  const [searchOptions, setSearchOptions] = useState([]);
  const [irfsProject, setIrfsProject] = useState(false);
  const [priceFormSubmitted, setPriceFormSubmitted] = useState(false);
  const [isCouponGenerated,setIsCouponGenerated] = useState(false);
  const [showGamification, setShowGamification] = useState(true);
  const [showExitIntentPopup, setShowExitIntentPopup] = useState(true);
  const [isDetailsPage, setIsDetailsPage] = useState(false);

  return (
    <SearchVisibilityContext.Provider
      value={{
        isVisible,
        setIsVisible,
        searchOptions,
        setSearchOptions,
        downloadCouponBtn,
        setDownloadCouponBtn,
        couponData,
        setCouponData,
        irfsProject,
        setIrfsProject,
        priceFormSubmitted,
        setPriceFormSubmitted,
        isCouponGenerated,
        setIsCouponGenerated,
        showGamification,
        setShowGamification,
        showExitIntentPopup,
        setShowExitIntentPopup,
        isDetailsPage,
        setIsDetailsPage
      }}
    >
      {children}
    </SearchVisibilityContext.Provider>
  );
};
