import React, { useEffect, useState } from 'react';
import CouponButtons from './CouponButtons';
import { checkImageUrlValid } from '../../Admin/Utils';
import defaultGallery from '../../../images/default-corporate-image.jpg';

const ExitIntentComponentUI = ({
    projectWithOffer,
    handleNotInterested,
    handleLoginCoupon,
    handleNotLogin,
    s3url,
    parsedUserData
}) => {
    const [imageSrc, setImageSrc] = useState(defaultGallery); // Default image initially
    const [isImageLoaded, setIsImageLoaded] = useState(false); // Track if the main image is loaded

    useEffect(() => {
        const validateImage = async () => {
            let projectImg = "";
            if (projectWithOffer?.gallery_thumbnail?.[0]?.url) {
                projectImg = projectWithOffer?.gallery_thumbnail[0]?.url;
            } else {
                projectImg = projectWithOffer?.gallery[0]?.url;
            }

            if (projectImg && projectImg !== null && projectImg !== undefined && projectImg !== "") {
                const imageUrl = `${s3url}/project-assets${projectImg}`;
                const valid = await checkImageUrlValid(imageUrl);
                if (valid) {
                    setImageSrc(imageUrl);
                }
            }
        };

        validateImage();
    }, [projectWithOffer, s3url]);

    // Handle image load to avoid showing broken image until fully loaded
    const handleImageLoad = () => {
        setIsImageLoaded(true); // Main image loaded
    };

    return (
        <div className="popup-overlay exitComp">
            <div className="popup-card">
                <span className="close" onClick={handleNotInterested}>×</span>
                <div className="propertyInfo">
                    <p className="developerName">By <span>{projectWithOffer?.developer?.name}</span></p>
                    <p className="projectName">{projectWithOffer?.name}</p>
                </div>
                <div className='dp'>
                    {/* Use the default image until the actual one is loaded */}
                    <img
                        className='img-fluid projectImg'
                        src={imageSrc}
                        alt="Project"
                        onLoad={handleImageLoad}
                        style={!isImageLoaded ? { display: 'none' } : {}} // Hide image until loaded
                    />
                    {!isImageLoaded && ( // Show the default image until the main one loads
                        <img className='img-fluid projectImg' src={defaultGallery} alt="Default" />
                    )}
                    <p className='offerDesc'>{projectWithOffer?.offers?.[0]?.offer_description || 'Limited Period Offer'}</p>
                    <p className='offerName'>{projectWithOffer?.offers?.[0]?.name}</p>
                    <div className="popup-buttons">
                        <CouponButtons
                            project={projectWithOffer}
                            parsedUserData={parsedUserData}
                            handleLoginCoupon={handleLoginCoupon}
                            handleNotLogin={handleNotLogin}
                            buttonText="Claim This Offer"
                            buttonType="exitIntent"
                        />
                    </div>
                    <p className="btn-close-pop" onClick={handleNotInterested}>I don't want a discount</p>
                </div>
            </div>
        </div>
    );
};

export default ExitIntentComponentUI;
