import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { executeLaravelFrontAPI } from '../../../Admin/Utils';
import { Icon } from '@iconify/react';
import DeveloperInfoCard from './DeveloperInfoCard';

const DeveloperInfoCardSlider = () => {
  const [developers, setDevelopers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchDevelopers = async () => {
      setLoading(true);
      try {
        const result = await executeLaravelFrontAPI('developers', {
          page: 1,
          per_page: 15,
        }, 'GET');
        setDevelopers(result.data.data);
      } catch (error) {
        console.error('Error fetching developers:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDevelopers();
  }, []);

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show one full slide and half of the next
    slidesToScroll: 1,
    centerMode: true,
	centerPadding: "275px",
  autoplay: true,
    responsive: [
      {
        breakpoint: 993,
        settings: {
            centerMode: false,
            centerPadding: "0px",
            dots: false,
            arrows: true,
            prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className="prev-arrow" />,
            nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className="next-arrow" />,
        },
      }
    ],
  };

  return (
    <section className='devInfoSliderSec py-5'>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
        <h2 className="fw-bold my-3">OUR PERFORMANCE IS OUR PROOF</h2>
        <Slider className='py-3' {...sliderSettings}>
          {developers.map((developer, index) => (
            <div key={index} style={index === 0 ? styles.activeSlide : styles.inactiveSlide}>
              <DeveloperInfoCard developer={developer} />
            </div>
          ))}
        </Slider>
        </>
      )}
    </section>
  );
};

const styles = {
  sliderContainer: {
    paddingLeft: '20%',
  }
};

export default DeveloperInfoCardSlider;
