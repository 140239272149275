// MobileFilters.js
import React from 'react';
import { Icon } from '@iconify/react';
import Slider from 'rc-slider';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { executeLaravelFrontAPI, s3url, formatNumber, createGa4Event, isMobileDevice, getMinMaxCarpets, getMinMaxPrice, appAbsPath } from './../../Admin/Utils';
import { setProjectStatusFilter, setProjectConfigurationFilter, setProjectMaxBudgetFilter } from './../../Admin/UserActivatyTracker';

const MobileFilter = ({
    visible,
    handleShowSortSidebarBtn,
    //   handleShowFilterSidebarBtn,
    clearUrlParams,
    activeSort,
    getSortProjectFilter,
    handleHideSortSidebarBtn,
    hasParams,
    // createGa4Event,
    filteredByBhk,
    selectedBHK,
    showAllBhk,
    initialDisplayLimit,
    toggleBhkType,
    sortProjectsByBhkType,
    setSelectedTab,
    priceRange,
    handleSliderChange,
    handleSliderChangeComplete,
    handleSliderChangeCompleteArea,
    handleFilter,
    selectedStatus,
    toggleStatus,
    selectedPossessionStatus,
    togglePossessionStatus,
    builtUpArea,
    handleSliderChangeArea,
    handleFilterArea

}) => {
    const handleShowFilterSidebarBtn = () => {
        const rightSideDiv = document.querySelector('.filter-sidebar');
        rightSideDiv.classList.toggle('active');
    }
    const handleHideFilterSidebarBtn = () => {
        handleShowFilterSidebarBtn();
    }

    const combinedOnChange = (newRange) => {
        handleSliderChange(newRange);
    };

    const combinedOnChangeComplete = (newRange) => {
        handleSliderChangeComplete(newRange);
        setProjectMaxBudgetFilter(newRange[1]);
        createGa4Event('filter_budget', 'BudgetFilter', `${newRange[0]}-${newRange[1]}`);
    };

    const combinedOnChangeArea = (newRangeArea) => {
        handleSliderChangeArea(newRangeArea);
    };

    const combinedOnChangeCompleteArea = (newRangeArea) => {
        handleSliderChangeCompleteArea(newRangeArea);
        createGa4Event('filter_builtup', 'Built Up Filter', `${newRangeArea[0]}-${newRangeArea[1]}`);
    };

    return (
        <>
            {/* Mobile Filters */}
            <div className={`visible-ipro mobile-filter-wrap ${visible ? 'slide-up' : 'slide-down'}`}>
                <a className='filter-sidebar-sort' onClick={() => handleShowSortSidebarBtn()}><Icon icon="bx:sort" /> Sort</a>
                <a className='filter-sidebar-btn' onClick={() => handleShowFilterSidebarBtn()}><Icon icon="mage:filter" /> Filter</a>
            </div>
            <div className='visible-ipro mobile-filter-wrap-popup' id="sort-filter">
                <div className='filter-sidebar-sort-popup'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex gap-3 align-items-center'>
                            <a className='' onClick={() => handleHideSortSidebarBtn()}>
                                <svg width="19" height="16" viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg"><path d="M17.556 7.847H1M7.45 1L1 7.877l6.45 6.817" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"></path></svg>
                            </a>
                            <h4 className='mb-0'>Sort By:
                            </h4>
                        </div>
                        {hasParams && (
                            <button
                                className='btn btn-primary sc py-1 h-auto'
                                onClick={() => clearUrlParams(true)}
                            >
                                Clear All
                            </button>
                        )}
                    </div>
                    <hr />
                    <ul>
                        <li onClick={() => {
                            getSortProjectFilter("relevance");
                            handleHideSortSidebarBtn();
                            createGa4Event('On Click', 'Filters', 'Relevance')
                        }}
                            className={activeSort === "relevance" ? 'active' : ''}
                        >
                            <div className='d-flex justify-content-between'>
                                Relevance
                            </div>
                        </li>
                        <li
                            onClick={() => {
                                getSortProjectFilter("recentlyLaunched");
                                handleHideSortSidebarBtn();
                                createGa4Event('On Click', 'Filters', 'Recently Launched')
                            }}
                            className={activeSort === "recentlyLaunched" ? 'active' : ''}
                        >
                            <div className='d-flex justify-content-between'>
                                Recently Launched
                            </div>
                        </li>
                        <li
                            onClick={() => {
                                getSortProjectFilter("priceLowToHigh");
                                handleHideSortSidebarBtn();
                                createGa4Event('On Click', 'Filters', 'Low to High')
                            }}
                            className={activeSort === "priceLowToHigh" ? 'active' : ''}
                        >
                            <div className='d-flex justify-content-between'>
                                Price: Low to High
                            </div>
                        </li>
                        <li
                            onClick={() => {
                                getSortProjectFilter("priceHighToLow");
                                handleHideSortSidebarBtn();
                                createGa4Event('On Click', 'Filters', 'High to Low');
                            }}
                            className={activeSort === "priceHighToLow" ? 'active' : ''}
                        >
                            <div className='d-flex justify-content-between'>
                                Price: High to Low
                            </div>
                        </li>
                        <li
                            onClick={() => {
                                getSortProjectFilter("sqftLowToHigh");
                                handleHideSortSidebarBtn();
                                createGa4Event('On Click', 'Filters', 'Low to High');
                            }}
                            className={activeSort === "sqftLowToHigh" ? 'active' : ''}
                        >
                            <div className='d-flex justify-content-between'>
                                Sq. Ft.: Low to High
                            </div>
                        </li>
                        <li
                            onClick={() => {
                                getSortProjectFilter("sqftHighToLow");
                                handleHideSortSidebarBtn();
                                createGa4Event('On Click', 'Filters', 'Sq. Ft.: High to Low');
                            }}
                            className={activeSort === "sqftHighToLow" ? 'active' : ''}
                        >
                            <div className='d-flex justify-content-between'>
                                Sq. Ft.: High to Low
                            </div>
                        </li>
                        <li
                            onClick={() => {
                                getSortProjectFilter("possessionDate");
                                handleHideSortSidebarBtn();
                                createGa4Event('On Click', 'Filters', 'Possession Date (Recent First)');
                            }}
                            className={activeSort === "possessionDate" ? 'active' : ''}
                        >
                            <div className='d-flex justify-content-between'>
                                Possession Date (Recent First)
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='filter-sidebar visible-ipro'>
                <div className="headBtns">
                    <a className='' onClick={() => handleHideFilterSidebarBtn()}>
                        <svg width="19" height="16" viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg"><path d="M17.556 7.847H1M7.45 1L1 7.877l6.45 6.817" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"></path></svg>
                    </a>
                    <h4 className='mb-0'>Filters:</h4>
                    {hasParams && (
                        <button
                            className='btn btn-primary sc py-1 h-auto'
                            onClick={() => clearUrlParams(true)}
                        >
                            Clear All
                        </button>
                    )}
                </div>
                <hr className='mt-0' />
                <Tab.Container id="left-tabs-example" defaultActiveKey="three">
                    <Row className='align-items-stretch d-flex'>
                        <Col sm={4} className='pe-0 align-items-stretch d-flex flex-column left-tab-container'>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="three">Project Status</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='flex-column d-flex'>
                                    <Nav.Link eventKey="first">Configuration Type</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="five">Possession Status</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Budget</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="four">Carpet Area (Sq.Ft.)</Nav.Link>
                                </Nav.Item>

                            </Nav>
                        </Col>
                        <Col sm={8} className='ps-3'>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    {filteredByBhk && Array.isArray(filteredByBhk) && selectedBHK && Array.isArray(selectedBHK) && filteredByBhk.length > 0 && filteredByBhk.slice(0, showAllBhk ? filteredByBhk.length : initialDisplayLimit).map((BhkType, index) => (
                                        BhkType !== "" && (
                                            BhkType && BhkType.bedrooms && BhkType.type &&
                                            <a key={index} className={`btn btn-outline-secondary me-2 mb-2 ${selectedBHK.includes(BhkType.bedrooms + " " + BhkType.type.toUpperCase()) ? 'active' : ''}`}
                                                onClick={() => {
                                                    toggleBhkType(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                                                    sortProjectsByBhkType(selectedBHK);
                                                    setSelectedTab(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                                                    createGa4Event('filter_configuration_type', 'ConfigurationTypeFilter', BhkType.bedrooms + " " + BhkType.type.toUpperCase())
                                                }}>
                                                {BhkType.bedrooms} {BhkType.type.toUpperCase()}
                                            </a>

                                        )
                                    ))}
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Slider
                                        range
                                        min={500000}
                                        max={50000000}
                                        value={priceRange}
                                        onChange={combinedOnChange}
                                        onChangeComplete={combinedOnChangeComplete}
                                    />
                                    <p className='d-flex justify-content-between'>
                                        <span>{`₹${formatNumber(priceRange[0])}`}</span>
                                        <span className='pe-md-0 pe-3'>{`₹${formatNumber(priceRange[1])}`}+</span>
                                    </p>
                                </Tab.Pane>
                                <Tab.Pane eventKey="three">
                                    <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Project") ? 'active' : ''}`}
                                        onClick={() => {
                                            toggleStatus("New Project");
                                        }}>
                                        New Project
                                    </a>
                                    <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Under Construction") ? 'active' : ''}`}
                                        onClick={() => {
                                            toggleStatus("Under Construction");
                                        }}>
                                        Under Construction
                                    </a>
                                    <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Ready To Move") ? 'active' : ''}`}
                                        onClick={() => {
                                            toggleStatus("Ready To Move");
                                        }}>
                                        Ready To Move
                                    </a>
                                    <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Launch") ? 'active' : ''}`}
                                        onClick={() => {
                                            toggleStatus("New Launch");
                                        }}>
                                        New Launch
                                    </a>
                                    <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Nearing Possession") ? 'active' : ''}`}
                                        onClick={() => {
                                            toggleStatus("Nearing Possession");
                                        }}>
                                        Nearing Possession
                                    </a>
                                </Tab.Pane>

                                <Tab.Pane eventKey="five">
                                    <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 3 ? 'active' : ''}`}
                                        onClick={() => {
                                            togglePossessionStatus('3');
                                            createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 months')
                                        }}>
                                        In 3 months
                                    </a>
                                    <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 6 ? 'active' : ''}`}
                                        onClick={() => {
                                            togglePossessionStatus('6');
                                            createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 6 months')
                                        }}>
                                        In 6 months
                                    </a>
                                    <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 12 ? 'active' : ''}`}
                                        onClick={() => {
                                            togglePossessionStatus('12');
                                            createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 1 year')
                                        }}>
                                        In 1 year
                                    </a>
                                    <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 24 ? 'active' : ''}`}
                                        onClick={() => {
                                            togglePossessionStatus('24');
                                            createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 2 years')
                                        }}>
                                        In 2 years
                                    </a>
                                    <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 36 ? 'active' : ''}`}
                                        onClick={() => {
                                            togglePossessionStatus('36');
                                            createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 years')
                                        }}>
                                        In 3 years
                                    </a>
                                    <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 60 ? 'active' : ''}`}
                                        onClick={() => {
                                            togglePossessionStatus('60');
                                            createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 5 years')
                                        }}>
                                        In 5 years
                                    </a>
                                    <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 84 ? 'active' : ''}`}
                                        onClick={() => {
                                            togglePossessionStatus('84');
                                            createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 7 years')
                                        }}>
                                        In 7 years
                                    </a>
                                </Tab.Pane>
                                <Tab.Pane eventKey="four">
                                    <Slider
                                        range
                                        min={100}
                                        max={5000}
                                        value={builtUpArea}
                                        onChange={combinedOnChangeArea}
                                        onChangeComplete={combinedOnChangeCompleteArea}
                                    />
                                    <p className='d-flex justify-content-between'>
                                        <span>{`${builtUpArea[0]} Sq.Ft.`}</span>
                                        <span className='pe-md-0 pe-3'>{`${builtUpArea[1]} Sq.Ft.`} {builtUpArea[1] === 5000 && '+'}</span>
                                    </p>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                <button className="btn btn-primary w-100 theme-btn filter-apply-btn" onClick={() => handleHideFilterSidebarBtn()}>Apply</button>
            </div>
        </>
    );
};

export default MobileFilter;
