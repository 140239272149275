import React, { useEffect, useState, useRef, useContext, Suspense, useMemo } from 'react';
import { appAbsPath, executeLaravelFrontAPI, createGa4Event, lazyLoadImages } from './../Admin/Utils';
import Header from './Layouts/Header';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
// import { useSearchVisibility } from './Layouts/SearchVisibilityContext';
import { SearchVisibilityContext } from './Layouts/SearchVisibilityContext';
import { Icon } from '@iconify/react';
import debounce from 'lodash.debounce';

// import WishListBtn from './Pages/WishListBtn';
import BlogIcon from '../../Assets/img/blogicon.png';
import BlogBook from '../../Assets/img/book.png';
import BlogRobot from '../../Assets/img/blog-robot.png';
import BlogHouse from '../../Assets/img/house.png';
import BlogShaniwar from '../../Assets/img/blog-shaniwar.png';
import Checklist from '../../Assets/img/checklist.png';
import Affordable from '../../Assets/img/affordble.png';
import Fire from '../../Assets/img/fire.png';
import Flowers from '../../Assets/img/flowers.png';
import Sky1 from '../../Assets/img/sky1.png';
import Sky2 from '../../Assets/img/sky2.png';
import Stars from '../../Assets/img/stars.png';
import Box from '../../Assets/img/box.png';
import Hot from '../../Assets/img/hot.png';
import BannerBuild from '../../Assets/img/banner-build.webp';
// import ReraImg from '../../Assets/img/rera.svg';
import ArrowD from '../../Assets/img/arrow.svg';
import Comparesvg from '../../Assets/img/compare.svg';
import IrfsHome from '../../Assets/img/irfshome.png';
import Sharmanirfshome from '../../Assets/img/sharmanirfshome.png';

//Home Content Box Import
import ContentBox from './Pages/Home/HomeContentBox';
// import TopProjectSlider from './Layouts/TopProjectSlider';


import BwLogo from '../../Assets/img/bwwhite.png';
// import Shaniwaar from '../../Assets/img/shaniwarwada-home.png';
import Blueright from '../../Assets/img/blue-right.png';
import Ceoimg from '../../Assets/img/ceoimg.png';
import Buildabstract from '../../Assets/img/about-building.svg';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const Loader = React.lazy(() => import('../Loader'));
// import { Button } from 'react-bootstrap';
const ExploreProjectsLocalities = React.lazy(() => import('./Pages/Home/ExploreProjectsLocalities'));
const SearchInput = React.lazy(() => import('./Layouts/SearchInput'));
const SearchPopup = React.lazy(() => import('./Layouts/SearchPopup'));
// const ProjectCard = React.lazy(() => import('./Layouts/ProjectCard'));
const TopProjectSlider = React.lazy(() => import('./Layouts/TopProjectSlider'));




// import ExploreProjectsLocalities from './Pages/Home/ExploreProjectsLocalities';
// import {setProjectSearchLocation,setProjectSearchDeveloper, setProjectSearchConfiguration} from './../Admin/UserActivatyTracker';

const Home = ({ isWishlistActive }) => {
    const pageDetails = { 'pagetitle': 'Beyondwalls', 'pageslug': '/' };
    const [showResult, showSearchResult] = useState(false);
    const [blogsData, setblogsData] = useState([]);
    const sectionRef = useRef(null);
    const [showDefaultResult, showDefaultSearchResult] = useState(false);
    const [isLazyLoaded, setIsLazyLoaded] = useState(false);
    const location = useLocation();
    const isLandingPage = location.pathname === '/';
    const currentUrl = location.pathname;
    const [projects, setResponseData] = useState(() => {
        const cachedProjects = localStorage.getItem('projects');
        return cachedProjects ? JSON.parse(cachedProjects) : [];
    });

    // let blogimg1  = BlogIcon;
    // let blogimg2  = BlogBook;
    // let blogimg3  = BlogGirl;
    // let blogimg4  = BlogRobot;
    // let blogimg5  = BlogHouse;
    // let blogimg6  = BlogShaniwar;

    const blogImages = [BlogIcon, BlogBook, BlogRobot]; // Add more image URLs as needed
    const blogImages2 = [BlogHouse, BlogShaniwar, Checklist]; // Add more image URLs as needed
    const [showLink, setShowLink] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            lazyLoadImages();
            setShowLink(true);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    // const [currCity, setCurrCity] = useState(sessionStorage.getItem('city') || 'pune');

    useEffect(() => {
        axios.get('https://corporate.beyondwalls.com/?portalblogs=1')
            .then((response) => setblogsData(response.data.data))
            .catch((error) => console.error('Error fetching data:', error));
    }, []);

    const scrollToSection = () => {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        return () => {
            if (location.pathname === '/' || location.pathname.includes('/project/')) {
                localStorage.removeItem('selectedValues');
            }
        };
    }, [location.pathname]);

    // Search filter
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const uniqueLocalitiesRegion = new Set();
    const uniqueLocalitiesArea = new Set();

    // Out side click hide div
    const searchInputRef = useRef(null);
    const suggestionContainerRef = useRef(null);

    const handleOutsideClick = (e) => {
        if (
            suggestionContainerRef.current &&
            !suggestionContainerRef.current.contains(e.target) &&
            searchInputRef.current &&
            !searchInputRef.current.contains(e.target)
        ) {
            showSearchResult(false);
            // console.log("Outside click");
        }
    };



    useEffect(() => {
        getProjects();
        document.addEventListener('mousedown', handleOutsideClick);
        // const storedCity = sessionStorage.getItem('city');
        // if (storedCity) {
        // setCurrCity(storedCity);
        // }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []); // Run the effect only once during component mount
    //End Out side click hide div

    //City change
    const handleCityChange = (e) => {
        // const selectedCity = e.target.value;
        // setCurrCity(selectedCity);
        // sessionStorage.setItem('city', selectedCity);

    };
    const handleFocus = (e) => {
        showSearchResult(true);
        // setDivVisibility(true);
        if (e.target.value === '' || !searchQuery || searchQuery.trim() === '') {
            showDefaultSearchResult(true);
        } else {
            showDefaultSearchResult(false);
        }
    };
    // Create a mapping of localities to projects
    const localityProjectsMap = {};
    searchResults.forEach(project => {
        uniqueLocalitiesRegion.add(project.region); // Store lowercase to ensure case-insensitivity
        uniqueLocalitiesArea.add(project.locality_name); // Store lowercase to ensure case-insensitivity
    });

    useEffect(() => {
        const lazyLoaded = sessionStorage.getItem('isLazyLoaded');
        if (lazyLoaded) {
          setIsLazyLoaded(true);
        }
    
        const handleScroll = () => {
          if (!isLazyLoaded) {
            // Your lazy loading code here
            // For example:
            document.querySelectorAll('img[data-src]').forEach(img => {
              img.src = img.getAttribute('data-src');
            });
            
            setIsLazyLoaded(true);
            sessionStorage.setItem('isLazyLoaded', 'true');
          }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [window.location.pathname]);
      
    const settings = useMemo(() => ({
        dots: false,
        arrows: true,
        infinite: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
        centerMode: false,
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className='prev-arrow' />,
        nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className='next-arrow' />,
        responsive: [
            { breakpoint: 1024, settings: { arrows: true, slidesToShow: 3, slidesToScroll: 1 } },
            { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 1 } },
            { breakpoint: 480, settings: { variableWidth: false, slidesToShow: 1, slidesToScroll: 1 } }
        ]
    }), []);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 992);
        };

        // Add event listener to track window resize
        window.addEventListener('resize', handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getProjects = async () => {
        if (projects.length > 0) return; // If projects are already fetched, skip the API call
    
        const paramsData = { is_mandate: true, per_page: 15 };
        try {
            const result = await executeLaravelFrontAPI('projects', paramsData, 'GET');
            const projectData = result.data.data;
            setResponseData(projectData);
            localStorage.setItem('projects', JSON.stringify(projectData)); // Cache data
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    useEffect(() => {
        getProjects();
    }, []);
    const { setIsVisible } = useContext(SearchVisibilityContext);


    const [isOpen, setIsOpen] = useState(false);
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setIsOpen(false);
    };


    useEffect(() => {
        const onScroll = debounce(() => {
            if (currentUrl === '/') {
                const banner = document.getElementById('home-banner');
                const bannerBottom = banner.getBoundingClientRect().bottom;
                const bannerHeight = banner.offsetHeight;
                const screenWidth = window.innerWidth;

                if (screenWidth < 992) {
                    setIsVisible(bannerBottom < (bannerHeight / 2 + 80));
                } else {
                    setIsVisible(bannerBottom < 0);
                }
            }
        }, 200);

        window.addEventListener('scroll', onScroll);
        return () => window.removeEventListener('scroll', onScroll);
    }, [setIsVisible, currentUrl]);



    return (
        <>
            <Helmet>
                <title>BeyondWalls: Buy Properties in India, Real Estate, Property Site India</title>
                <meta name="description" content="Browse residential properties for sale in India at BeyondWalls - New Projects, Ready to Move in Flats/Apartments. Choose your dream home from our 100% Verified Listings." />
                <link rel="canonical" href="https://www.beyondwalls.com" />
                <meta property="og:url" content="https://www.beyondwalls.com/" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="BeyondWalls: Buy Properties in India, Real Estate, Property Site India" />
                <meta property="og:description" content="BeyondWalls- Browse residential properties for sale in India- New Projects, Ready to Move in Flats/Apartments. 100% Verified Listings" />
                <meta property="og:image" content="https://portal.beyondwalls.com/static/media/logo.fe506d7579e2f8d20d3fcb8856ca8b0c.svg"></meta>

                <meta name="twitter:card" content="summary_large_image"></meta>
                <meta property="twitter:domain" content="beyondwalls.com"></meta>
                <meta property="twitter:url" content="https://www.beyondwalls.com/"></meta>
                <meta name="twitter:title" content="BeyondWalls: Buy Properties in India, Real Estate, Property Site India"></meta>
                <meta name="twitter:description" content="BeyondWalls- Browse residential properties for sale in India- New Projects, Ready to Move in Flats/Apartments. 100% Verified Listings"></meta>
                <meta name="twitter:image" content="https://portal.beyondwalls.com/static/media/logo.fe506d7579e2f8d20d3fcb8856ca8b0c.svg"></meta>

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Corporation",
                        "name": "BeyondWalls",
                        "legalName": "BeyondWalls",
                        "url": "https://www.beyondwalls.com/",
                        "logo": "https://portal.beyondwalls.com/static/media/logo.fe506d7579e2f8d20d3fcb8856ca8b0c.svg",
                        "foundingDate": "2021",
                        "founders": [
                            {
                                "@type": "Person",
                                "name": " Mr. Vikram Kotnis"
                            }
                        ],
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "4th floor, Sr.No:9, H.NO:1/2, near hotel Ramada Plaza, Above Maruti Suzuki sumankirti cars Pvt Ltd. Mahalunge",
                            "addressLocality": "Pune",
                            "addressRegion": "Maharashtra ",
                            "postalCode": " 411045",
                            "addressCountry": "India"

                        },
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "contactType": "customer support",
                            "email": "contact.us@beyondwalls.com"
                        },
                        "sameAs": [
                            "https://www.facebook.com/beyondwallsofficial",
                            "https://goo.gl/maps/dYUNLxxbBhzwYoqS8",
                            "https://twitter.com/BeyondWallsCo",
                            "https://www.instagram.com/beyondwallsofficial/",
                            "https://www.youtube.com/channel/UCRudeqskEYlCpTmo7X8HttQ",
                            "https://www.linkedin.com/company/beyondwallsofficial/?viewAsMember=true"
                        ]
                    })}
                </script>

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "WebPage",
                        "name": "BeyondWalls: Buy Properties in India, Real Estate, Property Site India",
                        "speakable":
                        {
                            "@type": "SpeakableSpecification",
                            "xPath": [
                                "/html/head/title",
                                "/html/head/meta[@name='description']/@content"
                            ]
                        },
                        "url": "https://www.beyondwalls.com/"
                    })}
                </script>


                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "WebSite",
                        "name": "BeyondWalls: Buy Properties in India, Real Estate, Property Site India",
                        "url": "https://www.beyondwalls.com/",
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://www.beyondwalls.com/search?q={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    })}
                </script>


                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "BeyondWalls: Buy Properties in India, Real Estate, Property Site India",
                        "image": "https://portal.beyondwalls.com/static/media/logo.fe506d7579e2f8d20d3fcb8856ca8b0c.svg",
                        "url": "https://www.beyondwalls.com/",
                        "telephone": "020 67082729",
                        "priceRange": "NA",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "4th floor, Sr.No:9, H.NO:1/2, near hotel Ramada Plaza, Above Maruti Suzuki sumankirti cars Pvt Ltd. Mahalunge",
                            "addressLocality": "Pune",
                            "postalCode": "411045",
                            "addressCountry": "IN"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": 18.58272232153281,
                            "longitude": 73.75996146930895
                        },
                        "openingHoursSpecification": {
                            "@type": "OpeningHoursSpecification",
                            "dayOfWeek": [
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday"
                            ],
                            "opens": "10:00",
                            "closes": "07:00"
                        },
                        "sameAs": [
                            "https://www.facebook.com/beyondwallsofficial",
                            "https://twitter.com/BeyondWallsCo",
                            "https://www.instagram.com/beyondwallsofficial/",
                            "https://www.youtube.com/channel/UCRudeqskEYlCpTmo7X8HttQ",
                            "https://www.linkedin.com/company/beyondwallsofficial/?viewAsMember=true",
                            "https://goo.gl/maps/dYUNLxxbBhzwYoqS8"
                        ]
                    })}
                </script>


                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "http://schema.org/",
                        "@type": "Place",
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "18.58272232153281",
                            "longitude": "73.75996146930895"
                        }
                    })}
                </script>



                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "SearchResultsPage",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.beyondwalls.com/"
                        },
                        "headline": "BeyondWalls: Buy Properties in India, Real Estate, Property Site India",
                        "description": "BeyondWalls- Browse residential properties for sale in India- New Projects, Ready to Move in Flats/Apartments. 100% Verified Listings",
                        "image": "https://www.beyondwalls.com/wp-content/themes/beyondwallsconsumer/images/proptech-bg.png",
                        "author": {
                            "@type": "Organization",
                            "name": "BeyondWalls: Buy Properties in India, Real Estate, Property Site India",
                            "url": "https://www.beyondwalls.com/"
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": "BeyondWalls: Buy Properties in India, Real Estate, Property Site India",
                            "logo": {
                                "@type": "ImageObject",
                                "url": "https://portal.beyondwalls.com/static/media/logo.fe506d7579e2f8d20d3fcb8856ca8b0c.svg"
                            }
                        }
                    })}
                </script>
            </Helmet>
            <Header />
            <section id='home-banner' className="position-relative bg-white hero-section text-center d-flex flex-column justify-content-center align-items-center">
                <div className="container hero-main">
                    <div className='hero-cont'>
                        <div className='row h100'>
                            <div className="hero-main-1 col-xl-6 col-12">
                                <div className="hero-heading mt-0 pt-0 pt-md-1">
                                    <div className='bwlogo'>
                                        <img src={BwLogo} loading='lazy' alt="hero-image" className="img-fluid d-block" />
                                    </div>
                                    <h1 className="d-flex flex-column align-items-lg-start align-items-center urbanist-font text-center text-lg-start">
                                        Real Estate <br />Simplified
                                    </h1>
                                </div>
                                <div className="hero-searchmain position-relative col-lg-8 col-12 align-self-center" ref={searchInputRef}>
                                    <div className="text-start mt-3 mt-lg-5 position-relative">
                                        <div className="search-component">
                                            {isMobile ? (
                                                <Suspense fallback={<Loader />}>
                                                    <SearchPopup buttonShow={true} iconShow={false} />
                                                </Suspense>
                                            ) : (
                                                <Suspense fallback={<Loader />}>
                                                    <SearchInput />
                                                </Suspense>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-6 col-12'>
                                <div className="hero-main-2 mt-2 mt-lg-3 mt-md-0 pb-0">
                                    {/*<a href={`${appAbsPath}/projects/map-view`} onClick={()=>{createGa4Event("hp_map_view", 'Map View', `Map View` )}}><img src={homeBanner} alt="hero-image" className="img-fluid col-9" /></a>
                                    <a href={`${appAbsPath}/projects/map-view`} className='map-btn' onClick={()=>{createGa4Event("hp_map_view", 'Map View', `Map View` )}}><img src={mapBtn} alt="map-btn" /></a>*/}

                                    <img src={BannerBuild} alt="hero-image" className="img-fluid heroimg" loading="lazy" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={scrollToSection} className='arrowd d-none d-md-block'><img src={ArrowD} loading='lazy' alt="hero-image" className="img-fluid d-block" /></div>
            </section>
            <Suspense fallback={<div>Loading...</div>}>
                <TopProjectSlider projects={projects} appAbsPath={appAbsPath} settings={settings} />
            </Suspense>



            <section className='bg-white pb-0 py-4'>
                <div className='container px-lg-0'>
                    <a href="/irfs" className="irfscominganchor">
                        <div className='irfscoming'>
                            <div className='row align-items-center m-0 position-relative'>
                                <div className='col-12 col-md-6 p-0 pt-4'>
                                    <img data-src={IrfsHome} alt="map-btn" className='img-fluid irfslogomob lazy d-block d-sm-none' />
                                    <p className='irfstitle'>Pune's Biggest Real Estate Sale</p>
                                    <h2 className='soon'>IRFS is Live</h2>
                                    <a href="/irfs" className="local-project-btn d-block mb-4">View the Best Deals Now</a>
                                </div>
                                <div className='col-12 col-md-6 pb-4 py-sm-4 p-0'>
                                    <img data-src={IrfsHome} alt="map-btn" className='img-fluid irfshome lazy d-none d-sm-block' />
                                    <img data-src={Sharmanirfshome} alt="IRFS" className='img-fluid lazy sharmanirfshome' />
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </section>

            <Suspense fallback={<div>Loading...</div>}>
                <ExploreProjectsLocalities irfs={false} />
            </Suspense>

            <section className='bg-white compare-sec py-4 py-lg-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='comparesec'>
                            <div>
                                <img data-src={Comparesvg} className="img-fluid compareimg lazy" alt='Compare' />
                            </div>
                            <div>
                                <h2 className='compare-head'>Compare Your Top <br></br>Property Choices</h2>
                                <a href="/compare" className="local-project-btn d-block mb-4">Explore More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='bg-white py-4 py-lg-5'>
                <div className='container px-lg-0'>
                    <div className='row project-local'>
                        <ContentBox
                            appAbsPath={appAbsPath}
                            createGa4Event={createGa4Event}
                            Box={Box}
                            Blueright={Blueright}
                            Flowers={Flowers}
                            Stars={Stars}
                            Hot={Hot}
                            Fire={Fire}
                            Affordable={Affordable}
                            Sky1={Sky1}
                            Sky2={Sky2}
                            showPara={true}
                            showTopContent={true}
                            showForwardIcon={true}
                            allowLazyLoad={true}
                            origin={"home"}
                        />
                    </div>
                </div>
            </section>

            <section className='bg-white ceosection py-4 py-lg-5'>
                <div className='container'>
                    <div className='row ceocontainer align-items-center position-relative'>
                        <div className='col-md-7 col-12'>
                            <div className='ceobox'>
                                <h2 className="sec-title fw-bold text-md-start text-center">About Beyondwalls</h2>
                                <p className='sec-desc text-md-start text-center'>BeyondWalls is an AI-powered property search platform that offers a comprehensive property consultancy service designed to simplify homebuying for you! This is your one-stop homebuying portal that brings you the right comparison of properties, for the right locations, to help you make the right decision.</p>
                                <a href="https://corporate.beyondwalls.com/" target='_blank' className="local-project-btn d-block mb-4">Explore More</a>
                            </div>
                        </div>
                        <div className='col-md-5 col-12'>
                            <div className='ceoimgbox'>
                                <div className='ceocirc'>&nbsp;</div>
                                <img data-src={Ceoimg} alt="CEO" className='img-fluid lazy' />
                            </div>
                        </div>
                        <div className=''>
                            <img data-src={Buildabstract} alt="CEO" className='img-fluid buildabstract lazy' />
                        </div>
                    </div>
                </div>
            </section>


            <section className='bg-white py-4 py-lg-5 pt-lg-3'>
                <div className='container'>
                    <div className='row'>
                        {/* <h2 className='sec-title fw-bold text-md-start text-center'>Blogs</h2> */}
                        <div className='d-flex justify-content-between align-items-center px-2 px-sm-3 px-md-0'>
                            <h2 className='sec-title fw-bold text-md-start text-center'>Blogs</h2>
                            <div className="ceobtn">
                                <a href={`${appAbsPath}/blogs`}>View More</a>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-xl-6 col-12'>
                            <div className='blog-grid'>
                                {blogsData.slice(0, 3).map((blogsData, index) => (
                                    <div className='blog-box'>
                                        <div>
                                            <h3 className='blog-title abtitle'>{blogsData.title}</h3>
                                            <a href={`${appAbsPath}/blog/${blogsData.slug}`} target="_blank" className='blog-link'>Explore More</a>
                                        </div>
                                        <div>
                                            <div className='blogicon'>
                                                <img data-src={blogImages[index]} className='img-fluid lazy' alt='blogicon' />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-xl-6 col-12'>
                            <div className='blog-grid2'>
                                {blogsData.slice(3, 6).map((blogsData, index) => (
                                    <div className='blog-box'>
                                        <div>
                                            <h3 className='blog-title abtitle'>{blogsData.title}</h3>
                                            <a href={`${appAbsPath}/blog/${blogsData.slug}`} target="_blank" className='blog-link'>Explore More</a>
                                        </div>
                                        <div>
                                            <div className='blogicon'>
                                                <img data-src={blogImages2[index]} className='img-fluid lazy' alt='blogicon' />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default Home;
