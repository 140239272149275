import React from 'react';

const Loader = () => {
    const repeatDiv = () => {
        const array = new Array(10).fill(null);
         // Use map to render the div 10 times
        return array.map((_, index) => (
            <div key={index} className='loaderBox'>
                <div className="loaderBoxInner">
                    <div className="loaderBoxImg"></div>
                    <div className="loaderBoxInfo">
                        <div className="loaderTitle"></div>
                        <div className="loaderConfig"></div>
                        <div className="css-rw3bo2"></div>
                        <div className="css-j67rfj"></div>
                        <div className="css-1oqkpw8">
                            <div className="css-1yzekmu"></div>
                            {/* <div className="css-5amt6h"></div> */}
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    return repeatDiv();
};
export default Loader;