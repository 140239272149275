import ReactGA4 from "react-ga4";
const InitializeGoogleAnalytics = () => {
  // Initialize UA
  //   ReactGA.initialize("UA-XXXXXXXX-X");
  // Initialize GA4 - Add your measurement ID
  ReactGA4.initialize("G-N707E6NXV1");

  // console.log("GA4 INITIALIZED");
};

const TrackGoogleAnalyticsEvent = (
  category: string,
  action: string,
  label: string
) => {
  // console.log("GA event:", category, ":", action, ":", label);
  // Send UA Event
  //   ReactGA.event({
  //     category: category,
  //     action: action,
  //     label: label,
  //   });
  // Send GA4 Event

  if (category && action && label) {
    try {
      ReactGA4.event({
        category: category,
        action: action,
        label: label,
      });
    } catch (error) {
      console.error("Failed to send GA event:", error);
    }
  }
};

function CompletePurchase(phone, url) {
  console.log("GA CompletePurchase:", phone, ":", url);
  ReactGA4.event({
    category: 'Ecommerce',
    action: 'Purchase',
    label: phone,
    value: 1, // Total transaction value
    currency: 'INR', // Currency of the transaction
    transaction_id: phone, // A unique ID for the transaction
    items: {
      item_id: phone, // ID of the purchased item
      item_name: phone, // Name of the purchased item
      item_category: url, // Category of the purchased item
      price: 1, // Price of the item
      quantity: 1 // Quantity of the item
    }
  });
}

export default InitializeGoogleAnalytics;
export { InitializeGoogleAnalytics, TrackGoogleAnalyticsEvent, CompletePurchase };