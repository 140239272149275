import React, { useEffect } from 'react';
import ProjectsList from './Project/ProjectsList';

const Dashboard = () => {
    const pageDetails={'pagetitle':'Dashboard','pageslug':'dashboard'};
    useEffect(() => {
        document.title = pageDetails.pagetitle;
    });
    return (
        <>
        {/* <PageHeader pageDetails={pageDetails}/> */}
        <section className="content">
            
            <div className="row">
                <div className="col-md-12">
                    <ProjectsList />
                </div>
            </div>
            <div className="row">
                {/*<div className="col-md-6">
                    <BookingsList />
                </div>
                <div className="col-md-6">
                    <WalkinsList />
                </div>
                <div className="col-md-6">
                    <ChannelPartnersList />
                </div>
                <div className="col-md-6">
                    <LeadsList />
                </div>*/}
            </div>
        </section>
        </>
    )
}
export default Dashboard;
