import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { userToken, executeLaravelAPI } from '../Utils';

export function SelectToSearch({ type, defaultValues, onChange,typesection }) {
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        // Ensure defaultValues is an array before proceeding
       
        if (Array.isArray(defaultValues)&& defaultValues !== undefined && defaultValues?.length > 0) {
          const selectedOptions = defaultValues?.map(item => ({
            value: item.id,
            label: item.name
          }));
          setSelectedOptions(selectedOptions);
        } else {
          // If defaultValues is not an array, you might want to set selectedOptions to an empty array
          // or handle the case appropriately depending on your application's needs
          setSelectedOptions([]);
        }
      }, [defaultValues]);

    const fetchOptions = useCallback(debounce(async (inputValue, page) => {
    try {
        if (inputValue.length < 3) {
            setHasMore(false);
            return;
        }

        const params={name:inputValue,page:page};
        if(type==='offers'){
            params.offer_type=typesection;
        }
        //console.log(params);
        const resultData = await executeLaravelAPI(`${type}`,params, 'GET', userToken);
        const optionsData = resultData.data.data.map(item => ({
            value: item.id,
            label: item.name
        }));

        // Update options state with new options only if it's the first page,
        // otherwise append new options to the existing ones
        setOptions(prevOptions => {
            if (page === 1) {
                return optionsData;
            } else {
                const existingValues = new Set(prevOptions.map(opt => opt.value));
                const newOptions = optionsData.filter(opt => !existingValues.has(opt.value));
                return [...prevOptions, ...newOptions];
            }
        });

        setHasMore(resultData.data.data.length > 0);
        setCurrentPage(page);
    } catch (error) {
        console.error('Error fetching options:', error);
        setHasMore(false);
    }
}, 300), [type]);

    const handleChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions || []);
        if (typeof onChange === 'function') {
            onChange(selectedOptions || []);
        }
    };
    const loadMoreOptions = () => {
        if (hasMore) {
            fetchOptions(inputValue, currentPage + 1);
        }
    };
    //console.log('Updated selectedOptions:', selectedOptions);
    return (
        <Select
            isMulti
            // components={animatedComponents}
            options={options}
            // defaultValue={selectedOptions}
            value={selectedOptions}
            //onChange={() => {setSelectedOptions(); handleChange();}}
            onChange={handleChange}
            // onInputChange={handleInputChange}
            onInputChange={(inputValue, { action }) => {
                // console.log(inputValue);
                setInputValue(inputValue);
                if (action === 'input-change') {
                    setCurrentPage(1);
                    setHasMore(true);
                    fetchOptions(inputValue, 1);
                }
            }}
            noOptionsMessage={() => inputValue.length < 3 ? 'Please enter at least 3 characters' : 'No options'}
            onMenuScrollToBottom={loadMoreOptions}
            
        />
    );
}
