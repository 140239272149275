import React from 'react';
import { Modal, Button } from 'react-bootstrap';


const ConfirmationModal = ({ show, onConfirm, onCancel, modelBody }) => (
    <Modal show={show} onHide={onCancel}>
        <Modal.Header closeButton>
            <Modal.Title>Confirm Action</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modelBody}</Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onCancel}>
                Cancel
            </Button>
            <Button variant="primary" onClick={onConfirm}>
                Yes
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ConfirmationModal;