import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Form, Card, FloatingLabel } from 'react-bootstrap';
import { showToastMessage, executeLaravelAPI,userToken,validateName,appAbsPath,formatDateForDatepicker } from '../../Utils';
// import SubmitButton from '../../shared/SubmitButton';


const OfferEdit = ({ onCreateFormSubmit }) => {
    // const buttonClass=useRef('theme-btn btn btn-success');
    const pageDetails={'pagetitle':'Edit Offer','pageslug':'edit-offer'};
    // const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const [editOffer, setEditedItem] = useState({
        name: '',
        expires_at: '',
        offer_description:'',
        offer_disclaimer:''
    });
    const { id } = useParams();
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        document.title = pageDetails.pagetitle;
        const getOffer = async () => {
            try {
                const result = await executeLaravelAPI(`offer/edit/${id}`, '', 'GET', userToken);
                const dataOfferDetails = result.data;
                console.log(dataOfferDetails);
                setEditedItem({
                    name: dataOfferDetails.name,
                    expires_at: formatDateForDatepicker(dataOfferDetails.expires_at),
                    offer_description: dataOfferDetails.offer_description,
                    offer_disclaimer: dataOfferDetails.offer_disclaimer,
                });
                

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (id) {
            getOffer();
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let slugValue;
        const updatedState = { [name]: value };
        if (name === 'name') {
            // slugValue = generateSlug(value);
            // updatedState.slug = slugValue;
            // updatedState.canonical_url = "/" + slugValue;
        }
        setEditedItem((prevState) => ({
            ...prevState,
            ...updatedState,
        }));
        
        // Validate the input
        let error = validateInput(name, value);
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };


    const validateInput = (name, value) => {
        // Your validation logic here
        let error = '';
        if (['name'].includes(name)) {
            error = validateName(value);
        }
        // Add other validations as needed...
        return error;
    };
   
    const updateOffer = async (e) => {
        e.preventDefault();
        try {
           
            // Check for errors
            let hasErrors = false;
            const errors = {};
            errors.name = validateName(editOffer.name);
            errors.expires_at = validateName(editOffer.expires_at);
            for (const key in errors) {
                if (errors[key]) {
                    hasErrors = true;
                    break;
                }
            }
            setValidationErrors(errors);
            if (hasErrors) return;


            const response = await executeLaravelAPI(`offer/update/${id}`, editOffer,'PUT',userToken);
            
            if (response.status === 200) {
                showToastMessage('Offer edited successfully', 'top-right', 'success');
            } else {
                showToastMessage('Offer edition failed', 'top-right', 'error');
            }
        } catch (error) {
            showToastMessage('Error updating offer', 'top-right', 'error');
        }
        if (onCreateFormSubmit) {
            onCreateFormSubmit(editOffer);
        }
    };

    return (
        <form onSubmit={updateOffer} className='offerForm' encType="multipart/form-data">
            <div className="row">
                <div className="col-md-12 mb-2">
                    <Card border="" style={{ width: '100%' }}>
                        <Card.Header>Edit Offer "{editOffer.name}"</Card.Header>
                        <Card.Body>
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Name">
                                                    <Form.Control placeholder="Name" type="text" name='name' value={editOffer.name || ''} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.name}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.name}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Expires At">
                                                    <Form.Control placeholder="Expires At" type="date" name='expires_at' value={editOffer.expires_at || ''} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.expires_at}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.expires_at}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                               
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <hr/>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Offer Description">
                                                    <Form.Control placeholder="Offer Description" type="text" name='offer_description' value={editOffer.offer_description || ''} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Offer Disclaimer">
                                                    <Form.Control placeholder="Offer Disclaimer" type="text" name='offer_disclaimer' value={editOffer.offer_disclaimer || ''} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>

                        </Card.Body>
                        <Card.Footer className="text-end text-muted">
                            <Link to={`${appAbsPath}/portaladmin/offers`} variant="secondary" className="theme-btn secondary me-2">Cancel</Link>
                            <Button variant="success" type="submit" className="theme-btn">Save</Button>
                        </Card.Footer>
                    </Card>
                </div>
            </div>
        </form>
    );
};

export default OfferEdit;
