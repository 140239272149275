import { useEffect, useRef } from 'react';
import { Carousel } from 'react-bootstrap';
import Discount from '../../../Assets/img/discount.svg';
const OfferTag = ({ project }) => {
    const observerRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null, // relative to the viewport
            rootMargin: '0px',
            threshold: 0.5 // Trigger when 50% of the element is visible
        };

        // Create an IntersectionObserver
        observerRef.current = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                const target = entry.target;
                // Check if the element is intersecting and hasn't spun yet
                if (entry.isIntersecting && !target.classList.contains('spin-triggered')) {
                    target.classList.add('spin');
                    target.classList.add('spin-triggered'); // Mark as spun
                }
            });
        }, options);

        // Observe all elements with the class 'discimg'
        const images = document.querySelectorAll('.discimg');
        images.forEach(image => {
            observerRef.current.observe(image);
        });

        // Cleanup on unmount
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, []);

    return (
        <>
            {Array.isArray(project.offers) && project.offers.length > 0 && (
                <div className='discdiv'>
                    <img src={Discount} className="img-fluid me-2 discimg" alt="Discount"/>
                    <Carousel data-bs-theme="light" controls={false} indicators={false}>
                        {(project.offers || []).map((offer, offersIndex) => (
                            <Carousel.Item key={offersIndex}>
                                <p className='irfsdisc d-none d-sm-block'>Offer: <span>{offer.name}</span></p>
                                <p className='irfsdisc d-block d-sm-none'>{offer.name}</p>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            )}
        </>
    );
};

export default OfferTag;
