import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Card, FloatingLabel } from 'react-bootstrap';
import { showToastMessage, executeLaravelAPI,userToken,uploadAssets,validateName,appAbsPath} from '../../Utils';
// import { SelectToSearch } from '../SelectToSearch';


const NewDeveloperAdd = ({ onCreateFormSubmit }) => {
    const [newDeveloper, setNewDeveloper] = useState({
        name: '',
        nickname: '',
        description: '',
        is_active: false,
        is_primary: false,
        rating: 0,
        total_completed_projects: 0,
        total_ongoing_projects: 0,
        city: '',
        location: '',
        address1: '',
        address2: '',
        zip: '',
        area: '',
        latitude: '',
        longitude: '',
        logo: '',
        established_time_stamp: '',
        website: '',
        years_of_experience: 0,
        sq_ft_delivered: 0,
        no_of_customers_served: 0,
        happy_customers: 0
    });
    const [developerLogo, setSelectedFile] = useState(''); 
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationsData, setSelectedLocations] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        document.title = 'Create New Developer';
    }, []);
    
    const getLocationsByCity = async (city) => {
        try {
            const result=await executeLaravelAPI(`locations/city/${city}`, '', 'GET', userToken);
            const locationData=result.data;
            //console.log(locationData)
            setSelectedLocations(locationData);            
        } catch (error) {
            console.error('Error fetching data:', error);
        } 
    };

    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewDeveloper((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        // Validate the input
        let error = '';
        if (name === 'name') {
            error = validateName(value);
        } else if (name === 'nickname') {
            error = validateName(value);
        } else if (name === 'city') {
            error = validateName(value);
        } else if (name === 'location') {
            error = validateName(value);
        } else if (name === 'address1') {
            error = validateName(value);
        } else if (name === 'address2') {
            error = validateName(value);
        } else if (name === 'zip') {
            error = validateName(value);
        } else if (name === 'area') {
            error = validateName(value);
        } else if (name === 'established_time_stamp') {
            error = validateName(value);
        }
        // Add other validations as needed...
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleCityChange = (event) => {
        const city = event.target.value;
        if (city === '') {
            return;
        }
        setSelectedCity(city);
        getLocationsByCity(city);
    };

    const handleLocationChange = (event) => {
        const location = event.target.value;
        if (location === '') {
            return;
        }
        setSelectedLocation(location);
    };
   
    const createDeveloper = async (e) => {
        e.preventDefault();
        try {
            newDeveloper.city=selectedCity;
            newDeveloper.location=selectedLocation;
            // Check for errors
            let hasErrors = false;
            const errors = {};
            errors.name = validateName(newDeveloper.name);
            errors.nickname = validateName(newDeveloper.nickname);
            if (!selectedCity || selectedCity==null) {
                errors.city = 'Please select a city.';
            }
            // Validate location selection
            if (!selectedLocation || selectedLocation==null) {
                errors.location = 'Please select a location.';
            }
            errors.address1 = validateName(newDeveloper.address1);
            errors.address2 = validateName(newDeveloper.address2);
            errors.zip = validateName(newDeveloper.zip);
            errors.area = validateName(newDeveloper.area);
            errors.established_time_stamp = validateName(newDeveloper.established_time_stamp);
            for (const key in errors) {
                if (errors[key]) {
                    hasErrors = true;
                    break;
                }
            }
            setValidationErrors(errors);
            if (hasErrors) return;

            const response = await executeLaravelAPI('add-developer', newDeveloper,'POST',userToken);
            const developerFormData = new FormData();
            if (developerLogo) {
                developerFormData.append(`logo`, developerLogo);
                await uploadAssets(`developerassets/${response.data.id}`, developerFormData,userToken);
            }
            
            if (response.status === 200) {
                showToastMessage('Developer created successfully', 'top-right', 'success');
            } else {
                showToastMessage('Developer creation failed', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error creating developer:', error);
            showToastMessage('Error creating developer', 'top-right', 'error');
        }
        if (onCreateFormSubmit) {
            onCreateFormSubmit(newDeveloper);
        }
    };

    return (
        <form onSubmit={createDeveloper} className='developerForm' encType="multipart/form-data">
            <div className="row">
                <div className="col-md-12 mb-2">
                    <Card border="" style={{ width: '100%' }}>
                        <Card.Header>Add New Developer</Card.Header>
                        <Card.Body>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Brand Name">
                                                    <Form.Control placeholder="Brand Name" type="text" name='name' value={newDeveloper['name']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.name}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.name}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <hr/>
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Developer Name">
                                                    <Form.Control placeholder="Developer Name" type="text" name='nickname' value={newDeveloper['nickname']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.nickname}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.nickname}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    {/* <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Email">
                                                    <Form.Control placeholder="Email" type="text" name='email' value={newDeveloper['email']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.email}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.email}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div> */}
                                    {/* <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Phone">
                                                    <Form.Control placeholder="Phone" type="text" name='phone' value={newDeveloper['phone']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.phone}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.phone}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div> */}
                                    <hr/>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Rating">
                                                    <Form.Control placeholder="Rating" type="number" name='rating' value={newDeveloper['rating']} onChange={handleInputChange} disabled={false} readOnly={false}/>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Total Completed Projects">
                                                    <Form.Control placeholder="Total Completed Projects" type="number" name='total_completed_projects' value={newDeveloper['total_completed_projects']} onChange={handleInputChange} disabled={false} readOnly={false}/>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Total Ongoing Projects">
                                                    <Form.Control placeholder="Total Ongoing Projects" type="text" name='total_ongoing_projects' value={newDeveloper['total_ongoing_projects']} onChange={handleInputChange} disabled={false} readOnly={false}/>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Years Of Experience">
                                                    <Form.Control placeholder="Years Of Experience" type="number" name='years_of_experience' value={newDeveloper['years_of_experience']} onChange={handleInputChange} disabled={false} readOnly={false}/>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="No of customers served">
                                                    <Form.Control placeholder="No of customers served" type="number" name='no_of_customers_served' value={newDeveloper['no_of_customers_served']} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Happy customers">
                                                    <Form.Control placeholder="Happy customers" type="number" name='happy_customers' value={newDeveloper['happy_customers']} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Sq.Ft. delivered">
                                                    <Form.Control placeholder="Sq.Ft. delivered" type="number" name='sq_ft_delivered' value={newDeveloper['sq_ft_delivered']} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <hr/>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="City">
                                                    <select name="city" id="city" className={`form-control ${validationErrors.city ? 'is-invalid' : ''}`} onChange={handleCityChange} isInvalid={!!validationErrors.city}>
                                                        <option value="">--Select City--</option>
                                                        <option value="pune">Pune</option>
                                                        <option value="mumbai">Mumbai</option>
                                                    </select>
                                                    {validationErrors.city && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {validationErrors.city}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Location">
                                                    <select name="location" id="location" className={`form-control ${validationErrors.location ? 'is-invalid' : ''}`} onChange={handleLocationChange} isInvalid={!!validationErrors.location}>
                                                        <option value="">--Select Location--</option>
                                                        { locationsData &&
                                                            
                                                            locationsData.map((locationObj, index) => (
                                                                <option key={index} value={locationObj.location}>
                                                                    {locationObj.location}
                                                                </option>
                                                            ))
                                                            
                                                        };
                                                    </select>
                                                    {validationErrors.location && (
                                                        <Form.Control.Feedback type="invalid">
                                                            {validationErrors.location}
                                                        </Form.Control.Feedback>
                                                    )}
                                                </FloatingLabel>
                                                
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Address 1">
                                                    <Form.Control placeholder="Address 1" type="text" name='address1' value={newDeveloper['address1']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.address1}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.address1}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Address 2">
                                                    <Form.Control placeholder="Address 2" type="text" name='address2' value={newDeveloper['address2']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.address2}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.address2}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Zipcode">
                                                    <Form.Control placeholder="Zipcode" type="text" name='zip' value={newDeveloper['zip']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.zip}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.zip}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Area">
                                                    <Form.Control placeholder="Area" type="text" name='area' value={newDeveloper['area']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.area}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.area}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Latitude">
                                                    <Form.Control placeholder="Latitude" type="text" name='latitude' value={newDeveloper['latitude']} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Longitude">
                                                    <Form.Control placeholder="Longitude" type="text" name='longitude' value={newDeveloper['longitude']} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <hr/>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Established Timestamp">
                                                    <Form.Control placeholder="Established Timestamp" type="date" name='established_time_stamp' value={newDeveloper['established_time_stamp']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.established_time_stamp}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.established_time_stamp}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Logo">
                                                    <Form.Control type="file" className="form-control" accept="image/*" onChange={handleFileChange} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Website">
                                                    <Form.Control placeholder="Website" type="text" name='website' value={newDeveloper['website']} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <hr/>
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Description">
                                                    <Form.Control placeholder="Description" type="text" name='description' value={newDeveloper['description']} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>

                        </Card.Body>
                        <Card.Footer className="text-end text-muted">
                            <Link to={`${appAbsPath}/portaladmin/developers`} variant="secondary" className="theme-btn secondary me-2">Cancel</Link>
                            <Button variant="success" type="submit" className="theme-btn">Save</Button>
                        </Card.Footer>
                    </Card>
                </div>
            </div>
        </form>
    );
};

export default NewDeveloperAdd;
