import React, { useState } from 'react';
import DeveloperCard from '../../Layouts/DeveloperCard';

const DeveloperInfoCard = ({ developer }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className='DeveloperInfoCard' 
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}
      style={styles.cardContainer}
    >
      {!isHovered ? (
        <DeveloperCard developer={developer} />
      ) : (
        <div style={styles.overlay}>
          <h3>{developer.name}</h3>
          <p>Ongoing Projects: {developer.total_ongoing_projects}</p>
          <p>Completed Projects: {developer.total_completed_projects}</p>
        </div>
      )}
    </div>
  );
};

const styles = {
  cardContainer: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(135deg, rgba(0,0,0,0.6), rgba(255,255,255,0.2))',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
};

export default DeveloperInfoCard;
