import React, { useEffect, useState, useRef } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import {  userToken, executeLaravelAPI } from '../../Utils';
// import { Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { Icon } from '@iconify/react';
import addNewImg from '../../../../Assets/img/add-new.svg';
import deleteBtnImg from '../../../../Assets/img/delete-btn.svg';

const ShortUpdatesList = () => {
    const location = useLocation();
    const [shortUpdates, setShortUpdates] = useState([]);
    const [search, setSearch] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    // const searchRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError('');
            const params = {
             
                page:  `${currentPage + 1}` ,
                per_page: `${perPage}`,

                
            };
            try {
                const response = await executeLaravelAPI('shortupdates', params, 'GET', userToken);
                if (response.status === 200) {
                    setShortUpdates(response.data.data);
                    setPageCount(response.data.last_page);
                    setTotal(response.data.total);
                } else {
                    setError('Something went wrong');
                }
            } catch (error) {
                setError('Something went wrong');
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [search, currentPage, perPage]);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    // const handleSearch = () => {
    //     setCurrentPage(0);
    //     setSearch(searchRef.current.value);
    // };

    const handleDelete = async (id) => {
        try {
            const isConfirmed = window.confirm('Are you sure you want to delete this item?');
            if (!isConfirmed) {
                return;
            }
            const response = await executeLaravelAPI(`shortupdate/delete/${id}`, '', 'DELETE', userToken);
            if (response.status === 200) {
                const newShortUpdates = shortUpdates.filter(shortUpdate => shortUpdate.id !== id);
                setShortUpdates(newShortUpdates);
                setTotal(total - 1);
            } else {
                setError('Something went wrong');
            }
        } catch (error) {
            setError('Something went wrong');
        }
    };

    return (
        <div className="short-updates-list">
            <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                <h2 className="mb-0">Short Updates</h2>
                <NavLink to="/portaladmin/add-short-update" className="btn">
                    <img src={addNewImg} alt="Add New" style={{ marginRight: '8px' }} />
                </NavLink>
            </div>

            
            <div className="short-updates-list-body">
                {/* <div className="short-updates-list-search mb-3">
                    <Form.Control type="text" placeholder="Search" ref={searchRef} onChange={handleSearch} className="form-control" />
                </div> */}
                <div className="short-updates-list-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Sr.No.</th>
                                <th>ID.</th>
                                <th width='80%'>Title</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="2" className="text-center">Loading...</td>
                                </tr>
                            ) : (
                               
                                shortUpdates.map((shortUpdate, index )=> (
                                    <tr key={shortUpdate.id}>
                                        <td>{index + 1}</td>
                                        <td>{shortUpdate.id}</td>
                                        <td>{shortUpdate.title}</td>
                                        <td>
                                            <NavLink to={`${location.pathname}/edit/${shortUpdate.id}`} className="btn btn-primary btn-sm me-2">
                                                <Icon icon="akar-icons:edit" />
                                            </NavLink>
                                            <a onClick={() => handleDelete(shortUpdate.id)}>
                                                <img src={deleteBtnImg} alt="Delete" />
                                            </a>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    {error && <p className="text-danger">{error}</p>}
                </div>
            </div>
            <div className="short-updates-list-pagination float-end">
                <ReactPaginate
                     nextLabel="next >"
                     onPageChange={handlePageClick}
                     pageRangeDisplayed={3}
                     marginPagesDisplayed={2}
                     pageCount={pageCount}
                     previousLabel="< previous"
                     pageClassName="page-item"
                     pageLinkClassName="page-link"
                     previousClassName="page-item"
                     previousLinkClassName="page-link"
                     nextClassName="page-item"
                     nextLinkClassName="page-link"
                     breakLabel="..."
                     breakClassName="page-item"
                     breakLinkClassName="page-link"
                     containerClassName="pagination"
                     activeClassName="active"
                />
            </div>
        </div>
    );
};

export default ShortUpdatesList;
