import React, { useEffect, useState, lazy, Suspense, useContext} from 'react';
import Banner from './Banner';
import AddCompare from './AddCompare';
import HotProperties from './HotProperties';




const Compare = ({}) => {
   
    return(<>
        <main className='plp-page pt-5 mt-5 bg-white'>
            <Banner/>
            <AddCompare/>
            <HotProperties/>
            <section className='py-5 pt-0'>
                <div className='container'>
                    <div className='row'>
                        <iframe className="ceovid" src="https://www.youtube.com/embed/YKXbxh6IidY" title="Introducing BeyondWalls Property Search Portal Powered by AI | By Vikram Kotnis | MD, BeyondWalls" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
            </section>
        </main>
        </>)
};

export default Compare;