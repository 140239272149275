import {React, useEffect} from "react";
import { Link } from "react-router-dom";
import { appAbsPath, lazyLoadImages } from "../../../Admin/Utils";
import detaultGalleryImage2 from "../../../../images/default-location-page-3.png";


const BlogListCard = ({ blog }) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            lazyLoadImages();
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    return (

        <a className='blog-card' href={`${appAbsPath}/blog/${blog.slug}`} target="_blank">
            <div className='cardimg'>
                <img src={detaultGalleryImage2} data-src={blog.image.url} alt="Blog Banner" className="img-fluid lazy" onError={(e) => { e.target.onerror = null; e.target.src = detaultGalleryImage2; }} />
            </div>
            <div className='blog-card-content'>
                <p className='blog-card-date mb-1'>{blog.blogdate}</p>
                <h2 className='blog-card-title'>{blog.title}</h2>
                <p className='blog-card-desc'>{blog.content}</p>
            </div>
        </a>

    );
}

export default BlogListCard;