import React, { useEffect, useState } from 'react';
import { showToastMessage, userToken, executeLaravelAPI } from '../../Utils';
import { Form } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import SubmitButton from '../../shared/SubmitButton';

const ProjectEditLocation = ({selectedProjectAddressId}) => {
    const [projectLocaton, setEditedItem] = useState({
        address1:'',
        address2:'',
        address_type:'',
        city:'',
        country:'India',
        latitude:'',
        locality:'',
        location:'',
        longitude:'',
        state:'MAHARASHTRA',
        zip:''
    });
    
    useEffect(() => {
        const getAddress = async () => {
            try {
                const result = await executeLaravelAPI(`address/${selectedProjectAddressId}`, '', 'GET', userToken);
                const dataAddressDetails = result.data;
                setEditedItem(dataAddressDetails);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (selectedProjectAddressId) {
            getAddress();
        }
    }, [selectedProjectAddressId]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // Check if the name attribute corresponds to a nested property
        if (name.includes('.')) {
            const parts = name.split('.');
            // Assuming only one level of nesting for simplicity
            const nestedKey = parts[0]; // e.g., 'address'
            const nestedValueKey = parts[1]; // e.g., 'latitude' or 'longitude'
            setEditedItem(prevState => ({
                ...prevState,
                [nestedKey]: {
                    ...prevState[nestedKey],
                    [nestedValueKey]: value,
                },
            }));
        } else {
            // Handle top-level properties
            setEditedItem(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const updateProjectLocation = async (e) => {
        e.preventDefault();
        try {
            //console.log(projectBasicData);return false;
            projectLocaton.country='India';
            projectLocaton.state="MAHARASHTRA";
            projectLocaton.address_type="project";
            projectLocaton.project_id="project";
            const updatedProjectLocationData = await executeLaravelAPI(`address/edit/${selectedProjectAddressId}`, projectLocaton, 'PUT', userToken);
            if (updatedProjectLocationData.status === 200) {
                showToastMessage('Location has been updated successfully', 'top-right', 'success');
            } else {
                showToastMessage('Location has not been updated.', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error logging in:', error);
        }
    }

    return (
        <form onSubmit={updateProjectLocation} className='projectForm'>
            <div className="row">
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Address 1">
                                <Form.Control placeholder="Address 1" type="text" name='address1' value={projectLocaton.address1 || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Address 2">
                                <Form.Control placeholder="Address 2" type="text" name='address2' value={projectLocaton.address2 || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                {/* <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Locality">
                                <Form.Control placeholder="Locality" type="text" name='address.locality_name' value={projectLocaton.address.locality || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div> */}
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Location">
                                <Form.Control placeholder="Location" type="text" name='location' value={projectLocaton.location || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Region">
                                <Form.Control placeholder="Region" type="text" name='region' value={projectLocaton.locality || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="City">
                                <Form.Control placeholder="City" type="text" name='city' value={projectLocaton.city || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Zipcode">
                                <Form.Control placeholder="Zipcode" type="text" name='zipcode' value={projectLocaton.zip || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Latitude">
                                <Form.Control placeholder="Latitude" type="text" name='latitude' value={projectLocaton.latitude || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                <div className="col-md-6 mb-2">
                    <Form.Group className="row">
                        <div className="col-sm-12">
                            <FloatingLabel controlId="floatingInput" label="Longitude">
                                <Form.Control placeholder="Longitude" type="text" name='longitude' value={projectLocaton.longitude || ''} onChange={handleInputChange} />
                            </FloatingLabel>
                        </div>
                    </Form.Group>
                </div>
                {/* <div className="col-md-12 mb-2">
                    <Form.Group className="row">
                        <label className="col-sm-12 col-form-label">Embed Map Tag (iframe)</label>
                        <div className="col-sm-12">
                            <textarea className="form-control" id="exampleTextarea1" rows="10"></textarea>
                        </div>
                    </Form.Group>
                </div> */}
            </div>    
            <div className='col-12'>
                <hr></hr>
            </div>
            <div className='text-end mt-3'>
                {/* <Button variant="secondary" onClick={handleCloseForm} className="theme-btn secondary me-2">Cancel</Button> */}
                <SubmitButton variant="success" type="submit" className="theme-btn" label="Update Location" />   
            </div>           
        </form>
    )
}
export default ProjectEditLocation;
