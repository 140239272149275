import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { appAbsPath, showToastMessage, executeLaravelAPI, buttonLoadingClass } from '../Utils';
import '../../../Assets/css/LoginPage.scss';
import logo from '../../../images/logo.svg';
import SubmitButton from '../shared/SubmitButton';

const Login = ({ onLogin }) => {
    const buttonClass=useRef('theme-btn btn col-12 btn-block btn-primary btn-lg font-weight-medium auth-form-btn');
    const [formData, setFormData] = useState({email: '',password: ''});
    const [errors, setErrors] = useState({email: '',password: ''});
    const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const navigate = useNavigate();
    useEffect(() => {
        document.body.classList.add('login-page');
    },[])
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({...formData,[name]: value});
        // Reset the error message when the user starts typing
        setErrors({...errors,[name]: ''});
    };
    
    const handleLoginClick = async (e) => {
         e.preventDefault();
        try {
            // Perform validation
            let valid = true;
            const newErrors = { ...errors };
            if(formData.email === '') {
                valid = false;
                newErrors.email = 'Email is required';
            }
            if(formData.password === '') {
                valid = false;
                newErrors.password = 'Password is required';
            }
            if (valid) {
                // Calling Laravel Login API
                setButtonClass(`${buttonClass.current} ${buttonLoadingClass}`);
                document.getElementsByClassName('theme-btn').disabled = true;
                const userdata=await executeLaravelAPI('login', formData, 'POST');
                if(userdata.status===200) {
                    showToastMessage('You are logged in successfully','top-right','success');
                    onLogin(userdata);
                    //navigate(`${appAbsPath}/portaladmin/dashboard`);
                } else {
                    showToastMessage('Login details are invalid!','top-right','error');
                    setButtonClass(`${buttonClass.current}`);
                    document.getElementsByClassName('theme-btn').disabled = false;
                    navigate(`${appAbsPath}/portaladmin/login`);
                }
                
            } else {
                setErrors(newErrors);
                navigate(`${appAbsPath}/portaladmin/login`);
            }
            throw new Error('Login credentials are invalid!');
        } catch (error) {
            //console.error('Error logging in:', error);
            //setErrors(error);
        } finally {
          
        }
    }

    return (
        <div>
        <div className="d-flex align-items-center auth px-0">
          <div className="row w-100 mx-0 mt-5">
            <div className="col-lg-4 mx-auto mt-5">
              <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                <div className="brand-logo mb-5">
                    <img src={logo} alt="Beyondwalls Logo"/>
                </div>
                <h4>Hello! let's get started</h4>
                <span className="font-weight-light">Sign in to continue.</span>
                <form onSubmit={handleLoginClick} className="pt-3">
                  <div className="d-flex mb-4 from-group">
                  <input type="email" name="email" value={formData.email} onChange={handleChange} className="form-control" placeholder="Email" required/>
                  </div>
                  <div className="d-flex mb-4 from-group">
                  <input type="password" name="password" value={formData.password} onChange={handleChange} className="form-control" placeholder="Password" required/>
                  </div>
                  <div className="mt-4">
                    <SubmitButton 
                      variant="success" 
                      type="submit" 
                      className={buttonClassLoader}
                      label="SIGN IN" />
                  </div>
                  {/* <div className="my-2 d-flex justify-content-between align-items-center">
                    <a href="!#" onClick={event => event.preventDefault()} className="auth-link text-black">Forgot password?</a>
                  </div> */}
                  <div className="text-center mt-4 font-weight-light">
                    Don't have an account? <Link className="text-primary">Create Account</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>  
      </div>
    );
}
export default Login;
