
import React, { useState,createContext } from 'react';

export const CompareProjectContext = createContext();

export const CompareProjectProvider = ({ children }) => {
  const [isProjectSlug, setProjectSlug] = useState([]);
  const [isProjectId, setProjectId] = useState([]);
  const [isDeveloperId, setDeveloperId] = useState([]);
  const [isProjectName, setProjectName] = useState([]);
  return (
    <CompareProjectContext.Provider value={{ isProjectSlug, setProjectSlug, isProjectId, setProjectId, isDeveloperId, setDeveloperId, isProjectName, setProjectName }}>
      {children}
    </CompareProjectContext.Provider>
  );
};