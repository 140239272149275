import React, { useEffect, useState, lazy,Suspense } from 'react';
import Loader from '../../Loader';
import {appAbsPath, executeLaravelFrontAPI, s3url, userData} from '../../Admin/Utils';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Icon } from '@iconify/react';
import ComparisonProjectDetails from './ComparisonProjectDetails';
import defaultGallery from '../../../images/default-corporate-image.jpg';
import winner1 from '../../../Assets/img/winner1.svg';
import winner2 from '../../../Assets/img/winner2.svg';
import winner3 from '../../../Assets/img/winner3.svg';
import CircularProgress from './CircularProgress';

const ComparisonLayout = ({loggeduserdetails}) => {
    const location = useLocation();
    const pageDetails={'pagetitle':'Projects','pageslug':'projects'};
   
    const getAllParams = (location) => {
        const searchParams = new URLSearchParams(location.search);
        const comparisionData = searchParams.get('projects');
        return comparisionData;
    }
    let projectslug = getAllParams(location); 
    const [projects, setProject] = useState([]);
    const [userdetails, setUserResponseData] = useState(null);
    useEffect(() => {
        if(loggeduserdetails) {
          if(Object.keys(loggeduserdetails).length===0) {
              userData().then((result) => {
                  setUserResponseData(result);
                  //getWishListProjects();
              })
          }   
      }
    }, []);
    useEffect(() => {
      const getProjects = async () => {
        try {
          const result = await executeLaravelFrontAPI('compareprojects', { projects: projectslug }, 'GET');
          const sortedProjects = result.data.slice().sort((a, b) => parseFloat(b.total_score) - parseFloat(a.total_score));
          setProject(sortedProjects.slice(0, 3));
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle error state or display an error message
        }
      };
    
      if (projectslug) { // Check if projectslug is not null or undefined
        getProjects();
      }
    }, [projectslug]); // Include projectslug in the dependency array
  
    return (
        <>
            {/* <Header onCityChange={handleCityChange}/> */}
            <React.Fragment key={pageDetails.pageslug}>
            <main className='plp-page pb-5 pt-lg-5 pt-5 mt-5'>
              <section className='pt-4'>
                    <div class="container">
                        <h3 class="roc-font fw-bold text-center comparison-title">
                        <a href={`${appAbsPath}/projects`}><Icon icon="vaadin:arrow-left" className='me-2 align-middle'  color="#000" /></a> Hi There, We analysed your <span class="roc-font fw-bold text-primary"><span id="comparePropertyCount">{projectslug.split(',').length} </span>
                                shortlisted properties</span> & here are the results:
                        </h3>
                    </div>
                </section>
                <section className="pt-4">
                    <div className="container">
                      <div className='comparison-winner mb-5'>
                        <div className='d-flex flex-column flex-md-row'>
                          <div className='comparison-winner-img'>
                            <img src={projects[0] && projects[0].gallery && projects[0].gallery[0].url !== undefined ? `${s3url}/project-assets${projects[0].gallery[0].url}` : defaultGallery} className="img-fluid" alt={projects[0] && projects[0].name} />
                            <h3 className='mb-0 mt-2 text-truncate' title={projects[0]?.name} style={{"max-width":"250px"}}>{projects[0]?.name}</h3>
                          </div>
                          <div className='flex-fill gap-3'>
                            <ul className='winner-table d-flex flex-column px-0 px-md-5'>
                            {projects.map((project, index) => (
                              <li key={index} className='row mb-3 mt-3'>
                                <div className='index col-1 d-flex justify-content-center'><span className='align-self-center'>#{index + 1}</span></div>
                                <div className='cup-img text-center col-2 d-flex justify-content-center'>
                                  {index === 0 ? <img src={winner1} className="img-fluid align-self-center" alt="Winner" /> : null}
                                  {index === 1 ? <img src={winner2} className="img-fluid align-self-center" alt="Looser" /> : null}
                                  {index === 2 ? <img src={winner3} className="img-fluid align-self-center justify-content-center" alt="Looser" /> : null}
                                </div>
                                <div className='col-6 d-flex project-info align-content-center'><img src={project.gallery && project.gallery[0].url !== undefined ? `${s3url}/project-assets${project.gallery[0].url}` : defaultGallery} className="" width="50" height="50" naptha_cursor="text" alt={project.name} />
                                <p className='align-self-center text-truncate' title={projects[0]?.name} style={{"max-width":"250px"}}>{project.name}</p></div>
                                {/* <div className='col-3 text-end bw-score d-flex align-self-center justify-content-end'>
                                  <span key={index}>BW Score <CircularProgress color={"#FFF038"} percentage={project.total_score} /></span>
                                </div> */}
                              </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div >
                      <ComparisonProjectDetails projectslug={projectslug} />                        
                </section >
            </main>
            </React.Fragment>
        </>
    )
}
export default ComparisonLayout;