import React, { useEffect, useState, useRef } from 'react';
import { showToastMessage, userToken, executeLaravelAPI, buttonLoadingClass, uploadAssets, s3url } from '../../Utils';
import SubmitButton from '../../shared/SubmitButton';
import { Icon } from '@iconify/react';

const ProjectEditAssets = ({ selectedProjectId }) => {
    const buttonClass = useRef('theme-btn btn btn-success');
    const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const [assets, setAssets] = useState([]); // Unified state for existing and new assets
    const [removedIds, setRemovedIds] = useState([]);

    useEffect(() => {
        const getProjects = async () => {
            try {
                const result = await executeLaravelAPI(`projectassets/${selectedProjectId}`, '', 'GET', userToken);
                const dataProjectDetails = result.data.map((item) => ({
                    ...item,
                    isNew: false, // Flag to distinguish between existing and new assets
                    file: null // Placeholder for new file
                }));
    
                // If there are no existing assets, initialize with a placeholder for adding a new asset
              
                if (dataProjectDetails.length === 0) {
                    dataProjectDetails.push({ id: `newasset1`, isNew: true, file: null, title: '', alt: '' });
                }
    
                setAssets(dataProjectDetails);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (selectedProjectId) {
            getProjects();
        }
    }, [selectedProjectId]);    


    const handleFileChange = (index, file) => {
        setAssets(currentAssets => currentAssets.map((asset, i) => i === index ? { ...asset, file } : asset));
    };

    const handleTitleChange = (index, title) => {
        setAssets(currentAssets => currentAssets.map((asset, i) => i === index ? { ...asset, title } : asset));
    };

    const handleAltChange = (index, alt) => {
        setAssets(currentAssets => currentAssets.map((asset, i) => i === index ? { ...asset, alt } : asset));
    };

    const handleHeroImageChange = (index,hero_image) => {
        setAssets(currentAssets => currentAssets.map((asset, i) => ({
            ...asset,
            hero_image: i === index ? 1 : 0,
        })));
    };

    const handleAddNew = (index) => {
        const trElements = document.querySelectorAll('tbody.assetstbody tr');
        setAssets(currentAssets => [
            ...currentAssets,
            { id: `newasset${(trElements.length+1)}`, isNew: true, file: null, title: '', alt: '', heroimage: 0 } // Add a new asset with unique id
        ]);
    };

    const handleRemove = (index, id) => {
        setAssets(currentAssets => currentAssets.filter((_, i) => i !== index)); 
        setRemovedIds(prevIds => [...prevIds, id]);
    };

    const updateProjectAssets = async (e) => {
        e.preventDefault(); // Prevent form submission default behavior
        try {
            document.getElementsByClassName('theme-btn').disabled = true;
            setButtonClass(`${buttonClass.current} ${buttonLoadingClass}`);
    
            const assetsFormData = new FormData();
            console.log(assets);
            assets.forEach((asset, index) => {
                //if (asset.file) {
                    if (asset.file) {
                        assetsFormData.append(`images[${index}]`, asset.file);
                    }
                    assetsFormData.append(`title[${index}]`, asset.title || 'NA');
                    assetsFormData.append(`alt[${index}]`, asset.alt || 'NA');
                    assetsFormData.append(`assetid[${index}]`, asset.id || `newasset${index}`);
                    assetsFormData.append(`heroimage[${index}]`, asset.hero_image || 0);
                //}
                console.log("============="+`heroimage[${index}]`+"=="+asset.hero_image);
            });
            assetsFormData.append('removeassetids', removedIds.join(','));
            //console.log(assetsFormData);return
            //Insert/Update in assets API call
            const updatedProjectAssetsData = await uploadAssets(`projectassets/edit/${selectedProjectId}`, assetsFormData, userToken);

            if (updatedProjectAssetsData.status === 200) {
                //Again get inserted/updated assets API call
                const result = await executeLaravelAPI(`projectassets/${selectedProjectId}`, '', 'GET', userToken);
                setAssets(result.data);

                showToastMessage('Assets are updated successfully', 'top-right', 'success');
            } else {
                showToastMessage('Assets are not updated successfully', 'top-right', 'error');
            }

            document.getElementById("removeassets").value = '';

            setButtonClass(buttonClass.current);
            document.getElementsByClassName('theme-btn').disabled = false;
        } catch (error) {
            console.error('Error updating assets:', error);
        }
    };


    return (
        <form onSubmit={updateProjectAssets} className='projectForm' encType="multipart/form-data">
            <div className="row">
                <div className="col-md-12 mb-2">
                    <input type="hidden" id="removeassets" value={removedIds || ''} redOnly />
                    <table className='table table-responsive mt-0 p-0 mb-0 table-hover table-bordered configuration-table'>
                        <thead>
                            <tr className='table-secondary'>
                                <td>No</td>
                                <td>Image</td>
                                <td>Title</td>
                                <td>Alt Tag</td>
                                <td>Hero Image</td>
                                <td>Preview</td>
                                <td><button type='button' className='btn btn-outline-success rounded-circle add-config text-success' onClick={handleAddNew}>
                                    <Icon icon="ic:sharp-add" style={{ fontSize: '24px' }} />
                                </button></td>
                            </tr>
                        </thead>
                        <tbody className='assetstbody'>
                            {assets.map((asset, index) => (
                                <tr key={asset.id || index}>
                                    <td>{index + 1}</td>
                                    <td>
                                    <input type="hidden" className="form-control" defaultValue={asset.id || ''} readOnly/>
                                    <input type="file" className="form-control" onChange={(e) => handleFileChange(index, e.target.files[0])} /></td>
                                    <td><input type="text" className="form-control" defaultValue={asset.name || ''} onChange={(e) => handleTitleChange(index, e.target.value)} /></td>
                                    <td><input type="text" className="form-control" defaultValue={asset.file_name || ''} onChange={(e) => handleAltChange(index, e.target.value)} /></td>
                                    <td align='center'>
                                        <input type="radio" 
                                            name="heroimage"
                                            value="0"
                                            checked={asset.hero_image === 1}
                                            onChange={(e) => handleHeroImageChange(index, e.target.value)}
                                        />
                                        
                                    </td>
                                    <td>{asset.url ? <img src={`${s3url}/project-assets${asset.url}`} width={100} alt={asset.alt} /> : 'No Image'}</td>
                                    <td><a href="#remove" data-id={asset.id || ''} onClick={() => handleRemove(index, asset.id)} rel="noopener noreferrer"><Icon icon='ion:close-outline' style={{ fontSize: '30px', color: '#192951' }} /></a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='text-end mt-3'>
            {/* <Button variant="secondary" className="theme-btn secondary me-2">Cancel</Button> */}
            <SubmitButton type="submit" className={buttonClassLoader} label="Update Assets" />
            </div>
        </form>
    );
};

export default ProjectEditAssets;