import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { appAbsPath, showToastMessage, userData, userToken, executeLaravelAPI, parsedUserData } from '../Utils';
import logo from '../../../images/logo.svg';
//import { deleteCookie } from 'undici-types';

const Header = ({}) => {
    // const [isToggled, setToggled] = useState(true);
    // const [isVisible, setIsVisible] = useState(false);
    //console.log('loggeduserdetails:',loggeduserdetails);
    useEffect(() => {
        document.body.classList.remove('login-page');
        document.body.classList.add('skin-blue');
        document.body.classList.add('sidebar-mini');
    },[])
    // const toggleMenu = () => {
    //     if(isToggled){
    //         document.body.classList.add('sidebar-collapse');
    //     } else {
    //         document.body.classList.remove('sidebar-collapse');
    //     }
    //     setToggled(!isToggled);
    // };

    // const toggleProfile = () => {
    //     if(isVisible){
    //         setIsVisible(false);
    //     } else {
    //         setIsVisible(true);
    //     }
    // };
    const handleLogout = async () => {
       //alert('logout');
        try {
            localStorage.removeItem('bwToken');localStorage.clear();
            Cookies.remove('userData');
            //deleteCookie('bwToken')
            const userdata=await executeLaravelAPI('logout', '', 'POST', userToken);
            if(userdata.status===200) {
                window.location.href=`${appAbsPath}`; 

            } else {
                showToastMessage('Something Went Wrong!','top-right','error');
            } 
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <NavLink to={`${appAbsPath}`} className="navbar-brand brand-logo">
            <img src={logo} alt="Beyondwalls Logo" width="150px"/>
        </NavLink>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <span className="mdi mdi-menu"></span>
          </button>
          {/* <div className="search-field d-none d-md-block">
            <form className="d-flex align-items-center h-100" action="#">
              <div className="input-group">
                <div className="input-group-prepend bg-transparent">
                  <i className="input-group-text border-0 mdi mdi-magnify"></i>
                </div>
                <input type="text" className="form-control bg-transparent border-0" placeholder="Search projects"/>
              </div>
            </form>
          </div> */}
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile">
              <Dropdown>
                <Dropdown.Toggle className="nav-link">
                  <div className="nav-profile-img">
                    <img src={require("../../../Assets/img/face1.jpg")} alt="user"/>
                    <span className="availability-status online"></span>
                  </div>
                  <div className="nav-profile-text">
                    <p className="mb-1 text-black">{parsedUserData ? parsedUserData.name : ''}</p>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown">
                  <Dropdown.Item href="!#" onClick={evt =>evt.preventDefault()}>
                    <i className="mdi mdi-cached mr-2 text-success"></i>
                    Activity Log
                  </Dropdown.Item>
                  <Dropdown.Item href={`${appAbsPath}`} onClick={handleLogout}>
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="nav-item nav-logout d-none d-lg-block">
              <a className="nav-link" href="!#" onClick={event => event.preventDefault()}>
                <i className="mdi mdi-power"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
};
export default Header;