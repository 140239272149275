import React, { useEffect, useState, useRef, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import { Icon, DivIcon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { executeLaravelFrontAPI, s3url,appAbsPath } from './../../Admin/Utils';
import _ from 'lodash';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import mappinRed from '../../../images/map-pin-red-1.svg';
import mappinGreen from '../../../images/map-pin-green.svg';
import mappinGold from '../../../images/map-pin-gold.svg';
import ProjectCard from '../Layouts/ProjectCard';
import NewFilterSidebar from './NewFilterSidebar';
import defaultGallery from '../../../images/default-corporate-image.webp';

const customIcon = new Icon({
  iconUrl: mappinGreen,
  iconSize: [25, "100%"]
});

const highlightedIcon = new Icon({
  iconUrl: mappinRed,
  iconSize: [25, "100%"]
});

const highlightedIrfsIcon = new Icon({
  iconUrl: mappinGold,
  iconSize: [25, "100%"]
});

const initialDisplayLimit = 20;
const maxZoom = 15;
const minZoom = 10;
const zoomThreshold = 13;
const borderBuffer = 20; // 2px buffer from map edges

const fetchProjectsInBounds = async (sw_lat, sw_lng, ne_lat, ne_lng, locality = null, page = 1, perPage = initialDisplayLimit, params = null) => {
  try {

    // console.log("params: ",params)
    // const params = { per_page: perPage, page };
    params.per_page = perPage;
    if (sw_lat && sw_lng && ne_lat && ne_lng) {
      params.sw_lat = sw_lat;
      params.sw_lng = sw_lng;
      params.ne_lat = ne_lat;
      params.ne_lng = ne_lng;
    }
    if (locality) {
      params.searchtype = 'locality_name';
      params.locality_name = locality;
    }

    const result = await executeLaravelFrontAPI('projects', params, 'GET');
    return result.data.data || [];
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

const TruliaMap = () => {
  const [center, setCenter] = useState([18.5204, 73.8567]);
  const [projects, setProjects] = useState([]);
  const [zoom, setZoom] = useState(10);
  const [hoveredProject, setHoveredProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const mapRef = useRef();
  const listRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  // locality = "ravet";
  const getProjectLimit = (currentZoom) => {
    console.log("currentZoom: ",currentZoom);
    return currentZoom > zoomThreshold ? -1 : initialDisplayLimit;
  };

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    const queryParams = {};
    for (const [key, value] of params.entries()) {
      queryParams[key] = value;
    }
    return queryParams;

  }

  const filterProjectsNearBorder = (projects, bounds, pixelBounds) => {
    const bufferLat = (bounds.getNorth() - bounds.getSouth()) * (borderBuffer / pixelBounds.getSize().y);
    const bufferLng = (bounds.getEast() - bounds.getWest()) * (borderBuffer / pixelBounds.getSize().x);

    return projects.filter(project => {
      const { latitude, longitude } = project.address;
      return (
        latitude > bounds.getSouth() + bufferLat &&
        latitude < bounds.getNorth() - bufferLat &&
        longitude > bounds.getWest() + bufferLng &&
        longitude < bounds.getEast() - bufferLng
      );
    });
  };

  const updateProjectsInBounds = useCallback(
    _.debounce(async () => {
      const params = getQueryParams();
      if ((params.locality_name===undefined || params.locality_name === null || params.locality_name === '') && mapRef.current) {

        const map = mapRef.current;
        const bounds = map.getBounds();
        const pixelBounds = map.getPixelBounds();
        const sw = bounds.getSouthWest();
        const ne = bounds.getNorthEast();
        const currentZoom = map.getZoom();
        const limit = getProjectLimit(currentZoom);
        console.log("limit: ",limit)
        setLoading(true);
        const newProjects = await fetchProjectsInBounds(sw.lat, sw.lng, ne.lat, ne.lng, null, 1, limit, params);
        const filteredProjects = filterProjectsNearBorder(newProjects, bounds, pixelBounds);
        setProjects(filteredProjects);
        setLoading(false);
      }
    }, 500),
    [location.search]
  );

  useEffect(() => {
    const initializeMap = async () => {
      const params = getQueryParams();
      if (params.locality_name !== undefined && params.locality_name !== '' && params.locality_name !== null ) {
        try {

          // alert(params.locality_name)
          const response = await fetch(`https://nominatim.openstreetmap.org/search?q=${params.locality_name}&format=json&limit=1`);
          const data = await response.json();
          if (data && data.length > 0) {
            const { lat, lon } = data[0];
            const newCenter = [parseFloat(lat), parseFloat(lon)];
            setCenter(newCenter);
            setZoom(14);
            setLoading(true);
            const newProjects = await fetchProjectsInBounds(null, null, null, null, params.locality_name, 1, getProjectLimit(14), params);
            setProjects(newProjects);
            setLoading(false);
            mapRef.current.setView([newCenter[0], newCenter[1]], 14);
          }
        } catch (error) {
          console.error('Error fetching coordinates:', error);
        }
      } else {
        setLoading(true);
        const initialProjects = await fetchProjectsInBounds(null, null, null, null, null, 1, initialDisplayLimit, params);
        setProjects(initialProjects);
        mapRef.current.setView([center[0], center[1]], 10);
        setLoading(false);
      }
    };
    initializeMap();
  }, [location.search]);

  const loadMoreProjects = async () => {
    const params = getQueryParams;
    if (loading || zoom > zoomThreshold) return;
    setLoading(true);
    let newProjects;
    if (params.locality_name!==undefined && params.locality_name!=='' && params.locality_name!==null) {
      newProjects = await fetchProjectsInBounds(null, null, null, null, params.locality_name, 1, projects.length + initialDisplayLimit,params);
    } else if (mapRef.current) {
      const map = mapRef.current;
      const bounds = map.getBounds();
      const pixelBounds = map.getPixelBounds();
      const sw = bounds.getSouthWest();
      const ne = bounds.getNorthEast();
      newProjects = await fetchProjectsInBounds(sw.lat, sw.lng, ne.lat, ne.lng, null, 1, projects.length + initialDisplayLimit, params);
      newProjects = filterProjectsNearBorder(newProjects, bounds, pixelBounds);
    }
    if (newProjects.length > projects.length) {
      setProjects(newProjects);
    }
    setLoading(false);
  };

  const handleProjectHover = (project) => {
    setHoveredProject(project);
  };

  const MapEventsHandler = () => {
    const map = useMapEvents({
      moveend: updateProjectsInBounds,
      zoomend: () => {
        if (!map) return;
        const currentZoom = map.getZoom();
        setZoom(currentZoom);
        if (currentZoom > maxZoom) {
          map.setZoom(maxZoom);
        } else if (currentZoom < minZoom) {
          map.setZoom(minZoom);
        }
        updateProjectsInBounds();
      }
    });
    return null;
  };

  const handleMarkerClick = (slug) => {
    // Navigate to the desired URL when the marker is clicked
    // Assuming you want to navigate to a page with the project's ID in the URL
    navigate(`/project/${slug}`);
  };

  const ProjectMarkers = () => {
    const map = useMap();

    return (
      <>
        {projects.map((project) => (
          <Marker
            key={project.id}
            position={[project.address.latitude, project.address.longitude]}
            icon={project.id === hoveredProject?.id ? (project.is_irfs ? highlightedIrfsIcon : highlightedIcon) : customIcon}
            title={`${project.name.toString()}, ${project.locality_name}`}
            zIndexOffset={project.id === hoveredProject?.id ? 100 : 0}
            // onClick={handleMarkerClick(project.slug)}
          />
        ))}
      </>
    );
  };

  const ProjectInfo = () => {
    if (!hoveredProject) return null;
    const imageUrl = hoveredProject.gallery && Array.isArray(hoveredProject.gallery) && hoveredProject.gallery.length > 0 ? encodeURI(`${s3url}/project-assets${hoveredProject?.gallery[3]?.url}`) : defaultGallery;
    const projectUrl = `${appAbsPath}/project/${hoveredProject.slug}`;
    console.log(imageUrl)
    return (
      <div className="project-info" style={{
        position: 'absolute',
        top: '10px',
        right: '10px',
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '5px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
        zIndex: 1000
      }}>
       
      <div class='inner-map-popup' id={hoveredProject.id}>
        <a href={projectUrl} class='text-decoration-none'>
          <img src={imageUrl} class='project-img' />
          <div class='leaflet-popup-content'>
            
            <a href='{appAbsPath}/project/{hoveredProject.slug}' onClick='createGa4Event("project_view", "Project View", "{hoveredProject.name}")' class='text-secondary text-capitalize text-decoration-none'>
              <h6 class="m-0">{hoveredProject.name}</h6>
            </a>
            <p class='mb-0 developer-name'>By <span>{hoveredProject.developer.name}</span>, {hoveredProject.address?.location}, {hoveredProject.address?.city}</p>
            {/* <div class="map-card-btn-wrapper">
              <a href='https://www.google.com/maps/dir/?api=1&destination={hoveredProject.address?.latitude},{hoveredProject.address?.longitude}' target="_blank" class='btn btn-primary map-card-btn'>
                <img src={direction} class='direction-img' width="30px" height="30px"/>
              </a>
            </div> */}
          </div>
        </a>
      </div>
    `
      </div>
    );
  };

  return (
    <Container fluid className="h-90 mam-project-listing-wrapper" style={{ marginTop: "90px", marginBottom: "90px" }}>
      <Row className="h-90 ">
        <Col md={6} className="p-3 overflow-hidden">
          {/* <h3 className="mb-4">Project Listings</h3> */}

          <div
            className="project-list"
            ref={listRef}
            style={{ height: 'calc(100vh - 100px)', overflowY: 'auto', overflowX: 'hidden' }}
            onScroll={(e) => {
              const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
              if (scrollHeight - scrollTop <= clientHeight + 100 && !loading && zoom <= zoomThreshold) {
                loadMoreProjects();
              }
            }}
          >
            <Row className='top-projects-slider mapView mt-0 '>
              {projects.map((project, index) => (

                <Col md={6} className="p-3 overflow-hidden">
                  <div
                    key={project.id}
                    onMouseEnter={() => handleProjectHover(project)}
                    onMouseLeave={() => setHoveredProject(null)}
                  >
                    {/* <Card.Body>
                      <Card.Title>{project.name}</Card.Title>
                      <Card.Text>{project.locality_name}</Card.Text>
                    </Card.Body> */}
                    <ProjectCard
                      key={index}
                      project={project}
                    // isWishlistActive={isWishlistActive}
                    />
                  </div>
                </Col>
              ))}
            </Row>
            {loading && <p className="text-center">Loading projects...</p>}
          </div>
        </Col>
        <Col md={6} className="p-0">
          <MapContainer
            center={center}
            zoom={zoom}
            ref={mapRef}
            style={{ height: "100vh", width: "100%" }}
            maxZoom={maxZoom}
            minZoom={minZoom}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <ProjectMarkers />
            <MapEventsHandler />
            <ProjectInfo />
          </MapContainer>
        </Col>
      </Row>
    </Container >
  );
};

export default TruliaMap;