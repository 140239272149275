import React from 'react';
import { s3url, appAbsPath } from '../../Admin/Utils'; // Import the s3url and appAbsPath functions
import { Link } from 'react-router-dom';
const DeveloperCard = ({ developer }) => {
    const { name, logo, is_active } = developer;

    return (
        // <Link to={`${appAbsPath}/irfs/projects?searchtype=developers&developer=${developer.id}`}>
        <Link>
            <div className="developer-card">
                <div className='infoWrapper'>
                    {logo && is_active ? (
                        <div className='imgWrap'>
                            <img className='developer-logo img-responsive' src={`${s3url}${developer.logo?.url}`} alt={`${developer.name} Logo`} />
                        </div>
                    ) : (
                        <div className="developer-logo-placeholder">No Logo</div>
                    )}
                    {/* <p className="developer-name text-decoration-none">{name}</p> */}
                </div>
            </div>
        </Link>
    );
};

export default DeveloperCard;
