import React, { useState } from 'react';
import Slider from 'react-slick';
import './Recognition.scss';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Direct image imports for awards and accolades
import AwardDummy3 from '../../../../Assets/img/seopage/awards/award-dummy3.jpg';
// import Awards7 from '../../../../Assets/img/seopage/awards/awards-7.svg';
// import Awards8 from '../../../../Assets/img/seopage/awards/awards-8.svg';
import Awards1 from '../../../../Assets/img/seopage/awards/awards-1.svg';
import Awards2 from '../../../../Assets/img/seopage/awards/awards-2.svg';

import Accolades1 from '../../../../Assets/img/seopage/awards/accolades-1.svg';
import Accolades2 from '../../../../Assets/img/seopage/awards/accolades-2.svg';
import Accolades3 from '../../../../Assets/img/seopage/awards/accolades-3.svg';

const Recognition = () => {
  const [activeTab, setActiveTab] = useState('awards');

  // Sample data for awards and accolades sliders
  const awardsData = [
    { id: 1, src: Awards1, alt: 'Award Image 1' },
    { id: 4, src: Awards1, alt: 'Award Image 4' },
    { id: 5, src: Awards2, alt: 'Award Image 5' },
  ];

  const accoladesData = [
    { id: 1, src: Accolades1, alt: 'Accolade Image 1' },
    { id: 2, src: Accolades2, alt: 'Accolade Image 2' },
    { id: 3, src: Accolades3, alt: 'Accolade Image 3' },
  ];

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <div id="award-next" className="pnext pull-left" />,
    prevArrow: <div id="award-prev" className="pback pull-left" />,
    responsive : [
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <section id="awards-and-accolades" className="py-5 dev-page-awards">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="sec-heading2 fw-bold">RECOGNITION</h2>
          </div>

          {/* Tabs for Awards and Accolades */}
          <div className="col-12 text-center">
            <div className="awards-tabs-wrapper faintBlue">
              <button
                onClick={() => setActiveTab('awards')}
                className={`faintBlue ${activeTab === 'awards' ? 'active-awards' : ''}`}
              >
                Awards
              </button>
              <button
                onClick={() => setActiveTab('accolades')}
                className={`faintBlue ${activeTab === 'accolades' ? 'active-accolades' : ''}`}
              >
                Accolades
              </button>
            </div>
          </div>

          {/* Slider for Awards */}
          <div className="col-12 my-3">
            {activeTab === 'awards' && (
              <div className="award-wrapper pos-rel">
                <Slider {...sliderSettings} className="award-slider text-center">
                  {awardsData.map((award) => (
                    <div key={award.id} className="award-slide">
                      <img src={award.src} alt={award.alt} className="img-responsive" />
                    </div>
                  ))}
                </Slider>
              </div>
            )}

            {/* Slider for Accolades */}
            {activeTab === 'accolades' && (
              <div className="award-wrapper pos-rel">
                <Slider {...sliderSettings} className="accolades-slider text-center">
                  {accoladesData.map((accolade) => (
                    <div key={accolade.id} className="accolade-slide">
                      <img src={accolade.src} alt={accolade.alt} className="img-responsive" />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Recognition;
