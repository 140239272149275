import React, { useEffect, useState } from 'react';
import { executeLaravelFrontAPI, parsedUserData, s3url } from '../../Admin/Utils';
import ExitIntentComponentUI from './ExitIntentComponentUI';

// Helper function to set a cookie
const setCookie = (name, value, days) => {
    let cookieString = `${name}=${value}; path=/`;

    if (days) {
        const expires = new Date(Date.now() + days * 86400000).toUTCString();
        cookieString += `; expires=${expires}`;
    }

    document.cookie = cookieString;
};

// Helper function to read a cookie
const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

// Helper function to decode the locality names from the cookie value
const decodeLocalityNames = (encodedLocalities) => {
    return encodedLocalities.split('%252C').map(decodeURIComponent);
};

const ExitIntentComponent = ({ handleLoginCoupon, handleNotLogin, specificProject = null, projectWithOffers = null }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [projectWithOffer, setProjectWithOffer] = useState(null);
    const [closeCount, setCloseCount] = useState(1);

    useEffect(() => {
        // Check if the 'notinterested' cookie is set to true
        const isNotInterested = getCookie('notinterested');
        if (isNotInterested === 'true') {
            return; // Do not show the component if the user clicked "Not Interested" before
        }

        // Check if 'userProjectSearchLocation' cookie exists
        const searchLocationCookie = getCookie('userProjectSearchLocation');
        let localityNames = null;
        if (searchLocationCookie) {
            localityNames = decodeLocalityNames(searchLocationCookie);
        }

        if (projectWithOffers && projectWithOffers.offers && projectWithOffers.offers.length > 0) {
            // Case 1: 'projectWithOffers' is provided through the parent component
            setProjectWithOffer(projectWithOffers);
        } else if (specificProject && specificProject.offers && specificProject.offers.length > 0) {
            // Case 2: We're on the details page, and the specific project has an offer
            setProjectWithOffer(specificProject);
        } else {
            // Case 3: Default logic for other pages (random project with offer)
            const getProjects = async () => {
                // Set parameters for the API call
                const paramsData = {
                    is_irfs: true,
                    per_page: 15,
                    searchtype: 'locality_name',
                    ...(localityNames && { locality_name: localityNames.join(',') }) // Add locality_name if cookie exists
                };
                // console.log('paramsData:', paramsData);

                try {
                    const result = await executeLaravelFrontAPI('projects', paramsData, 'GET');
                    const projects = result.data.data;

                    // Filter projects that have valid offers
                    const projectsWithOffers = projects.filter(
                        (project) => project.offers && project.offers.length > 0
                    );

                    // console.log('projectsWithOffers:', projectsWithOffers);

                    // Randomly select a project with an offer
                    if (projectsWithOffers.length > 0) {
                        const randomProject = projectsWithOffers[
                            Math.floor(Math.random() * projectsWithOffers.length)
                        ];
                        setProjectWithOffer(randomProject);
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            getProjects();
        }

        // Set a timeout to add mouse movement listener after 10 seconds
        const timeoutId = setTimeout(() => {
            // Function to detect mouse movement (exit intent detection)
            const handleMouseMove = (e) => {
                if (e.clientY < 10) {
                    setShowPopup(true); // Show the popup when user moves to the top
                }
            };

            document.addEventListener('mousemove', handleMouseMove);

            return () => {
                document.removeEventListener('mousemove', handleMouseMove);
            };
        }, 5000); // 5 seconds delay

        return () => clearTimeout(timeoutId);
    }, [specificProject, projectWithOffers]);

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handlePopupCount = () => {
        setCloseCount(closeCount + 1);
        if (closeCount > 2) {
            setCookie('notinterested', 'true');
        }
    };

    const handleNotInterested = () => {
        setCookie('notinterested', 'true');
        setShowPopup(false);
    };

    if (getCookie('notinterested') === 'true') {
        return null;
    }

    return (
        <div>
            {showPopup && projectWithOffer && (
                <ExitIntentComponentUI
                    projectWithOffer={projectWithOffer}
                    handleNotInterested={handleNotInterested}
                    handleLoginCoupon={handleLoginCoupon}
                    handleNotLogin={handleNotLogin}
                    s3url={s3url}
                    parsedUserData={parsedUserData}
                />
            )}
        </div>
    );
};

export default ExitIntentComponent;
