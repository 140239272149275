import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
// import CouponButtons from '../Layouts/CouponButtons';
import CouponTag from '../Layouts/CouponTag';
import { executeLaravelFrontAPI, createGa4Event , executeLaravelAPI} from './../../Admin/Utils';
import { Helmet } from 'react-helmet-async';
import { Button, Form } from 'react-bootstrap';
// import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
import searchDataJson from '../../../Assets/search-json/pune_master_search.json';

let defaultLocations = searchDataJson.locations.map((item, index) => ({
    id: `default-${index + 1}`,
    text: item.location,
    type: 'locality',
    irfs: item.is_irfs
})).filter((item) => item.irfs === true);

//   let defaultDevelopers = searchDataJson.developers.map((item, index) => ({
//     id: item.id,
//     text: item.name,
//     type: 'developer',
//     irfs: item.is_irfs
//   }));

let defaultProjects = searchDataJson.projects.map((item, index) => ({
    id: item.id,
    text: item.name,
    type: 'project',
    slug: item.slug,
    irfs: item.is_irfs
})).filter((item) => item.irfs === true);

//   let defaultConfigurations = searchDataJson.configurations.map((item, index) => ({
//     id: `configurations-${index + 1}`,
//     text: `${item.bedrooms} BHK`,
//     type: 'configuration',
//     slug: item.slug || '',
//     bhk: item.bedrooms
//   }));




const CreateJUser = () => {
    const { name, phone, slug, selldoleadid, leadgenPreferredBudget, leadgenPreferredConfiguration, leadgenPreferredLocality, leadgenPreferredProject } = useParams();
    // const { setDownloadCouponBtn, couponData, setCouponData, downloadCouponBtn, setIrfsProject } = useContext(SearchVisibilityContext);
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [formVisible, setFormVisible] = useState(true); // Controls form visibility
    const [formData, setFormData] = useState({
        name: name || '',
        phone: phone || '',
        slug: slug || '',
        selldoleadid: selldoleadid || '',
        leadgenPreferredBudget: leadgenPreferredBudget || '',
        leadgenPreferredConfiguration: leadgenPreferredConfiguration || '',
        leadgenPreferredLocality: leadgenPreferredLocality || '',
        leadgenPreferredProject: leadgenPreferredProject || ''
    });
    const [showCoupon, setShowCoupon] = useState(false);
    const [parsedUserData, setParsedUserData] = useState(null);
    const [project, setProject] = useState(null);

    const handleClose = () => {
        setOverlayVisible(false); // Hide overlay on close
    };

    const fetchUserData = async () => {
        // const result = await executeLaravelFrontAPI(`admin/usercreate/${formData.name}/${formData.phone}/${formData.slug}/${formData.selldoleadid}/${formData.leadgenPreferredBudget}/${formData.leadgenPreferredConfiguration}/${formData.leadgenPreferredLocality}/${formData.leadgenPreferredProject}`, '', 'GET');
        let jsonData = {
            name: formData.name,
            phone: formData.phone,
            slug: formData.slug,
            selldoleadid: formData.selldoleadid,
            leadgenPreferredBudget: formData.leadgenPreferredBudget,
            leadgenPreferredConfiguration: formData.leadgenPreferredConfiguration,
            leadgenPreferredLocality: formData.leadgenPreferredLocality,
            leadgenPreferredProject: formData.leadgenPreferredProject,
            downloadCoupon: true
        };
        const result = await executeLaravelAPI('usercreate', jsonData,'POST');

        if (!result.couponcode) {
            console.error("No data found for user.");
            return;
        }
        result.couponcode.user_name = formData.name;
        setParsedUserData(result.couponcode);
        setProject(result.project);
    };

    const handleChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetchUserData();

        setShowCoupon(true); // Show download coupon button
        setFormVisible(false); // Hide the form
        setOverlayVisible(true); // Automatically show the overlay
    };

    const handleRefillForm = () => {
        setFormVisible(true); // Show the form again
        setShowCoupon(false); // Hide the download coupon button
        setOverlayVisible(false); // Hide the overlay
        setFormData({
            name: '',
            phone: '',
            slug: '',
            selldoleadid: '',
            leadgenPreferredBudget: '',
            leadgenPreferredConfiguration: '',
            leadgenPreferredLocality: '',
            leadgenPreferredProject: ''
        });
    };

    return (
        <>
            <div className="main container mt-5 pt-5 pb-5">
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10">
                        {formVisible ? (
                            <Form onSubmit={handleSubmit} className="p-4 bg-light shadow rounded">
                                <h2 className="text-center mb-4">User Information Form</h2>

                                {/* Form Fields */}
                                <Form.Group controlId="formBasicName" className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicPhone" className="mb-3">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                {/* Other form fields go here */}
                                <Form.Group controlId="formBasicPreferredProject" className="mb-3">
                                    <Form.Label>Preferred Project</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Preferred Project"
                                        name="leadgenPreferredProject"
                                        value={formData.leadgenPreferredProject}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicSlug" className="mb-3">
                                    <Form.Label>Slug</Form.Label>
                                    <Form.Control
                                        as="select"
                                        placeholder="Select slug"
                                        name="slug"
                                        value={formData.slug}
                                        onChange={handleChange}>
                                        <option value="">Select a project</option>
                                        {defaultProjects.map((item, index) => (
                                            <option key={index} value={item.slug}>{item.text}</option>
                                        ))}
                                    </Form.Control>

                                </Form.Group>

                                <Form.Group controlId="formBasicSellDoLeadId" className="mb-3">
                                    <Form.Label>SellDo Lead ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter SellDo Lead ID"
                                        name="selldoleadid"
                                        value={formData.selldoleadid}
                                        onChange={handleChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formBasicPreferredLocality" className="mb-3">
                                    <Form.Label>Preferred Locality</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="leadgenPreferredLocality"
                                        value={formData.leadgenPreferredLocality}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select a locality</option>
                                        {defaultLocations.map((item, index) => (
                                            <option key={index} value={item.text}>{item.text}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="formBasicPreferredBudget" className="mb-3">
                                    <Form.Label>Preferred Budget</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="leadgenPreferredBudget"
                                        value={formData.leadgenPreferredBudget}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select a budget</option>
                                        <option value="Below 40 L">Below 40 L</option>
                                        <option value="40- 45 L">40- 45 L</option>
                                        <option value="45- 50 L">45- 50 L</option>
                                        <option value="51 - 55 L">51 - 55 L</option>
                                        <option value="56- 60 L">56- 60 L</option>
                                        <option value="61 - 65 L">61 - 65 L</option>
                                        <option value="66 - 70 L">66 - 70 L</option>
                                        <option value="71 - 75 L">71 - 75 L</option>
                                        <option value="76 - 80 L">76 - 80 L</option>
                                        <option value="81 - 85 L">81 - 85 L</option>
                                        <option value="86 - 90 L">86 - 90 L</option>
                                        <option value="91 - 95 L">91 - 95 L</option>
                                        <option value="96 L - 1 Cr">96 L - 1 Cr</option>
                                        <option value="1.10 - 1.15 Cr">1.10 - 1.15 Cr</option>
                                        <option value="1.16 - 1.2 Cr">1.16 - 1.2 Cr</option>
                                        <option value="1.21 - 1.30 Cr">1.21 - 1.30 Cr</option>
                                        <option value="1.31 - 1.40 Cr">1.31 - 1.40 Cr</option>
                                        <option value="1.41 - 1.50 Cr">1.41 - 1.50 Cr</option>
                                        <option value="1.51 - 1.60 Cr">1.51 - 1.60 Cr</option>
                                        <option value="1.61 - 1.70 Cr">1.61 - 1.70 Cr</option>
                                        <option value="1.71 - 1.80 Cr">1.71 - 1.80 Cr</option>
                                        <option value="1.81 - 1.90 Cr">1.81 - 1.90 Cr</option>
                                        <option value="1.91 - 2 Cr">1.91 - 2 Cr</option>
                                        <option value="2 - 2.2 Cr">2 - 2.2 Cr</option>
                                        <option value="2.2 - 2.4 Cr">2.2 - 2.4 Cr</option>
                                        <option value="2.4 - 2.6 Cr">2.4 - 2.6 Cr</option>
                                        <option value="2.6 - 2.8 Cr">2.6 - 2.8 Cr</option>
                                        <option value="2.8 - 3 Cr">2.8 - 3 Cr</option>
                                        <option value="3 - 3.3 Cr">3 - 3.3 Cr</option>
                                        <option value="3.3 - 3.6 Cr">3.3 - 3.6 Cr</option>
                                        <option value="3.6 - 3.9 Cr">3.6 - 3.9 Cr</option>
                                        <option value="3.9 - 4.2 Cr">3.9 - 4.2 Cr</option>
                                        <option value="4.2 - 4.5 Cr">4.2 - 4.5 Cr</option>
                                        <option value="4.5 - 5 Cr">4.5 - 5 Cr</option>
                                        <option value="5 - 5.5 Cr">5 - 5.5 Cr</option>
                                        <option value="5.5 - 6 Cr">5.5 - 6 Cr</option>
                                        <option value="6 Cr & above">6 Cr & above</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId="formBasicPreferredConfiguration" className="mb-3">
                                    <Form.Label>Preferred Configuration</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="leadgenPreferredConfiguration"
                                        value={formData.leadgenPreferredConfiguration}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select a configuration</option>
                                        <option value="1 BHK">1 BHK</option>
                                        <option value="1.5 BHK">1.5 BHK</option>
                                        <option value="2 BHK">2 BHK</option>
                                        <option value="2.5 BHK">2.5 BHK</option>
                                        <option value="3 BHK">3 BHK</option>
                                        <option value="3.5 BHK">3.5 BHK</option>
                                        <option value="4 BHK">4 BHK</option>
                                        <option value="4.5 BHK">4.5 BHK</option>
                                        <option value="5 BHK">5 BHK</option>
                                        <option value="5.5 BHK">5.5 BHK</option>
                                        <option value="6 BHK onwards">6 BHK onwards</option>
                                        <option value="NA Plots">NA Plots</option>
                                        <option value="Agri Plots">Agri Plots</option>
                                        <option value="Old Age Home">Old Age Home</option>
                                        <option value="Commercial Shops">Commercial Shops</option>
                                        <option value="Commercial Offices">Commercial Offices</option>
                                        <option value="Villa">Villa</option>
                                        <option value="Townhouse">Townhouse</option>
                                        <option value="Duplex">Duplex</option>
                                    </Form.Control>
                                </Form.Group>
                                <div className="text-center">
                                    <Button variant="primary" type="submit" className="w-100">
                                        Fetch User Data
                                    </Button>
                                </div>
                            </Form>
                        ) : (
                            <div className="text-center mt-4">
                                <h4>Form Submitted Successfully!</h4>
                                <h5>Coupon Code: <span className="text-success">{parsedUserData?.coupon_code}</span></h5>
                                <Button variant="secondary" onClick={handleRefillForm} className="mt-3">
                                    Click here to refill the form
                                </Button>
                            </div>
                        )}

                        {showCoupon && (
                            <div className="text-center mt-4">
                                <Button
                                    variant="success"
                                    onClick={() => setOverlayVisible(true)}
                                    className="w-100"
                                >
                                    Download Coupon
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {parsedUserData && (
                <div className="overlayCreateUser" id="overlayCreateUser" style={{ display: isOverlayVisible ? 'block' : 'none' }}>
                    <div className="overlay-content-createUser">
                        <span className="close position-absolute end-0 text-end text-black col-1 z-index10" onClick={handleClose}>
                            &times;
                        </span>
                        {createGa4Event('download_coupon', `download_coupon`, `${parsedUserData.project.name}`)}
                        <CouponTag
                            userTokenCheckDisabled={true}
                            couponCode={parsedUserData.coupon_code}
                            offerName={parsedUserData.offer.name}
                            projectName={parsedUserData.project?.name}
                            projectSlug={parsedUserData.project?.slug}
                            userName={parsedUserData.user_name}
                            userPhone={parsedUserData?.user?.phone}
                            offerExpiresAt={parsedUserData.offer?.expires_at}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default CreateJUser;
