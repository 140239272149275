import React, { useEffect, useState, useRef } from 'react';
import { showToastMessage, userToken, executeLaravelAPI, buttonLoadingClass, uploadAssets, s3url } from '../../Utils';
import SubmitButton from '../../shared/SubmitButton';
import { Icon } from '@iconify/react';

const ProjectEditBrochures = ({ selectedProjectId }) => {
    const buttonClass = useRef('theme-btn btn btn-success');
    const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const [brochures, setBrochures] = useState([]); // Unified state for existing and new assets
    const [removedIds, setRemovedIds] = useState([]);

    useEffect(() => {
        const getProjectBrochures = async () => {
            try {
                const result = await executeLaravelAPI(`projectbrochures/${selectedProjectId}`, '', 'GET', userToken);
                const dataProjectDetails = result.data.map((item) => ({
                    ...item,
                    isNew: false, // Flag to distinguish between existing and new assets
                    file: null // Placeholder for new file
                }));
    
                // If there are no existing assets, initialize with a placeholder for adding a new asset
              
                if (dataProjectDetails.length === 0) {
                    dataProjectDetails.push({ id: `newasset1`, isNew: true, file: null, title: '', alt: '' });
                }
    
                setBrochures(dataProjectDetails);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (selectedProjectId) {
            getProjectBrochures();
        }
    }, [selectedProjectId]);    


    const handleFileChange = (index, file) => {
        setBrochures(currentAssets => currentAssets.map((asset, i) => i === index ? { ...asset, file } : asset));
    };

    const handleTitleChange = (index, title) => {
        setBrochures(currentAssets => currentAssets.map((asset, i) => i === index ? { ...asset, title } : asset));
    };

    const handleAltChange = (index, alt) => {
        setBrochures(currentAssets => currentAssets.map((asset, i) => i === index ? { ...asset, alt } : asset));
    };

    const handleAddNew = (index) => {
        const trElements = document.querySelectorAll('tbody.assetstbody tr');
        setBrochures(currentAssets => [
            ...currentAssets,
            { id: `newasset${(trElements.length+1)}`, isNew: true, file: null, title: '', alt: '' } // Add a new asset with unique id
        ]);
    };

    const handleRemove = (index, id) => {
        setBrochures(currentAssets => currentAssets.filter((_, i) => i !== index)); 
        setRemovedIds(prevIds => [...prevIds, id]);
    };

    const updateProjectBrochures = async (e) => {
        e.preventDefault(); // Prevent form submission default behavior
        try {
            document.getElementsByClassName('theme-btn').disabled = true;
            setButtonClass(`${buttonClass.current} ${buttonLoadingClass}`);
    
            const assetsFormData = new FormData();
            brochures.forEach((asset, index) => {
                if (asset.file || asset.isNew || asset.title || asset.alt) {
                    if (asset.file) {
                        assetsFormData.append(`images[${index}]`, asset.file);
                    }
                    assetsFormData.append(`title[${index}]`, asset.title || 'NA');
                    assetsFormData.append(`alt[${index}]`, asset.alt || 'NA');
                    assetsFormData.append(`assetid[${index}]`, asset.id || `newasset${index}`);
                }
            });
            assetsFormData.append('removeassetids', removedIds.join(','));
            //Insert/Update in assets API call
            const updatedProjectAssetsData = await uploadAssets(`projectbrochures/edit/${selectedProjectId}`, assetsFormData, userToken);

            if (updatedProjectAssetsData.status === 200) {
                //Again get inserted/updated assets API call
                const result = await executeLaravelAPI(`projectbrochures/${selectedProjectId}`, '', 'GET', userToken);
                setBrochures(result.data);

                showToastMessage('Unitplans are updated successfully', 'top-right', 'success');
            } else {
                showToastMessage('Unitplans are not updated successfully', 'top-right', 'error');
            }

            document.getElementById("removeassets").value = '';

            setButtonClass(buttonClass.current);
            document.getElementsByClassName('theme-btn').disabled = false;
        } catch (error) {
            console.error('Error updating assets:', error);
        }
    };


    return (
        <form onSubmit={updateProjectBrochures} className='projectForm' encType="multipart/form-data">
            <div className="row">
                <div className="col-md-12 mb-2">
                    <input type="hidden" id="removeassets" value={removedIds || ''} redOnly />
                    <table className='table table-responsive mt-0 p-0 mb-0 table-hover table-bordered configuration-table'>
                    <thead>
                            <tr className='table-secondary'>
                                <td>No</td>
                                <td>Brochures</td>
                                <td>Link</td>
                                <td><button type='button' className='btn btn-outline-success rounded-circle add-config text-success' onClick={handleAddNew}>
                                    <Icon icon="ic:sharp-add" style={{ fontSize: '24px' }} />
                                </button></td>
                            </tr>
                        </thead>
                        <tbody className='assetstbody'>
                            {brochures.map((brochure, index) => (
                                <tr key={brochure.id || index}>
                                    <td>{index + 1}</td>
                                    <td>
                                    <input type="hidden" className="form-control" defaultValue={brochure.id || ''} readOnly/>
                                    <input type="file" className="form-control" accept="application/pdf" onChange={(e) => handleFileChange(index, e.target.files[0])} /></td>
                                    <td>{brochure.url ? <a href={`${s3url}/project-assets${brochure.url}`} target="_blank">Download</a> : 'No File'}</td>
                                    <td><a href="#remove" data-id={brochure.id || ''} onClick={() => handleRemove(index, brochure.id)} rel="noopener noreferrer"><Icon icon='ion:close-outline' style={{ fontSize: '30px', color: '#192951' }} /></a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='text-end mt-3'>
            {/* <Button variant="secondary" className="theme-btn secondary me-2">Cancel</Button> */}
            <SubmitButton type="submit" className={buttonClassLoader} label="Update Brochures" />
            </div>
        </form>
    );
};

export default ProjectEditBrochures;