import React, { useEffect, useState, useRef } from 'react';
import { Button, Form, Tabs, Tab, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { showToastMessage, executeLaravelAPI, executeLaravelFrontAPI, userToken,validateName,generateSlug,buttonLoadingClass } from '../Utils';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { SelectToSearch } from './SelectToSearch';
import TowerComponent from './TowerComponent';
import ConfigurationComponent from './ConfigurationComponent';
import { Icon } from '@iconify/react';
import AsyncSelect from 'react-select/async';
import SubmitButton from '../shared/SubmitButton';

const ProjectCreateForm = ({ reloadParent, onCreateFormSubmit, hideCreateForm}) => {
    const buttonClass=useRef('theme-btn btn btn-success');
    const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const [isIRFS, setIsIRFS] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationsData, setSelectedLocations] = useState(null);
    
    const [newProject, setNewProject] = useState({
        name: '',
        slug: '',
        project_type: '',
        project_status : '',
        total_units : '',
        rera_registration_no : '',
        per_sqft_price : '',
        completion_timestamp : '',
        possession_date_readable :'',
        project_size : '',
        is_irfs : isIRFS,
        developer_id : '',
        address1:'',
        address2:'',
        address_type:'',
        city:'',
        country:'India',
        latitude:'',
        locality:'',
        location:'',
        longitude:'',
        state:'MAHARASHTRA',
        zip:'',
    });

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Create New Project';
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let slugValue;
        const updatedState = { [name]: value };
        if (name === 'name') {
            slugValue = generateSlug(value);
            updatedState.slug = slugValue;
        }
        setNewProject((prevState) => ({
            ...prevState,
            ...updatedState,
        }));
        // Validate the input
        let error = validateInput(name, value);
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const validateInput = (name, value) => {
        // Your validation logic here
        let error = '';
        if (['name', 'slug'].includes(name)) {
            error = validateName(value);
        }
        // Add other validations as needed...
        return error;
    };

    const handleSwitchChange = (e) => {
        setIsIRFS(e.target.checked);
    };


    const handleCityChange = (event) => {
        const city = event.target.value;
        if (city === '') {
            return;
        }
        setSelectedCity(city);
        getLocationsByCity(city);
    };

    const getLocationsByCity = async (city) => {
        try {
            const result=await executeLaravelAPI(`locations/city/${city}`, '', 'GET', userToken);
            const locationData=result.data;
            //console.log(locationData)
            setSelectedLocations(locationData);            
        } catch (error) {
            console.error('Error fetching data:', error);
        } 
    };

    const handleLocationChange = (event) => {
        const location = event.target.value;
        if (location === '') {
            return;
        }
        setSelectedLocation(location);
    };


    const loadOptions = async (inputValue) => {
        if (inputValue.length > 2) {
            try {
                const paramsDev = {
                    name: inputValue,
                    searchtype: `developers`
                };
                const response = await executeLaravelFrontAPI('developersinsearch', paramsDev, 'GET');
                return response.data.data.map(dev => ({
                    label: dev.name,
                    value: dev.id
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
                return [];
            }
        }
        return [];
    };
    const handleDeveloperChange = (selectedOption) => {
        setNewProject(prevState => ({
            ...prevState,
            developer_id: {
                id: selectedOption.value
            },
        }));
    };

    const createProject = async (e) => {
        e.preventDefault();
        try {
            document.getElementsByClassName('theme-btn').disabled = true;
            setButtonClass(`${buttonClass.current} ${buttonLoadingClass}`);
            // Check for errors
            let hasErrors = false;
            const errors = {};
            errors.name = validateName(newProject.name);
            errors.slug = validateName(newProject.slug);
            errors.project_type = validateName(newProject.project_type);
            errors.project_status = validateName(newProject.project_status);
            errors.total_units = validateName(newProject.total_units);
            errors.rera_registration_no = validateName(newProject.rera_registration_no);
            errors.per_sqft_price = validateName(newProject.per_sqft_price);
            errors.completion_timestamp = validateName(newProject.completion_timestamp);
            errors.possession_date_readable = validateName(newProject.possession_date_readable);
            errors.project_size = validateName(newProject.project_size);


            if (!selectedCity || selectedCity==null) {
                errors.city = 'Please select a city.';
            }
            // Validate location selection
            if (!selectedLocation || selectedLocation==null) {
                //errors.location = 'Please select a location.';
            }
            errors.address1 = validateName(newProject.address1);
            errors.address2 = validateName(newProject.address2);
            errors.zip = validateName(newProject.zip);
            errors.area = validateName(newProject.area);

            for (const key in errors) {
                if (errors[key]) {
                    hasErrors = true;
                    break;
                }
            }
            setValidationErrors(errors);
            if (hasErrors) return;

            newProject.is_irfs = isIRFS;
            newProject.city=selectedCity;
            newProject.location=selectedLocation;
            newProject.developer_id=newProject.developer_id.id;
            //console.log(newProject);
            const projectData = await executeLaravelAPI('add-project', newProject, 'POST', userToken);
            if (projectData.status === 200) {
                showToastMessage('Project Created Successfully', 'top-right', 'success');
                navigate('/portaladmin/disabledprojects');
                reloadParent();
            } else {
                showToastMessage('Project not created', 'top-right', 'error');
            }
            setButtonClass(`${buttonClass.current}`);
            document.getElementsByClassName('theme-btn').disabled = false;
            
        } catch (error) {
            console.error('Error creating project:', error);
        }
        if (typeof onCreateFormSubmit === 'function') {
            onCreateFormSubmit(newProject);
        }
        // hideCreateForm(true);
    };

    const handleCloseForm = () => {
        // hideCreateForm(true);
    };

    return (
        <>
        <form onSubmit={createProject} className='projectForm'>
            <div className="row">
                <div className="col-md-12 mb-2">
                    <Card border="" style={{ width: '100%' }}>
                        <Card.Header>Create New Project</Card.Header>
                        <Card.Body>
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Project Name">
                                            <Form.Control placeholder="Project Name" type="text" name='name' value={newProject['name']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.name}/>
                                            <Form.Control.Feedback type="invalid">
                                                {validationErrors.name}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Slug">
                                            <Form.Control placeholder="Slug" type="text" name='slug' value={newProject['slug']} onChange={handleInputChange} disabled={false} readOnly={true} isInvalid={!!validationErrors.slug}/>
                                            <Form.Control.Feedback type="invalid">
                                                {validationErrors.slug}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>
                            
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="RERA">
                                            <Form.Control placeholder="RERA" type="text" name='rera_registration_no' value={newProject['rera_registration_no']} onChange={handleInputChange} isInvalid={!!validationErrors.rera_registration_no}/>
                                            <Form.Control.Feedback type="invalid">
                                                {validationErrors.rera_registration_no}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>

                            <div className='col-md-6 mb-2'>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={loadOptions}
                                    name="developer_id"
                                    onChange={handleDeveloperChange}
                                    className='customSelectStyle'
                                />
                            </div>

                            <div className='col-12'>
                            <hr></hr>
                            </div>

                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Select Project Type">
                                            <select name="project_type" className={`form-control ${validationErrors.project_type ? 'is-invalid' : ''}`} value={newProject['project_type']} onChange={handleInputChange} isInvalid={!!validationErrors.project_type}>
                                                <option>--Select Type--</option>
                                                <option value="Residential">Residential</option>
                                                <option value="Commercial">Commercial</option>
                                                <option value="Industrial">Industrial</option>
                                                <option value="Mixed(Plot & Buildings)">Mixed(Plot & Buildings)</option>
                                                <option value="Others">Others</option>
                                            </select>
                                            {validationErrors.project_type && (
                                                <Form.Control.Feedback type="invalid">
                                                    {validationErrors.project_type}
                                                </Form.Control.Feedback>
                                            )}
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Project Status">
                                            <select name="project_status" className={`form-control ${validationErrors.project_status ? 'is-invalid' : ''}`} value={newProject['project_status']} onChange={handleInputChange} isInvalid={!!validationErrors.project_status}>
                                                <option value="">--Select Project Status--</option>
                                                <option value="New Project">New Project</option>
                                                <option value="New Launch">New Launch</option>
                                                <option value="Under Construction">Under Construction</option>
                                                <option value="Ready To Move">Ready To Move</option>
                                            </select>
                                            {validationErrors.project_status && (
                                                <Form.Control.Feedback type="invalid">
                                                    {validationErrors.project_status}
                                                </Form.Control.Feedback>
                                            )}
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>

                            <div className='col-12'>
                            <hr></hr>
                            </div>

                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Total Unit">
                                            <Form.Control placeholder="Total Unit" type="text" name='total_units' value={newProject['total_units']} onChange={handleInputChange} isInvalid={!!validationErrors.total_units}/>
                                            <Form.Control.Feedback type="invalid">
                                                {validationErrors.total_units}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Price/sqft">
                                            <Form.Control placeholder="Price/sqft" type="text" name='per_sqft_price' value={newProject['per_sqft_price']} onChange={handleInputChange} isInvalid={!!validationErrors.per_sqft_price}/>
                                            <Form.Control.Feedback type="invalid">
                                                {validationErrors.per_sqft_price}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>

                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Project Size">
                                            <Form.Control placeholder="Project Size (in Acre)" type="text" name='project_size' value={newProject['project_size']} onChange={handleInputChange} isInvalid={!!validationErrors.project_size}/>
                                            <Form.Control.Feedback type="invalid">
                                                {validationErrors.project_size}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>
                        
                            <div className='col-12'>
                            <hr></hr>
                            </div>
                            
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Completion Time">
                                            <Form.Control placeholder="Completion Time" type="date" name='completion_timestamp' value={newProject['completion_timestamp']} onChange={handleInputChange} isInvalid={!!validationErrors.completion_timestamp}/>
                                            <Form.Control.Feedback type="invalid">
                                                {validationErrors.completion_timestamp}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Possession Date">
                                            <Form.Control placeholder="Possession Date" type="date" name='possession_date_readable' value={newProject['possession_date_readable']} onChange={handleInputChange} isInvalid={!!validationErrors.possession_date_readable}/>
                                            <Form.Control.Feedback type="invalid">
                                                {validationErrors.possession_date_readable}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>
                            
                            <div className='col-12'>
                            <hr></hr>
                            </div>
                            
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="City">
                                            <select name="city" id="city" className={`form-control ${validationErrors.city ? 'is-invalid' : ''}`} onChange={handleCityChange} isInvalid={!!validationErrors.city}>
                                                <option value="">--Select City--</option>
                                                <option value="pune">Pune</option>
                                                <option value="mumbai">Mumbai</option>
                                            </select>
                                            {validationErrors.city && (
                                                <Form.Control.Feedback type="invalid">
                                                    {validationErrors.city}
                                                </Form.Control.Feedback>
                                            )}
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Location">
                                            <select name="location" id="location" className={`form-control ${validationErrors.location ? 'is-invalid' : ''}`} onChange={handleLocationChange} isInvalid={!!validationErrors.location}>
                                                <option value="">--Select Location--</option>
                                                { locationsData &&
                                                    
                                                    locationsData.map((locationObj, index) => (
                                                        <option key={index} value={locationObj.location}>
                                                            {locationObj.location}
                                                        </option>
                                                    ))
                                                    
                                                };
                                            </select>
                                            {validationErrors.location && (
                                                <Form.Control.Feedback type="invalid">
                                                    {validationErrors.location}
                                                </Form.Control.Feedback>
                                            )}
                                        </FloatingLabel>
                                        
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Address 1">
                                            <Form.Control placeholder="Address 1" type="text" name='address1' value={newProject['address1']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.address1}/>
                                            <Form.Control.Feedback type="invalid">
                                                {validationErrors.address1}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Address 2">
                                            <Form.Control placeholder="Address 2" type="text" name='address2' value={newProject['address2']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.address2}/>
                                            <Form.Control.Feedback type="invalid">
                                                {validationErrors.address2}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Zipcode">
                                            <Form.Control placeholder="Zipcode" type="text" name='zip' value={newProject['zip']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.zip}/>
                                            <Form.Control.Feedback type="invalid">
                                                {validationErrors.zip}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Area">
                                            <Form.Control placeholder="Area" type="text" name='area' value={newProject['area']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.area}/>
                                            <Form.Control.Feedback type="invalid">
                                                {validationErrors.area}
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Latitude">
                                            <Form.Control placeholder="Latitude" type="text" name='latitude' value={newProject['latitude']} onChange={handleInputChange} disabled={false} readOnly={false} />
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <FloatingLabel controlId="floatingInput" label="Longitude">
                                            <Form.Control placeholder="Longitude" type="text" name='longitude' value={newProject['longitude']} onChange={handleInputChange} disabled={false} readOnly={false} />
                                        </FloatingLabel>
                                    </div>
                                </Form.Group>
                            </div>

                            <div className='col-12'>
                            <hr></hr>
                            </div>
                            
                            <div className="col-md-6 mb-2">
                                <Form.Group className="row">
                                    <div className="col-sm-12">
                                        <label>Is IRFS</label>
                                        <Form.Check
                                            type="switch"
                                            id='custom-switch'
                                            name='is_irfs'
                                            checked={isIRFS} 
                                            onChange={handleSwitchChange}
                                        />
                                    </div>
                                </Form.Group>
                            </div>
                        </div>
                       
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="secondary" onClick={reloadParent} className="me-2">Cancel</Button>
                            <SubmitButton variant="success" type="submit" className={buttonClassLoader} label='Add Project'/>                      
                        </Card.Footer>
                    </Card>
                </div>
            </div>
        </form>
        </>
    )
};

export default ProjectCreateForm;
