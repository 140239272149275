import React, { useEffect, useState, useRef } from 'react';
import { showToastMessage, userToken, executeLaravelAPI, buttonLoadingClass } from '../../Utils';
import { Form } from 'react-bootstrap';
import { SelectToSearch } from '../SelectToSearch';
import SubmitButton from '../../shared/SubmitButton';

const ProjectEditSpecifications = ({selectedProjectId }) => {
    const buttonClass=useRef('theme-btn btn btn-success');
    const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const [projectSpecifications, setEditedItem] = useState({});
    
    useEffect(() => {
        const getProjects = async () => {
            try {
                const result = await executeLaravelAPI(`project/view/${selectedProjectId}`, '', 'GET', userToken);
                const dataProjectDetails = result.data;
                setEditedItem(dataProjectDetails); // Set the editedItem state
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (selectedProjectId) {
            getProjects();
        }
    }, [selectedProjectId]);

    const handleSelectChangeForSpecifications = (selectedOptions) => {
        setEditedItem(prevState => ({
            ...prevState,
            specifications: selectedOptions.length > 0 ? selectedOptions.map(option => ({ id: option.value, name: option.label })): []
        }));
    };

    const updateProjectSpecifications = async (e) => {
        e.preventDefault();
        const buttons = document.getElementsByClassName('theme-btn');
        for (let button of buttons) {
            button.disabled = true;
        }
        setButtonClass(`${buttonClass.current} ${buttonLoadingClass}`);
        
        try {
            if (projectSpecifications.specifications && Array.isArray(projectSpecifications.specifications)) {
                const specificationsData = { "specifications": projectSpecifications.specifications.map(spec => spec.id) };
    
                if (specificationsData.specifications.length > 0) {
                    const updatedProjectSpecificationsData = await executeLaravelAPI(`projectspecifications/edit/${selectedProjectId}`, specificationsData, 'PUT', userToken);
    
                    if (updatedProjectSpecificationsData.status === 200) {
                        const response = await executeLaravelAPI(`specificationsbyids`, specificationsData, 'POST', userToken);
                        // Update the state only once with the new data
                        setEditedItem(prevState => ({
                            ...prevState,
                            specifications: response.data.map(specification => ({ id: specification.id, name: specification.name }))
                        }));
                        showToastMessage('Specifications updated successfully', 'top-right', 'success');
                    } else {
                        showToastMessage('Specifications update failed', 'top-right', 'error');
                    }
                } else {
                    // Handle case where no specifications are selected
                    setEditedItem(prevState => ({
                        ...prevState,
                        specifications: []
                    }));
                }
            }
        } catch (error) {
            console.error('Error updating specifications:', error);
            showToastMessage('Error updating specifications', 'top-right', 'error');
        } finally {
            // Reset the button class and enable all the buttons
            setButtonClass(buttonClass.current);
            for (let button of buttons) {
                button.disabled = false;
            }
        }
    }
    
    
    

    return (
        <form onSubmit={updateProjectSpecifications} className='projectForm'>
            <div className="row">
                <div className="col-md-12 mb-2">
                    <Form.Group className="row">
                        <label className="col-sm-12 col-form-label">Specifications</label>
                        <div className="col-sm-12">
                            <SelectToSearch
                                type={"specifications"}
                                defaultValues={projectSpecifications?.specifications || []} 
                                name="specifications"
                                onChange={(selectedOptions) => handleSelectChangeForSpecifications(selectedOptions)}
                            />
                        </div>
                    </Form.Group>
                </div>
            </div>
            <div className='col-12'>
                <hr></hr>
            </div>
            <div className='text-end mt-3'>
                {/* <Button variant="secondary" className="theme-btn secondary me-2">Cancel</Button> */}
                <SubmitButton variant="success" type="submit" className={buttonClassLoader} label="Update Specifications" />
            </div>
        </form>
    )
}
export default ProjectEditSpecifications;
