import React, { useEffect, useState } from 'react';
import { Button, Form, Card, FloatingLabel } from 'react-bootstrap';
import { showToastMessage, executeLaravelAPI,userToken,uploadAssets,validateName,generateSlug} from '../../Utils';

const NewOfferAdd = ({ onCreateFormSubmit }) => {
    const [newOffer, setNewOffer] = useState({
        name: '',
        slug: '',
        price: '',
        offer_type: '',
        offer_description: '',
        offer_disclaimer: '',
        expires_at: '',
        coupon_code: '',
        image: ''
    });
    const [offerLogo, setSelectedFile] = useState(''); 
    const [offerType, setOfferType] = useState('');
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        document.title = 'Create New Offer';
    }, []);

    const formatDate = (dateString) => {
        if (!dateString) return ''; // Handle empty or null values
        //console.log("dated:- "+dateString);
        const dateObject = new Date(dateString);
        const day = dateObject.getDate().toString().padStart(2, '0');
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Note: Month starts from 0
        const year = dateObject.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const validateInput = (name, value) => {
        // Your validation logic here
        let error = '';
        if (['name', 'slug','price'].includes(name)) {
            error = validateName(value);
        }
        // Add other validations as needed...
        return error;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let slugValue;
        const updatedState = { [name]: value };

        if (name === 'name') {
            slugValue = generateSlug(value);
            updatedState.slug = slugValue;
        }
        setNewOffer((prevState) => ({
            ...prevState,
            ...updatedState,
        }));
        // Validate the input
        let error = validateInput(name, value);
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    // Handle change in the select dropdown
    const handleOfferTypeChange = (e) => {
        const value = e.target.value;
        setOfferType(value);
        // Clear validation error if an option is selected
        if (value) {
            setValidationErrors((prevErrors) => ({
            ...prevErrors,
            offer_type: ''
            }));
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };
   
    const createOffer = async (e) => {
        e.preventDefault();
        try {
            // Check for errors
            let hasErrors = false;
            const errors = {};
            errors.name = validateName(newOffer.name);
            errors.price = validateName(newOffer.price);
            errors.slug = validateName(newOffer.slug);
            //errors.coupon_code = validateName(newOffer.coupon_code);
            if (!offerType) {
                errors.offer_type = 'Please select an offer type.';
            }

            for (const key in errors) {
                if (errors[key]) {
                    hasErrors = true;
                    break;
                }
            }
            setValidationErrors(errors);
            if (hasErrors) return;
            newOffer.offer_type = offerType;
            const offerFormData = new FormData();
            if (offerLogo) {
                offerFormData.append(`logo`, offerLogo);
            }
            
            const response = await executeLaravelAPI('add-offer', newOffer,'POST',userToken);
            await uploadAssets(`offerassets/${response.data.id}`, offerFormData,userToken);
            if (response.status === 200) {
                showToastMessage('Offer created successfully', 'top-right', 'success');
            } else {
                showToastMessage('Offer creation failed', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error creating offer:', error);
            showToastMessage('Error creating offer', 'top-right', 'error');
        }
        if (onCreateFormSubmit) {
            onCreateFormSubmit(newOffer);
        }
    };

    return (
        <form onSubmit={createOffer} className='offerForm' encType="multipart/form-data">
            <div className="row">
                <div className="col-md-12 mb-2">
                    <Card border="" style={{ width: '100%' }}>
                        <Card.Header>Add New Offer</Card.Header>
                        <Card.Body>
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Offer Name">
                                                    <Form.Control placeholder="Offer Name" type="text" name='name' value={newOffer['name']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.name}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.name}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Slug">
                                                    <Form.Control placeholder="Slug" type="text" name='slug' value={newOffer['slug']} onChange={handleInputChange} disabled={false} readOnly={true} isInvalid={!!validationErrors.slug}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.slug}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>  
                                    <hr/>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Offer Price">
                                                    <Form.Control placeholder="Offer Price" type="number" name='price' value={newOffer['price']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.price}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.price}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Offer Type">
                                                    <select name="offer_type" id="offer_type" className={`form-control ${validationErrors.offer_type ? 'is-invalid' : ''}`} onChange={handleOfferTypeChange} value={offerType} 
                                                    >
                                                        <option value="">--Select Offer Type--</option>
                                                        <option value="project">Project</option>
                                                        {/* <option value="developer">Developer</option>
                                                        <option value="configuration">Unit Configuration</option>
                                                        <option value="user">User</option> */}
                                                    </select>
                                                    {validationErrors.offer_type && (
                                                        <Form.Control.Feedback type="invalid">
                                                        {validationErrors.offer_type}
                                                    </Form.Control.Feedback>
                                                    )}
                                                    
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    {/* <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Coupon Code">
                                                    <Form.Control placeholder="Coupon Code" type="text" name='coupon_code' value={newOffer['coupon_code']} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.coupon_code}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.coupon_code}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div> */}
                                    <hr/>
                                   
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Logo">
                                                    <Form.Control type="file" className="form-control" accept="image/*" onChange={handleFileChange} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Expires At">
                                                    <Form.Control placeholder="Expires At" type="date" name='expires_at' value={formatDate(newOffer.expires_at) || ''} onChange={handleInputChange} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <hr/>
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Description">
                                                    <Form.Control placeholder="Description" type="text" name='offer_description' value={newOffer['offer_description']} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Disclaimer">
                                                    <Form.Control placeholder="Disclaimer" type="text" name='offer_disclaimer' value={newOffer['offer_disclaimer']} onChange={handleInputChange} disabled={false} readOnly={false} />
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>

                        </Card.Body>
                        <Card.Footer className="text-end text-muted">
                            <Button variant="secondary" className="theme-btn secondary me-2">Cancel</Button>
                            <Button variant="success" type="submit" className="theme-btn">Save</Button>
                        </Card.Footer>
                    </Card>
                </div>
            </div>
        </form>
    );
};

export default NewOfferAdd;
