import React, { useContext } from "react";
import Slider from "react-slick";
import { parsedUserData } from "../../../Admin/Utils";
import { SearchVisibilityContext } from "../../Layouts/SearchVisibilityContext";
import DeveloperListing from "../../Layouts/DeveloperListing"; // Update path if necessary
import { ReactComponent as HmhighSVG } from "../../../../Assets/img/seopage/hm-high.svg";
import CrackBg from "../../../../Assets/img/seopage/crack-bg.jpg";
import CrackBgMob from "../../../../Assets/img/seopage/crack-mob-bg.jpg";
import Services from "./Services";
import CaseStudies from "./CaseStudies";
import DeveloperInfoCardSlider from "./DeveloperInfoCardSlider";
import Recognition from "./Recognition";
import Faq from "./Faq";
import RevenueSection from "./RevenueSection";
import SalesChooseSection from "./SalesChooseSection";
// import SvgComponent from "../../Layouts/svg/svg1";

const RealEstateMarketing = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
        arrows: false,
        dots: true, // Enable dots if you want them, else remove it
    };

    const { setDownloadCouponBtn } = useContext(SearchVisibilityContext);

    const handleNavItemClick = (btnClick) => {
        document.getElementById('overlay').style.display = 'block';
        sessionStorage.setItem('popupShown', 'true');

        if (btnClick === "Log in") {
            document.getElementById('login_title').textContent = "BeyondWalls";
            setDownloadCouponBtn(false);
        }
    };

    return (
        <main className="marketingPages">
            <section className="mt-5 bannerHero">
                <Slider {...settings}>
                    <div className="banner-slide">
                        <div className="container">
                            <div className="home-banner-cont">
                                <h1 className="text-white fw-bold sec-title text-uppercase">
                                    Real Estate <br /> Marketing <br /> SIMPLIFIED
                                </h1>
                                {
                                    parsedUserData === null && (
                                        <button className="theme-btn my-3" onClick={() => handleNavItemClick("Log in")}>
                                            Get Started
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="banner-slide">
                        <div className="container">
                            <div className="home-banner-cont">
                                <h1 className="text-white fw-bold sec-title text-uppercase">
                                    Real Estate <br /> Marketing <br /> SIMPLIFIED
                                </h1>
                                {
                                    parsedUserData === null && (
                                        <button className="theme-btn my-3" onClick={() => handleNavItemClick("Log in")}>
                                            Get Started
                                        </button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>

            <section className="py-5">
                <h2 className="text-center fw-bold">TOP REAL ESTATE BRANDS TRUST US</h2>
                <DeveloperListing />
            </section>

            <section id="crack" className="">
                <div className="col-12 position-relative">
                    {/* Desktop Image */}
                    <img
                        src={CrackBg}
                        className="img-fluid w-100 lazy hidden-ipad"
                        alt="real estate sales background"
                    />

                    <div className="crack-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="crack-content">
                                        <h2 className="sec-heading fw-bolder">
                                            Unrestricted Growth With Robust Real Estate Sales Partnership
                                        </h2>
                                        <p className="carck-desc text-semiBold">
                                            Backed by tech, team of experts, and 15 years of expertise, BeyondWalls offers a robust real estate sales partnership that provides the structure, support, and resources you need to close more deals and accelerate growth.
                                            <br />
                                            With BeyondWalls, you'll have access to our team of professionals who will help you streamline your pipeline and reach your growth goals. Moreover, you will get exclusive leads, marketing support, and advanced technology tools to gain a competitive edge in today's market.
                                        </p>
                                    </div>

                                    {/* Mobile Image */}
                                    <div className="crack-mb-img mt-5">
                                        <img
                                            src={CrackBgMob}
                                            className="img-fluid w-100 lazy visible-ipad"
                                            alt="real estate sales"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <RevenueSection />

            <SalesChooseSection />

            <DeveloperInfoCardSlider />
            <Recognition />

            <Faq />
        </main>
    );
};

export default RealEstateMarketing;
