import React, { useState, useEffect } from 'react';
import { formatNumberThousands, formatNumber } from './../../Admin/Utils';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const EMICalculator = ({ closePopup, loanPrice, showEMI }) => {   
    const [loanAmount, setLoanAmount] = useState(loanPrice !== 0 ? loanPrice : 6000000);
    const [interestRate, setInterestRate] = useState(9);
    const [loanTenure, setLoanTenure] = useState(20);
    const [emi, setEMI] = useState(null);
    useEffect(() => {
        setLoanAmount(loanPrice)
    },[loanPrice])
    useEffect(() => {
        calculateEMI();
    }, [loanAmount, interestRate, loanTenure]); // Recalculate EMI when any of the inputs change

    const calculateEMI = () => {
        // Validate input data
        if (!loanAmount || !interestRate || !loanTenure) {
            setEMI(null); // Reset EMI if inputs are invalid
            return;
        }

        // Convert interest rate to decimal and tenure to months
        const monthlyInterestRate = interestRate / (12 * 100);
        const loanMonths = loanTenure * 12;

        // Calculate EMI using the correct formula
        const emiAmount =
            (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanMonths)) /
            (Math.pow(1 + monthlyInterestRate, loanMonths) - 1);
        // console.log(emiAmount)
        setEMI(formatNumberThousands(emiAmount)); // Set EMI with 2 decimal places
    };

    return (
        // EMI Calculator
        <>
            <span>
                {emi && <p className='mb-0'>₹ <b>{emi}</b></p>}
            </span>
            {showEMI && (
                <div className='emiCalc-popup'>
                    <div className='d-flex justify-content-between emi-title'>
                        <h2 className='fs-5 text-white mb-0'>EMI Calculator</h2>
                        {emi && <p className='mb-0'>₹<b>{emi}</b>/month</p>}
                    </div>
                    <div className='p-4 pb-0 pt-3'>
                        <div className='d-flex justify-content-between'>
                            <label>Loan Amount:</label>
                            <span>₹{formatNumber(loanAmount)}</span>
                        </div>
                        <Slider
                            min={0}
                            max={100000000}
                            step={1000}
                            value={loanAmount}
                            onChange={setLoanAmount}
                        />
                    </div>
                    <hr />
                    <div className='p-4 pb-0 pt-3'>
                        <div className='d-flex justify-content-between'>
                            <label>Interest Rate (%):</label>
                            <span>{interestRate}%</span>
                        </div>
                        <Slider
                            min={0}
                            max={15}
                            step={0.5}
                            value={interestRate}
                            onChange={setInterestRate}
                        />
                    </div>
                    <hr />
                    <div className='p-4 pb-0 pt-3'>
                        <div className='d-flex justify-content-between'>
                            <label>Loan Tenure (years):</label>
                            <span>{loanTenure} years</span>
                        </div>
                        <Slider
                            min={0}
                            max={30}
                            step={1}
                            value={loanTenure}
                            onChange={setLoanTenure}
                        />
                    </div>
                    <button className="close-btn" onClick={closePopup}>X</button>
                </div>
            )}
        </>
    );
};

export default EMICalculator;
