import React, {useContext} from "react";
import Slider from "react-slick";
import { parsedUserData } from "../../../Admin/Utils";
import { SearchVisibilityContext } from "../../Layouts/SearchVisibilityContext";
import DeveloperListing from "../../Layouts/DeveloperListing"; // Update path if necessary
import { ReactComponent as HmhighSVG } from "../../../../Assets/img/seopage/hm-high.svg";
import Services from "./Services";
import CaseStudies from "./CaseStudies";
import DeveloperInfoCardSlider from "./DeveloperInfoCardSlider";
import Recognition from "./Recognition";
import Faq from "./Faq";
// import SvgComponent from "../../Layouts/svg/svg1";

const RealEstateMarketing = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: false,
    dots: true, // Enable dots if you want them, else remove it
  };

  const { setDownloadCouponBtn } = useContext(SearchVisibilityContext);

  const handleNavItemClick = (btnClick) => {
    document.getElementById('overlay').style.display = 'block';
    sessionStorage.setItem('popupShown', 'true');

    if (btnClick === "Log in") {
      document.getElementById('login_title').textContent = "BeyondWalls";
      setDownloadCouponBtn(false);
    }
  };

  return (
    <main className="marketingPages">
      <section className="mt-5 bannerHero">
        <Slider {...settings}>
          <div className="banner-slide">
            <div className="container">
              <div className="home-banner-cont">
                <h1 className="text-white fw-bold sec-title text-uppercase">
                  Real Estate <br /> Marketing <br /> SIMPLIFIED
                </h1>
                {
                  parsedUserData === null && (
                    <button className="theme-btn my-3" onClick={() => handleNavItemClick("Log in")}>
                      Get Started
                    </button>
                  )
                }
              </div>
            </div>
          </div>
          <div className="banner-slide">
            <div className="container">
              <div className="home-banner-cont">
                <h1 className="text-white fw-bold sec-title text-uppercase">
                  Real Estate <br /> Marketing <br /> SIMPLIFIED
                </h1>
                {
                  parsedUserData === null && (
                    <button className="theme-btn my-3" onClick={() => handleNavItemClick("Log in")}>
                      Get Started
                    </button>
                  )
                }
              </div>
            </div>
          </div>
        </Slider>
      </section>

      <section className="py-5">
        <h2 className="text-center fw-bold">TOP REAL ESTATE BRANDS TRUST US</h2>
        <DeveloperListing />
      </section>

      <section className="position-relative" id="highlights">
        <div>
          <div className="row">
            <div className="col-12 text-center">
              <div className="high-svg position-relative">
                <div className="high-heading-wrapper digital">
                  <h1 className="sec-heading2 fw-bold">OUR REAL ESTATE EXPERTISE</h1>
                  <div className="sec-desc fw-medium">Powered by a team of 300+ experts</div>
                </div>
                <div className="high-svg-wrapper">
                  <div className="hsw">
                    <HmhighSVG />
                    {/* <img src={HmhighSVG} alt="HmhighSVG" /> */}
                    {/* <SvgComponent /> */}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      <Services />
      {/* <CaseStudies /> */}

      <DeveloperInfoCardSlider />
      <Recognition />

      <Faq />
    </main>
  );
};

export default RealEstateMarketing;
