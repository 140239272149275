import React, { useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import Slider from 'rc-slider'; // Correct import from 'rc-slider'
import 'rc-slider/assets/index.css'; // Ensures slider styles are applied
import { formatNumber } from '../../Admin/Utils';
import { createGa4Event, isMobileDevice } from '../../Admin/Utils';
import { setProjectStatusFilter, setProjectConfigurationFilter, setProjectMaxBudgetFilter } from '../../Admin/UserActivatyTracker';

const isMobile = isMobileDevice();

const NewFilterSidebar = ({
  selectedStatus, toggleStatus,
  togglePossessionStatus, selectedPossessionStatus,
  filteredByBhk, initialDisplayLimit, showAllBhk, toggleShowAllBhk, selectedBHK,
  toggleBhkType, sortProjectsByBhkType, setSelectedTab,
  priceRange, builtUpArea, handleSliderChange, handleSliderChangeComplete, handleSliderChangeArea, handleSliderChangeCompleteArea
}) => {

  useEffect(() => {
    if (!isMobile) {
      const handleScroll = () => {
        const fixedDivWrap = document.getElementById('sticky-map-rightsidebarwrap');
        const fixedDiv = document.getElementById('sticky-map-rightsidebar');
        const footer = document.getElementById('footerdiv');

        if (fixedDiv && footer) {
          const footerRect = footer.getBoundingClientRect();
          if (window.scrollY > 1 && footerRect.top > window.innerHeight) {
            fixedDiv.classList.add('stickySidebar');
            fixedDiv.style.position = 'fixed';
            fixedDiv.style.top = isMobile ? '114px' : '157px';
          } else if (footerRect.top <= window.innerHeight) {
            fixedDiv.classList.remove('stickySidebar');
            fixedDiv.style.position = 'absolute';
            fixedDiv.style.bottom = '0';
          } else {
            fixedDiv.classList.remove('stickySidebar');
            fixedDiv.style.position = 'relative';
            fixedDivWrap.classList.remove('flexibleHeight');
            fixedDivWrap.style.height = 'calc(100vh - 157px)';
          }
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const combinedOnChange = (newRange) => {
    handleSliderChange(newRange);
  };

  const combinedOnChangeComplete = (newRange) => {
    handleSliderChangeComplete(newRange);
    setProjectMaxBudgetFilter(newRange[1]);
    createGa4Event('filter_budget', 'BudgetFilter', `${newRange[0]}-${newRange[1]}`);
  };

  const combinedOnChangeArea = (newRangeArea) => {
    handleSliderChangeArea(newRangeArea);
  };

  const combinedOnChangeCompleteArea = (newRangeArea) => {
    handleSliderChangeCompleteArea(newRangeArea);
    createGa4Event('filter_builtup', 'Built Up Filter', `${newRangeArea[0]}-${newRangeArea[1]}`);
  };

  return (
    <>
      <div className='filter-sticky-map1 mt-2' id="filter-sticky1">
        <input type="hidden" name="search" value="" id="plp-search-hidden" />
        <Accordion className='single-accordion'>
          {/* Project Status Accordion Item */}
          <Accordion.Item eventKey="0" className='p-0 accordion-item'>
            <Accordion.Header className={`${selectedStatus.length > 0 && "active"}`}>Project Status</Accordion.Header>
            <Accordion.Body>
              <div className='filter-options'>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Project") ? 'active' : ''}`}
                  onClick={() => {
                    toggleStatus("New Project");
                    createGa4Event('Click', 'Project Status Filter', 'New Project');
                  }}>
                  New Project
                </a>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Under Construction") ? 'active' : ''}`}
                  onClick={() => {
                    toggleStatus("Under Construction");
                    setProjectStatusFilter("Under Construction");
                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'Under Construction');
                  }}>
                  Under Construction
                </a>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Ready To Move") ? 'active' : ''}`}
                  onClick={() => {
                    toggleStatus("Ready To Move");
                    setProjectStatusFilter("Ready To Move");
                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'Ready To Move');
                  }}>
                  Ready To Move
                </a>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Launch") ? 'active' : ''}`}
                  onClick={() => {
                    toggleStatus("New Launch");
                    setProjectStatusFilter("New Launch");
                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'New Launch');
                  }}>
                  New Launch
                </a>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("Nearing Possession") ? 'active' : ''}`}
                  onClick={() => {
                    toggleStatus("Nearing Possession");
                    setProjectStatusFilter("Nearing Possession");
                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'Nearing Possession');
                  }}>
                  Nearing Possession
                </a>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          {/* Configuration Type Accordion Item */}
          <Accordion.Item eventKey="1" className='p-0 accordion-item'>
            <Accordion.Header className={`${selectedBHK.length > 0 && "active"}`}>Configuration Type</Accordion.Header>
            <Accordion.Body>
              <div className='filter-options'>
                {filteredByBhk.slice(0, showAllBhk ? filteredByBhk.length : initialDisplayLimit).map((BhkType, index) => (
                  BhkType.bedrooms && BhkType.type && (
                    <a key={index} className={`btn btn-outline-secondary me-2 mb-2 ${selectedBHK.includes(BhkType.bedrooms + " " + BhkType.type.toUpperCase()) ? 'active' : ''}`}
                      onClick={() => {
                        toggleBhkType(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                        sortProjectsByBhkType(selectedBHK);
                        setSelectedTab(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                        setProjectConfigurationFilter(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                        createGa4Event('filter_configuration_type', 'ConfigurationTypeFilter', BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                      }}>
                      {BhkType.bedrooms} {BhkType.type.toUpperCase()}
                    </a>
                  )
                ))}
                {filteredByBhk.length > initialDisplayLimit && (
                  <button className="p-1 pt-0 readmore text-primary text-decoration-underline fw-bold border-0 background-trasparent btn btn-link" onClick={toggleShowAllBhk}>
                    {showAllBhk ? '- Show Less' : '+ Show More'}
                  </button>
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>

          {/* Possession Status Accordion Item */}
          <Accordion.Item eventKey="2" className='p-0 accordion-item'>
            <Accordion.Header className={`${selectedPossessionStatus.length > 0 && "active"}`}>Possession Status</Accordion.Header>
            <Accordion.Body>
              <div className='filter-options'>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 3 ? 'active' : ''}`}
                  onClick={() => {
                    togglePossessionStatus('3');
                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 months');
                  }}>
                  In 3 months
                </a>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 6 ? 'active' : ''}`}
                  onClick={() => {
                    togglePossessionStatus('6');
                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 6 months');
                  }}>
                  In 6 months
                </a>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 12 ? 'active' : ''}`}
                  onClick={() => {
                    togglePossessionStatus('12');
                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 1 year');
                  }}>
                  In 1 year
                </a>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 24 ? 'active' : ''}`}
                  onClick={() => {
                    togglePossessionStatus('24');
                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 2 years');
                  }}>
                  In 2 years
                </a>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 36 ? 'active' : ''}`}
                  onClick={() => {
                    togglePossessionStatus('36');
                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 years');
                  }}>
                  In 3 years
                </a>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 60 ? 'active' : ''}`}
                  onClick={() => {
                    togglePossessionStatus('60');
                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 5 years');
                  }}>
                  In 5 years
                </a>
                <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 84 ? 'active' : ''}`}
                  onClick={() => {
                    togglePossessionStatus('84');
                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 7 years');
                  }}>
                  In 7 years
                </a>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          {/* Budget Accordion Item */}
          <Accordion.Item eventKey="3" className='p-0 accordion-item'>
            <Accordion.Header className={`${priceRange[0] !== 500000 && "active"} ${priceRange[1] !== 50000000 && "active"}`}>Budget</Accordion.Header>
            <Accordion.Body>
              <Slider
                range
                min={500000}
                max={50000000}
                value={priceRange}
                onChange={combinedOnChange}
                onAfterChange={combinedOnChangeComplete}
              />
              <p className='d-flex justify-content-between'>
                <span>{`₹${formatNumber(priceRange[0])}`}</span>
                <span>{`₹${formatNumber(priceRange[1])}`}{priceRange[1] === 50000000 && '+'}</span>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          {/* Built Up Area Accordion Item */}
          <Accordion.Item eventKey="4" className='p-0 accordion-item'>
            <Accordion.Header className={`${builtUpArea[0] !== 100 && "active"} ${builtUpArea[1] !== 5000 && "active"}`}>Built Up Area (Sq.Ft.)</Accordion.Header>
            <Accordion.Body>
              <Slider
                range
                min={100}
                max={5000}
                value={builtUpArea}
                onChange={combinedOnChangeArea}
                onAfterChange={combinedOnChangeCompleteArea}
              />
              <p className='d-flex justify-content-between'>
                <span>{`${builtUpArea[0]} Sq.Ft.`}</span>
                <span>{`${builtUpArea[1]} Sq.Ft.`}{builtUpArea[1] === 5000 && '+'}</span>
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

export default NewFilterSidebar;
