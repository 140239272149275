import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Form, Card, FloatingLabel } from 'react-bootstrap';
import { showToastMessage, executeLaravelAPI,userToken,executeLaravelFrontAPI,validateName,appAbsPath,validatePhone } from '../Utils';
import SubmitButton from '../shared/SubmitButton';
import AsyncSelect from 'react-select/async';
import e from 'cors';


const UserEdit = ({ onCreateFormSubmit }) => {
    const buttonClass=useRef('theme-btn btn btn-success');
    const pageDetails={'pagetitle':'Edit User','pageslug':'edit-user'};
    // const [buttonClassLoader, setButtonClass] = useState(buttonClass.current);
    const [editUser, setEditedItem] = useState({
        name: '',
        phone: '',
        userrole:'',
        developer_id:''
    });
    const { id } = useParams();
    const [validationErrors, setValidationErrors] = useState({});
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedDeveloper, setSelectedDeveloper] = useState({});

    useEffect(() => {
        document.title = pageDetails.pagetitle;
        const getUser = async () => {
            try {
                const result = await executeLaravelAPI(`user/edit/${id}`, '', 'GET', userToken);
                const dataUserDetails = result.data;
                setSelectedRole(dataUserDetails.userrole);

                if(dataUserDetails.userrole=='developer'){
                    const result = await executeLaravelAPI(`developer/edit/${dataUserDetails.developer_id}`, '', 'GET', userToken);
                    const dataDeveloperDetails = result.data;
                    const initialSelectedValue = { value: dataUserDetails.developer_id, label: dataDeveloperDetails.name };
                    setSelectedDeveloper(initialSelectedValue);
                }

                setEditedItem({
                    name: dataUserDetails.name,
                    phone: dataUserDetails.phone,
                    userrole: dataUserDetails.userrole,
                    developer_id: dataUserDetails.developer_id
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (id) {
            getUser();
        }
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let slugValue;
        const updatedState = { [name]: value };
        if (name === 'name') {
            // slugValue = generateSlug(value);
            // updatedState.slug = slugValue;
            // updatedState.canonical_url = "/" + slugValue;
        }
        setEditedItem((prevState) => ({
            ...prevState,
            ...updatedState,
        }));
        
        // Validate the input
        let error = validateInput(name, value);
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };


    const validateInput = (name, value) => {
        // Your validation logic here
        let error = '';
        if (['name'].includes(name)) {
            error = validateName(value);
        }
        // Add other validations as needed...
        return error;
    };

    const handleRoleChange = (event) => {
        const role = event.target.value;
        setEditedItem((prevState) => ({
            ...prevState,
            userrole: role,
        }));
        setSelectedRole(role);
    };

    const loadOptions = async (inputValue) => {
        if (inputValue.length > 2) {
            try {
                const paramsDev = {
                    name: inputValue,
                    searchtype: `developers`
                };
                const response = await executeLaravelFrontAPI('developersinsearch', paramsDev, 'GET');
                return response.data.data.map(dev => ({
                    label: dev.name,
                    value: dev.id
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
                return [];
            }
        }
        return [];
    };

    const handleDeveloperChange = (selectedOption) => {
        setEditedItem(prevState => ({
            ...prevState,
            developer_id: {
                id: selectedOption.value
            },
        }));
        setSelectedDeveloper(selectedOption);
    };

    
   
    const updateUser = async (e) => {
        e.preventDefault();
        try {
            // Check for errors
            let hasErrors = false;
            const errors = {};
            errors.name = validateName(editUser.name);
            errors.phone = validateName(editUser.phone);
            if (selectedRole === null || selectedRole === '') {
                errors.userrole = 'Please select a role.';
            }
            if (selectedRole=='developer' && editUser.developer_id.id === undefined && selectedDeveloper === null) {
                errors.developer_id = 'Please select a developer.';
            }
            for (const key in errors) {
                if (errors[key]) {
                    hasErrors = true;
                    break;
                }
            }
            setValidationErrors(errors);
            if (hasErrors) return;
            
            if(selectedDeveloper){
                editUser.developer_id = selectedDeveloper.value;
            } else {
                editUser.developer_id = editUser.developer_id.id;
            }

            if(selectedRole!='developer'){
                editUser.developer_id = 0;
            }
            //console.log(editUser);
            const response = await executeLaravelAPI(`user/update/${id}`, editUser,'PUT',userToken);
            
            if (response.status === 200) {
                showToastMessage('User edited successfully', 'top-right', 'success');
            } else {
                showToastMessage('User edition failed', 'top-right', 'error');
            }
        } catch (error) {
            showToastMessage('Error updating user', 'top-right', 'error');
        }
        if (onCreateFormSubmit) {
            onCreateFormSubmit(editUser);
        }
    };

    return (
        <form onSubmit={updateUser} className='userForm' encType="multipart/form-data">
            <div className="row">
                <div className="col-md-12 mb-2">
                    <Card border="" style={{ width: '100%' }}>
                        <Card.Header>Edit User "{editUser.name}"</Card.Header>
                        <Card.Body>
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Name">
                                                    <Form.Control placeholder="Name" type="text" name='name' value={editUser.name || ''} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.name}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.name}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Phone">
                                                    <Form.Control placeholder="Phone" type="text" name='phone' value={editUser.phone || ''} onChange={handleInputChange} disabled={false} readOnly={false} isInvalid={!!validationErrors.phone}/>
                                                    <Form.Control.Feedback type="invalid">
                                                        {validationErrors.phone}
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <hr/>
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <FloatingLabel controlId="floatingInput" label="Select User Role">
                                                    <select name="userrole" id="userrole" className="form-control" value={editUser.userrole || ''} onChange={handleRoleChange} isInvalid={!!validationErrors.userrole}>
                                                        <option value="">--Select Role--</option>
                                                        <option value="user">User</option>
                                                        <option value="developer">Developer</option>
                                                        <option value="superadmin">Super Admin</option>
                                                    </select>
                                                    {validationErrors.userrole && (
                                                        <span style={{ color: '#dc3545' }}>{validationErrors.userrole}</span>
                                                    )}
                                                </FloatingLabel>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <Form.Group className="row">
                                            <div className="col-sm-12">
                                                <label>Select Developer</label>
                                                    <AsyncSelect
                                                        cacheOptions
                                                        defaultOptions
                                                        loadOptions={loadOptions}
                                                        name="developer_id"
                                                        onChange={handleDeveloperChange}
                                                        className='customSelectStyle'
                                                        value={selectedDeveloper}
                                                    />
                                                    {validationErrors.developer_id && (
                                                        <span style={{ color: '#dc3545' }}>{validationErrors.developer_id}</span>
                                                    )}
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>

                        </Card.Body>
                        <Card.Footer className="text-end text-muted">
                            <Link to={`${appAbsPath}/portaladmin/users`} variant="secondary" className="theme-btn secondary me-2">Cancel</Link>
                            <Button variant="success" type="submit" className="theme-btn">Save</Button>
                        </Card.Footer>
                    </Card>
                </div>
            </div>
        </form>
    );
};

export default UserEdit;
